export function generateIframeFromUrl(url, width = "100%", height = "315") {
  // Define the regular expression pattern to extract latitude and longitude
  const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;

  // Search for the pattern in the URL
  const match = url.match(pattern);

  if (match) {
    // Extract latitude and longitude from the match array
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    const iframeSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.8047280219143!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6666e076cc95%3A0xc338a8b5c9f3925e!2sEmirates%20Arabian%20Horse%20Society!5e0!3m2!1sen!2sae!4v1720423848015!5m2!1sen!2sae`;

    return `<iframe src="${iframeSrc}" width=${width} height=${height} style="border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
  } else {
    return "Invalid URL or no coordinates found.";
  }
}
