import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showFirst22Words } from "../helper/showFirstSixWords";
import { translateMonthToArabic } from "../helper/translateDayToArabic";
import axios from "../axios/axios";
import { formatDateTimeToString } from "../helper/formatDateTimeToString";
import { getEventType } from "../helper/getEventLink";
import ComingSoon from "../components/ComingSoon";

const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function OpendBooking() {
  const [openeningEvents, setOpeningEvents] = useState([]);
  const navigation = useNavigate();

  const [loaded, setLoaded] = useState(false);

  async function getOpeningEvents() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    const response = await axios.get(
      `bookingtable/getbookingtablesevents?Langauge=${lang}`
    );

    const formattedData = response.data.result.map((item, index) => ({
      endate: item.endate,
      eventName: item.eventName,
      id: item.id,
      startDate: item.startDate,
      templateId: item.templateId,
      logo:
        item.logo == null
          ? `${BaseURL}/assets/img/horseauction.png`
          : item.logo,
      type: item.type,
    }));

    if(formattedData.length == 1){
      navigation(`/book-table?eventid=${formattedData[0].id}`)
      return;
    }

     

    setOpeningEvents(formattedData);
    setLoaded(true);

     
    return response.data.result;
  }
  function getCurrentLanguageUmbraco() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE";
  }

  useEffect(() => {
    getOpeningEvents();
  }, []);

  return (
    loaded &&
    openeningEvents.length > 0 ?
    <>
        <div className='container-fluid p-0-10w'>
            <div className='row justRotate'>
                {openeningEvents.map((item, index) => (
                    <>
                        <div className="col-lg-4 col-md-6 col-sm-12 justRotate">
                            <Link to={`/book-table?eventid=${item.id}`} className="custom_card">
                                <div
                                    className="custom_card_img_container position-relative"
                                >
                                    <img
                                        src={item.logo}
                                        className="bgBlack50"
                                        alt=""
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                                        }}
                                    />
                                    <div className="image_black_over_lay"></div>
                                </div>
                                <div className="image_over_lay ">{item.type}</div>
                                <div className="card_title_row">
                                    <div className="card_title">{item.eventName}</div>
                                    <div className="card_date">
                                        <i
                                            className="fa fa-calendar color-green"
                                            aria-hidden="true"
                                        ></i>
                                        {formatDateTimeToString(item.startDate , getCurrentLanguageUmbraco())}
                                    </div>
                                </div>
                                <div className="card_paragraph">{item.eventName}</div>
                            </Link>
                        </div>
                    </>
                ))}
            </div>
        </div>
    </>:
    <ComingSoon />
  );
}
