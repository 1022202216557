import React, { useState, useEffect, lazy, Suspense } from "react";
import VideoSlider from "../components/VideoSlider";
// import Carousel from "../components/Carousel";
// import LatestNewsSection from "../components/LatestNewsSection";
import SectionTitle from "../components/SectionTitle";
// import ServicesSection from "../components/ServicesSection";
// import EventsSection from "../components/EventsSection";
// import SponsersSection from "../components/SponsersSection";
import axios from "axios";
import { useNavigate } from "react-router";
import { useTranslate } from "../components/TranslationText";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import ComingSoon from "../components/ComingSoon";
import DownloadAppsSection from "../components/DownloadAppsSection";

// lazyLoad
const Carousel = lazy(() => import('../components/Carousel')); // Replace with your component path
const LatestNewsSection = lazy(() => import('../components/LatestNewsSection')); // Replace with your component path
const SponsersSection = lazy(() => import('../components/SponsersSection')); // Replace with your component path
const ServicesSection = lazy(() => import('../components/ServicesSection')); // Replace with your component path
const EventsSection = lazy(() => import('../components/EventsSection')); // Replace with your component path
// const SectionTitle = lazy(() => import('../components/SectionTitle')); // Replace with your component path


export default function HomePage() {
  const getTokenLink = process.env.REACT_APP_UAE_PASS_TOKEN_LINK;
  const [loginCode, setLoginCode] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const BackENDURL = process.env.REACT_APP_BACKEND_API;
  const RedirectUAEPASS = process.env.REACT_APP_UAE_PASS_REDIRECT_LINK;
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    async function getCode() {
      if (searchParams.has("code")) {
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        setLoginCode(code); // Update loginCode state with the received code
        try {
          const getAccessToken = await axios.post(
            `${getTokenLink}?code=${code}&state=${state}`
          );
          if (getAccessToken?.data?.result !== null) {
            if (getAccessToken?.data?.result?.token !== null) {
               setAccessToken(getAccessToken?.data?.result?.token);
              const response = await axios.get(
                `${BackENDURL}/account/profile`,
                {
                  headers: {
                    Authorization: `Bearer ${getAccessToken?.data?.result?.token}`,
                  },
                }
              );
              console.log('user Profile is ' , response)
              const jsonString = JSON.stringify(response.data.result)
              localStorage.setItem("eahsUserProfile", jsonString);
              localStorage.setItem(
                "accessTokenforEAHS",
                getAccessToken.data.result.token
              );
              localStorage.setItem("EAHSUserIsUAEPASSUser", true);
              
              setTimeout(() => {
                navigate('/profile')
              }, 200);
              
            }
          } else if (getAccessToken?.data?.status?.detailedResponseCode === "Missing Id Number") {
            navigate('/login', {
              state: {
                message: currentLanguage == 'en' ? 'You are not eligible to access this service. Your account is either not upgraded or you have a visitor account. Please contact EAHS to access the services' : 'أنت غير مؤهل للوصول إلى هذه الخدمة. إما أن حسابك لم تتم ترقيته أو لديك حساب زائر. يرجى الاتصال بجمعية الخيول العربية في الامارات العربية المتحدة لتتمكن من الوصول إلى الخدمة.',
                enable: true
              }
            })
          }
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }

      if (searchParams.has("error_description")) {
        navigate('/login', { state: "Error in Login with UAE PASS" })
      }

      if (accessToken !== "") {
        const userProfile = await axios.get(
          `${BackENDURL}/account/profile`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUserProfile(userProfile.data.result);
      }
    }

    getCode();
  }, []); // Empty dependency array means this effect runs only once after the initial render

  useEffect(() => {
    // Check if access token is stored in localStorage
    const storedToken = localStorage.getItem("accessTokenforEAHS");
    if (storedToken) {
      setAccessToken(storedToken);
    }
  }, []);

  // console.log("mycodeis", loginCode);
  // console.log("accessToken is", accessToken);
  // if (userProfile !== "") {
  //   console.log("user profile is", userProfile);
  // }
  // Check if there are any query parameters

  return (
    <div className="homePage">
      <VideoSlider />
      <Suspense fallback={<Loader />}>
      <div className="mt-4">

        <Carousel isHomePage={true} />
      </div>
        <SectionTitle section_id={"services"} section_name={translate('web.ourServices', "our services")} />
        <ServicesSection isHomePage={true} />
        <SectionTitle section_id={"events"} section_name={translate('web.upcomingEvents', "upcoming events")} />
        <EventsSection />
        {/* <ComingSoon /> */}
        <LatestNewsSection />
        <SponsersSection />
        <DownloadAppsSection />
      </Suspense>

    </div>
  );
}
