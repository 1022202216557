import { createSlice } from "@reduxjs/toolkit";
import { fetchAuctions, BaseURL, initialState } from "./AuctionActions";

function mergeWithoutIntersection(arr1, arr2, propName = "auctionId") {
  // Create a set to store unique auctionIds from the first array
  const seenIds = new Set(arr1.map((obj) => obj[propName]));

  // Filter the second array to include only objects with unseen auctionIds
  const filteredArr2 = arr2.filter((obj) => !seenIds.has(obj[propName]));

  // Combine both arrays
  return [...arr1, ...filteredArr2];
}

const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    // Add any additional reducers for UI state or pagination (optional)
    resetFilter: (state) => {
      state.auctions = [];
      state.skip = 0;
      state.totalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuctions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAuctions.fulfilled, (state, action) => {
        const { result, paginationInfo } = action.payload.data;
        const {
          auctionStartDateFrom,
          auctionStartDateTo,
          numberOfHorsesTo,
          enrolled,
        } = action.payload;

        // get auctions without enrolled filter auctions and format it
        if (!enrolled) {
          const formattedData = result.map((item) => ({
            eventImageSrc:
              // item.auctionAttachment[0]?.entity === "AuctionFeatureImage"
              //   ? item.auctionAttachment[0]?.fileUrl
              //   : `${BaseURL}/assets/img/horseauction.png`,
              item.auctionAttachment.filter(
                (item) => item.entity === "AuctionFeatureImage"
              ).length > 0
                ? item.auctionAttachment.filter(
                    (item) => item.entity === "AuctionFeatureImage"
                  )[0].fileUrl
                : `${BaseURL}/assets/img/horseauction.png`,
            eventName: item.auctionName,
            auctionType: item.type,
            eventDate: new Date(item.startDate),
            eventPrice: item.auctionFees,
            EventHorses: item.numberOfHorses,
            eventLink: `/singleauction?id=${item.auctionId}`,
            eventDescription: item.description,
            auctionId: item.auctionId,
          }));

          const applyDateFilter = formattedData.filter((item) => {
            const numberhorseto = numberOfHorsesTo ?? 0;
            let startDate = auctionStartDateFrom
              ? new Date(auctionStartDateFrom)
              : null;
            let endDate = auctionStartDateTo
              ? new Date(auctionStartDateTo)
              : null;

            if (endDate) {
              endDate.setDate(endDate.getDate() + 1);
            }
            if (startDate) {
              startDate.setDate(startDate.getDate() - 1);
            }

            // If both start and end dates are provided
            if (startDate && endDate) {
              if (numberhorseto === 0) {
                return (
                  item.eventDate >= startDate &&
                  item.eventDate.setDate(item.eventDate.getDate() + 1) <=
                    endDate
                );
              } else {
                return (
                  item.eventDate >= startDate &&
                  item.eventDate.setDate(item.eventDate.getDate() + 1) <=
                    endDate &&
                  item.EventHorses <= numberhorseto
                );
              }
            }

            // If only start date is provided
            if (startDate) {
              if (numberhorseto === 0) {
                return item.eventDate >= startDate;
              } else {
                return (
                  item.eventDate >= startDate &&
                  item.EventHorses <= numberhorseto
                );
              }
            }

            // If only end date is provided
            if (endDate) {
              if (numberhorseto === 0) {
                return item.eventDate <= endDate;
              } else {
                return (
                  item.eventDate <= endDate && item.EventHorses <= numberhorseto
                );
              }
            }

            // If neither start nor end date is provided, include all items
            if (numberhorseto === 0) {
              return true;
            } else {
              return true && item.EventHorses <= numberhorseto;
            }
          });

          state.auctions = mergeWithoutIntersection(
            state.auctions,
            applyDateFilter
          );
          state.totalCount = paginationInfo.totalCount;
          state.skip += applyDateFilter.length;

          state.isLoading = false;
        }
        // get enrolled auctions and format it
        else {
          const formattedData = result.map((item) => ({
            eventImageSrc:
              item.auctionAttachment.filter(
                (item) => item.entity === "AuctionFeatureImage"
              ).length > 0
                ? item.auctionAttachment.filter(
                    (item) => item.entity === "AuctionFeatureImage"
                  )[0].fileUrl
                : `${BaseURL}/assets/img/horseauction.png`,
            eventName: item.auctionDetails.name,
            auctionType: item.auctionDetails.type,
            eventDate: new Date(item.auctionDetails.startDate),
            eventPrice: item.auctionDetails.auctionFees,
            EventHorses: item.auctionDetails.horseCount,
            eventLink: `/singleauction?id=${item.auctionDetails.id}`,
            eventDescription: item.auctionDetails.type,
            auctionId: item.auctionDetails.id,
          }));

          const applyDateFilter = formattedData.filter((item) => {
            const numberhorseto = numberOfHorsesTo ?? 0;
            let startDate = auctionStartDateFrom
              ? new Date(auctionStartDateFrom)
              : null;
            let endDate = auctionStartDateTo
              ? new Date(auctionStartDateTo)
              : null;

            if (endDate) {
              endDate.setDate(endDate.getDate() + 1);
            }
            if (startDate) {
              startDate.setDate(startDate.getDate() - 1);
            }

            // If both start and end dates are provided
            if (startDate && endDate) {
              if (numberhorseto === 0) {
                return (
                  item.eventDate >= startDate &&
                  item.eventDate.setDate(item.eventDate.getDate() + 1) <=
                    endDate
                );
              } else {
                return (
                  item.eventDate >= startDate &&
                  item.eventDate.setDate(item.eventDate.getDate() + 1) <=
                    endDate &&
                  item.EventHorses <= numberhorseto
                );
              }
            }

            // If only start date is provided
            if (startDate) {
              if (numberhorseto === 0) {
                return item.eventDate >= startDate;
              } else {
                return (
                  item.eventDate >= startDate &&
                  item.EventHorses <= numberhorseto
                );
              }
            }

            // If only end date is provided
            if (endDate) {
              if (numberhorseto === 0) {
                return item.eventDate <= endDate;
              } else {
                return (
                  item.eventDate <= endDate && item.EventHorses <= numberhorseto
                );
              }
            }

            // If neither start nor end date is provided, include all items
            if (numberhorseto === 0) {
              return true;
            } else {
              return true && item.EventHorses <= numberhorseto;
            }
          });

          state.auctions = mergeWithoutIntersection(
            state.auctions,
            applyDateFilter
          );
          state.totalCount = paginationInfo.totalCount;
          state.skip += applyDateFilter.length;

          state.isLoading = false;
        }
      })
      .addCase(fetchAuctions.rejected, (state) => {
        state.isLoading = false;
        // Handle error cases (optional)
      });
  },
});

export const { resetFilter } = auctionsSlice.actions;
export default auctionsSlice.reducer;
