// auctionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const fetchAuctionData = createAsyncThunk(
  "auction/fetchAuctionData",
  async (auctionId, thunkAPI) => {
    try {
      const response = await axios.get(`/auction/id?Id=${auctionId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

function getTermsURL(result) {
  console.debug('result' , result)

  const lang = localStorage.getItem("eahsLang");
  if (lang == "en") {
    const termsAndConditions = result.auctionAttachment.filter(
      (item) => item.entity == "AuctionTermsAndConditions"
    );
    return termsAndConditions[0]?.fileUrl ?? "";
  } else {
    const termsAndConditions = result.auctionAttachment.filter(
      (item) => item.entity == "AuctionTermsAndConditionsAr"
    );
    return termsAndConditions[0]?.fileUrl ?? "";
  }
}

const auctionSlice = createSlice({
  name: "auction",
  initialState: {
    auctionData: null,
    loading: false,
    error: null,
    auctionHorses: [],
    auctionAttachment: [],
  },
  reducers: {
    // Other reducers if needed
    getAuctionHourses: (state) => {
      return state.auctionHorses;
    },
    resetAuctionData: (state) => {
      state.auctionData = null;
      state.loading = false;
      state.error = null;
      state.auctionHorses = [];
      state.auctionAttachment = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAuctionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuctionData.fulfilled, (state, action) => {
        const { result } = action.payload;
        const formattedData = {
          auctionName: result.name,
          auuctionId: result.id,
          auctionDesc: result.auctionNumber,
          eventId:result.eventId,
          auctionStartDate: result.startDate,
          auctionEndDate: result.endDate,
          auctionNumberOfHorses: result.horseCount,
          auctionType: result.type,
          auctionFees: result.auctionFees,
          auctionHorses: result.auctionSelectedHorses,
          auctionAttachment: result.auctionAttachment,
          reservedBidNoStartDate: result.reservedBidNoStartDate,
          reservedBidNoEndDate: result.reservedBidNoEndDate,
          inspectionStartDate: result.inspectionStartDate,
          inspectionEndDate: result.inspectionEndDate,
          location: result.location,
          termsUrl: getTermsURL(result) ?? "",
          introVideoUrl: result.introVideoUrl,
          liveUrl: result.liveUrl,
        };
        debugger;
        state.loading = false;
        state.auctionData = formattedData;
        state.auctionHorses = result.auctionSelectedHorses;
        state.auctionAttachment = result.auctionAttachment;
      })
      .addCase(fetchAuctionData.rejected, (state) => {
        state.loading = false;
        // Handle error cases (optional)
      });
  },
});
export const { getAuctionHourses, resetAuctionData } = auctionSlice.actions;

export default auctionSlice.reducer;
