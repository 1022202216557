import { createSlice } from "@reduxjs/toolkit";
import {
  FetchAddEditHorseQualificationthunk,
  FetchAddEditInternationalHorsethunk,
  FetchAddJudgeConflictthunk,
  FetchCreateShowEntryRegisteredthunk,
  FetchCreateShowEntrythunk,
  FetchDeleteJudgeConflictthunk,
  FetchGetHorseQualificationByHorseIdthunk,
  FetchInternationalHorsesByIdthunk,
  FetchMyShowParticipantsRequestthunk,
  FetchOwnerRequestsCountthunk,
  FetchSearchInternationalHorsesthunk,
  FetchShowConflictsthunk,
  FetchShowEntryClassesthunk,
  FetchShowEntryFilesthunk,
  FetchShowEntryOwnerHorsesthunk,
  FetchShowEntrybyIdthunk,
  FetchShowEntrythunk,
  FetchShowRequestHorsesthunk,
  FetchShowRequestthunk,
  FetchShowSchedulethunk,
  FetchShowTermsthunk,
  FetchSubOwnerProfilesByIdthunk,
} from "./ShowEntryAction";

const initialState = {
  showEntryData: [],
  showEntrybyIdData: {},
  showEntryFilesData: [],
  showEntryOwnerHorsesData: [],
  showEntryClassesData: [],
  showScheduleData: [],
  searchInternationalHorsesData: [],
  myShowParticipantsRequestData: [],
  myShowParticipantsRequestChoosesData: [],
  internationalHorsesByIdData: {},
  showRequestData: [],
  showRequestHorsesData: [],
  showConflictsData: [],
  subOwnerProfilesByIdData: [],
  showTermsData: [],
  showEntryTempData: [],
  ownerRequestsCountData: {},
  addEditHorseQualificationData: {},
  getHorseQualificationByHorseIdData: {},
  createShowEntryData: null,
  addEditInternationalHorseData: null,
  addJudgeConflictData: null,
  takeShowEntry: 12,
  pageShowEntry: 0,
  totalCountShowEntry: 0,
  showEntryLoading: false,
  showEntryError: null,
  stateShowMoreButtonShowEntry: false,
  toasterMessageShowEntry: null,
  navigateStatus: false,
  deleteJudgeConflictData: null,
  activeTabOwnerProfile: "accountSetting",
  isDraftRegistered: true,
  isDraftUnRegistered: false,
};

const ShowEntrySlice = createSlice({
  name: "ShowEntry",
  initialState,
  reducers: {
    incrementPageShowEntry: (state) => {
      state.pageShowEntry += 1;
    },

    emptyPageShowEntry: (state) => {
      state.pageShowEntry = 0;
    },
    resetInternationalHorsesByIdData: (state) => {
      state.internationalHorsesByIdData = {};
    },

    resetSearchShowEntry: (state) => {
      state.pageShowEntry = 1;
      state.showEntryData = [];
      state.totalCountShowEntry = 0;
    },
    resetToasterMessageShowEntry: (state) => {
      state.toasterMessageShowEntry = null;
      state.navigateStatus = true;
    },
    resetNavigateStatus: (state) => {
      state.navigateStatus = false;
    },
    resetIsDraftStatus: (state) => {
      state.isDraftUnRegistered = false;
      state.isDraftRegistered = true;
    },
    myShowParticipantsRequestChooses: (state, action) => {
      state.myShowParticipantsRequestChoosesData = action.payload;
    },
    handelActiveTabOwnerProfile: (state, action) => {
      state.activeTabOwnerProfile = action.payload;
      state.showEntryData = [];
    },
    handelShowEntryTempData: (state, action) => {
      if (action.payload.type === "add") {
        state.showEntryTempData = state.showEntryTempData.concat(
          action.payload.data
        );
      } else {
        if (state.showEntryTempData.length > 0) {
          state.showEntryTempData = state.showEntryTempData.filter(
            (item) => item.horseId !== action.payload.data
          );
        }
      }
    },
    handelResetShowEntryTempData: (state, action) => {
      state.showEntryTempData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchShowEntrythunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowEntrythunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;

      if (state.page === 1) {
        state.showEntryData = action.payload.result;
      } else {
        state.showEntryData = state.showEntryData.concat(action.payload.result);
      }

      if (
        (state.showEntryData.length > 0 &&
          state.showEntryData.length < state.take) ||
        action.payload.totalCount === state.showEntryData.length
      ) {
        state.stateShowMoreButtonShowEntry = true;
      } else {
        state.stateShowMoreButtonShowEntry = false;
      }
      state.totalCountShowEntry = action.payload.totalCount;
    });
    builder.addCase(FetchShowEntrythunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showEntryData = [];
      state.stateShowMoreButtonShowEntry = false;
      state.totalCountShowEntry = 0;
    });

    builder.addCase(FetchShowEntrybyIdthunk.pending, (state) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowEntrybyIdthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showEntrybyIdData = action.payload.result;
    });
    builder.addCase(FetchShowEntrybyIdthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showEntrybyIdData = {};
    });

    builder.addCase(FetchShowEntryFilesthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowEntryFilesthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryFilesData = action.payload.result;
    });
    builder.addCase(FetchShowEntryFilesthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showEntryFilesData = [];
    });

    builder.addCase(FetchShowEntryOwnerHorsesthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(
      FetchShowEntryOwnerHorsesthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryOwnerHorsesData = action.payload.horsesList;
        //state.showEntryOwnerHorsesData[0].isQualified = false;
      }
    );
    builder.addCase(
      FetchShowEntryOwnerHorsesthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.showEntryOwnerHorsesData = [];
      }
    );

    builder.addCase(FetchShowEntryClassesthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowEntryClassesthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryClassesData = action.payload.result;
    });
    builder.addCase(FetchShowEntryClassesthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showEntryClassesData = [];
    });

    builder.addCase(FetchShowSchedulethunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowSchedulethunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showScheduleData = action.payload.result;
    });
    builder.addCase(FetchShowSchedulethunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showScheduleData = [];
    });

    builder.addCase(FetchCreateShowEntrythunk.pending, (state, action) => {
      state.showEntryLoading = true;
      state.toasterMessageShowEntry = null;
      state.isDraftUnRegistered = false;
    });
    builder.addCase(FetchCreateShowEntrythunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.createShowEntryData = action.payload.result;
      state.toasterMessageShowEntry = "save";
      state.isDraftUnRegistered = true;
    });
    builder.addCase(FetchCreateShowEntrythunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.createShowEntryData = null;
      state.toasterMessageShowEntry = null;
      state.isDraftUnRegistered = false;
    });

    builder.addCase(
      FetchCreateShowEntryRegisteredthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
        state.toasterMessageShowEntry = null;
        state.isDraftRegistered = true;
      }
    );
    builder.addCase(
      FetchCreateShowEntryRegisteredthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.createShowEntryData = action.payload.result;
        state.toasterMessageShowEntry = "save";
        state.isDraftRegistered = false;
      }
    );
    builder.addCase(
      FetchCreateShowEntryRegisteredthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.createShowEntryData = null;
        state.toasterMessageShowEntry = null;
        state.isDraftRegistered = true;
      }
    );

    builder.addCase(
      FetchSearchInternationalHorsesthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
      }
    );
    builder.addCase(
      FetchSearchInternationalHorsesthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.searchInternationalHorsesData = action.payload.result;
      }
    );
    builder.addCase(
      FetchSearchInternationalHorsesthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.searchInternationalHorsesData = [];
      }
    );

    builder.addCase(
      FetchInternationalHorsesByIdthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
      }
    );
    builder.addCase(
      FetchInternationalHorsesByIdthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.internationalHorsesByIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchInternationalHorsesByIdthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.internationalHorsesByIdData = {};
      }
    );

    builder.addCase(
      FetchAddEditInternationalHorsethunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
        state.toasterMessageShowEntry = null;
      }
    );
    builder.addCase(
      FetchAddEditInternationalHorsethunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.addEditInternationalHorseData = action.payload.result;
        state.toasterMessageShowEntry = "save";
        state.internationalHorsesByIdData = {};
      }
    );
    builder.addCase(
      FetchAddEditInternationalHorsethunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.addEditInternationalHorseData = null;
        state.toasterMessageShowEntry = null;
      }
    );

    builder.addCase(
      FetchMyShowParticipantsRequestthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
      }
    );
    builder.addCase(
      FetchMyShowParticipantsRequestthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.myShowParticipantsRequestData = action.payload.result;
      }
    );
    builder.addCase(
      FetchMyShowParticipantsRequestthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.myShowParticipantsRequestData = [];
      }
    );

    builder.addCase(FetchShowRequestthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowRequestthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showRequestData = action.payload.result;
    });
    builder.addCase(FetchShowRequestthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showRequestData = [];
    });

    builder.addCase(FetchShowRequestHorsesthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowRequestHorsesthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showRequestHorsesData = action.payload.result;
    });
    builder.addCase(FetchShowRequestHorsesthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showRequestHorsesData = [];
    });

    builder.addCase(FetchAddJudgeConflictthunk.pending, (state, action) => {
      state.showEntryLoading = true;
      state.toasterMessageShowEntry = null;
    });
    builder.addCase(FetchAddJudgeConflictthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.addJudgeConflictData = action.payload.result;
      state.toasterMessageShowEntry = "save";
    });
    builder.addCase(FetchAddJudgeConflictthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.addJudgeConflictData = null;
      state.toasterMessageShowEntry = null;
    });

    builder.addCase(FetchShowConflictsthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowConflictsthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showConflictsData = action.payload.result;
    });
    builder.addCase(FetchShowConflictsthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showConflictsData = [];
    });

    builder.addCase(FetchDeleteJudgeConflictthunk.pending, (state, action) => {
      state.showEntryLoading = true;
      state.toasterMessageShowEntry = null;
    });
    builder.addCase(
      FetchDeleteJudgeConflictthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.deleteJudgeConflictData = action.payload.result;
        state.toasterMessageShowEntry = "save";
      }
    );
    builder.addCase(FetchDeleteJudgeConflictthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.deleteJudgeConflictData = null;
      state.toasterMessageShowEntry = null;
    });

    builder.addCase(FetchSubOwnerProfilesByIdthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(
      FetchSubOwnerProfilesByIdthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.subOwnerProfilesByIdData = action.payload.result;
        // state.subOwnerProfilesByIdData = [
        //   {
        //     text: "ahmed",
        //     value: "1",
        //   },
        //   {
        //     text: "ramy",
        //     value: "2",
        //   },
        // ];
      }
    );
    builder.addCase(
      FetchSubOwnerProfilesByIdthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.subOwnerProfilesByIdData = [];
      }
    );

    builder.addCase(FetchShowTermsthunk.pending, (state, action) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchShowTermsthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.showTermsData = action.payload.result;
    });
    builder.addCase(FetchShowTermsthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.showTermsData = [];
    });

    builder.addCase(FetchOwnerRequestsCountthunk.pending, (state) => {
      state.showEntryLoading = true;
    });
    builder.addCase(FetchOwnerRequestsCountthunk.fulfilled, (state, action) => {
      state.showEntryLoading = false;
      state.ownerRequestsCountData = action.payload.result;
    });
    builder.addCase(FetchOwnerRequestsCountthunk.rejected, (state, action) => {
      state.showEntryLoading = false;
      state.showEntryError = action.payload;
      state.ownerRequestsCountData = {};
    });

    builder.addCase(
      FetchAddEditHorseQualificationthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
        state.toasterMessageShowEntry = null;
      }
    );
    builder.addCase(
      FetchAddEditHorseQualificationthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.addEditHorseQualificationData = action.payload.result;
        state.toasterMessageShowEntry = "save";
      }
    );
    builder.addCase(
      FetchAddEditHorseQualificationthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.addEditHorseQualificationData = null;
        state.toasterMessageShowEntry = null;
      }
    );

    builder.addCase(
      FetchGetHorseQualificationByHorseIdthunk.pending,
      (state, action) => {
        state.showEntryLoading = true;
      }
    );
    builder.addCase(
      FetchGetHorseQualificationByHorseIdthunk.fulfilled,
      (state, action) => {
        state.showEntryLoading = false;
        state.getHorseQualificationByHorseIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetHorseQualificationByHorseIdthunk.rejected,
      (state, action) => {
        state.showEntryLoading = false;
        state.showEntryError = action.payload;
        state.getHorseQualificationByHorseIdData = null;
      }
    );
  },
});

export const {
  incrementPageShowEntry,
  emptyPageShowEntry,
  resetSearchShowEntry,
  resetToasterMessageShowEntry,
  myShowParticipantsRequestChooses,
  resetNavigateStatus,
  handelActiveTabOwnerProfile,
  handelShowEntryTempData,
  handelResetShowEntryTempData,
  resetInternationalHorsesByIdData,
  resetIsDraftStatus,
} = ShowEntrySlice.actions;
export default ShowEntrySlice.reducer;
