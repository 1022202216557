import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import ActionButton from "../components/ActionButton";
import UploadFile from "../components/UploadFile";
import { useLocation, useNavigate } from "react-router";
import {
  FetchCountriesthunk,
  FetchHorseColorsthunk,
} from "../Redux/LookUp/LookUpAction";
import {
  FetchAddEditInternationalHorsethunk,
  FetchInternationalHorsesByIdthunk,
  FetchSearchInternationalHorsesthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  resetInternationalHorsesByIdData,
  resetNavigateStatus,
  resetToasterMessageShowEntry,
} from "../Redux/ShowEntry/ShowEntrySlice";
import { isLessThanOneYearOld } from "../helper/isLessThanOneYearOld";

export default function AddRegisterHorse() {
  const [websiteURLNotValidMsg, setWebsiteURLNotValidMsg] =
    useState("classnotdisplay");
  const navigate = useNavigate();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { countriesData, horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const {
    showEntryLoading,
    searchInternationalHorsesData,
    internationalHorsesByIdData,
    toasterMessageShowEntry,
    addEditInternationalHorseData,
    navigateStatus,
  } = useSelector((state) => state.showEntrySlice);

  const [openPopup, setOpenPopup] = useState(false);
  const [noRequiredQualifications, setNoRequiredQualifications] =
    useState(false);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const currentVal = useRef(-1);

  const [formState, setFormState] = useState({
    id: null,
    horseNameEn: "",
    origin: null,
    originDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    ueln: "",
    horseNameAr: "",
    birthDate: null,
    gender: null,
    genderDrop: {
      label: translate("web.gender", "Gender"),
      value: null,
    },
    ownerNameEn: "",
    ownerNameAr: "",
    regNo: "",
    breederInfo: "",
    breederNameAr: "",
    breederNameEn: "",
    color: null,
    colorDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    sireNameEn: "",
    sireNameAr: "",
    damNameEn: "",
    damNameAr: "",
    ownedByMe: true,
    country: null,
    countryDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },
    addressDetails: "",
    //horseQualificationsList: [],
    horseDocumentsList: [],

    idQual: -1,
    countryQual: null,
    countryQualDrop: {
      label: translate("web.countryName", "Country Name"),
      value: null,
    },
    showName: "",
    year: null,
    className: "",
    classRank: "",
    websiteURL: "",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const maxHorses = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  const showNameAr = searchParams.has("showNameAr")
    ? searchParams.get("showNameAr")
    : null;

  const showNameEn = searchParams.has("showNameEn")
    ? searchParams.get("showNameEn")
    : null;
  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  const entryFees = searchParams.has("entryFees")
    ? searchParams.get("entryFees")
    : null;

  const unregisterHorseId = searchParams.has("unregisterHorseId")
    ? searchParams.get("unregisterHorseId")
    : null;

  const registerStartDate = searchParams.has("registerStartDate")
    ? searchParams.get("registerStartDate")
    : null;

  const registerEndDate = searchParams.has("registerEndDate")
    ? searchParams.get("registerEndDate")
    : null;

  const registerStartDateInter = searchParams.has("registerStartDateInter")
    ? searchParams.get("registerStartDateInter")
    : null;

  const registerEndDateInter = searchParams.has("registerEndDateInter")
    ? searchParams.get("registerEndDateInter")
    : null;

  const horseQualificationsCardReset = () => {
    return {
      id: currentVal.current,
      countryDrop: {
        label: translate("web.countryName", "Country Name"),
        value: null,
      },
      showName: "",
      year: "",
      className: "",
      classRank: "",
      classRankDrop: {
        label: translate("web.classRank", "Class Rank"),
        value: null,
      },
      websiteURL: "",
    };
  };

  const [horseQualificationsCards, setHorseQualificationsCards] = useState([]);
  const [horseQualificationsCard, setHorseQualificationsCard] = useState(
    horseQualificationsCardReset()
  );

  // console.log("horseQualificationsCards", horseQualificationsCards);
  const [birthDate, setBirthdate] = useState(null);
  const [yearDate, setYeardate] = useState(null);

  const [uploadPassport, setUploadPassport] = useState([]);
  const [uploadOwnershipProof, setUploadOwnershipProof] = useState([]);
  const [checkHorse, setCheckHorse] = useState(false);
  const [checkHorseButton, setCheckHorseButton] = useState(false);

  const [
    uploadCertificationForQualification,
    setUploadCertificationForQualification,
  ] = useState([]);

  useEffect(() => {
    dispatch(FetchStudBookCountriesthunk(currentLanguage));
    dispatch(FetchCountriesthunk({ langauge: currentLanguage }));
    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    if (unregisterHorseId != null) {
      handelHorseSelect(unregisterHorseId, false);
    } else {
      dispatch(resetInternationalHorsesByIdData());
    }
  }, [currentLanguage]);

  const handleUploadPassportChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      //setUploadPassportUrl(URL.createObjectURL(e.target.files[0]));
      let fileList = [];
      let fileListHook = [];

      for (let index = 0; index < e.target.files.length; index++) {
        if (e.target.files[index].size > 10 * 1024 * 1024) {
          checkFileSize = false;
        } else {
          fileList.push({
            id: null,
            documentUrl: null,
            documentType: "PassPort",
            documentFile: e.target.files[index],
          });

          fileListHook.push({
            file: URL.createObjectURL(e.target.files[index]),
            name: e.target.files[index].name,
          });
        }
      }

      if (!checkFileSize) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      }

      setUploadPassport(fileListHook);

      setFormState({
        ...formState,
        horseDocumentsList: [
          ...formState.horseDocumentsList.filter(
            (oldValue) => oldValue.documentType !== "PassPort"
          ),
          ...fileList,
        ],
      });
    }
  };

  const handleUploadOwnershipProofChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      //setUploadOwnershipProofUrl(URL.createObjectURL(e.target.files[0]));

      let fileList = [];
      let fileListHook = [];

      for (let index = 0; index < e.target.files.length; index++) {
        if (e.target.files[index].size > 10 * 1024 * 1024) {
          checkFileSize = false;
        } else {
          fileList.push({
            id: null,
            documentUrl: null,
            documentType: "OwnerProof",
            documentFile: e.target.files[index],
          });

          fileListHook.push({
            file: URL.createObjectURL(e.target.files[index]),
            name: e.target.files[index].name,
          });
        }
      }

      if (!checkFileSize) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      }

      setUploadOwnershipProof(fileListHook);

      setFormState({
        ...formState,
        horseDocumentsList: [
          ...formState.horseDocumentsList.filter(
            (oldValue) => oldValue.documentType !== "OwnerProof"
          ),
          ...fileList,
        ],
      });
    }
  };

  const handleUploadCertificationForQualificationChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      let fileList = [];
      let fileListHook = [];

      for (let index = 0; index < e.target.files.length; index++) {
        if (e.target.files[index].size > 10 * 1024 * 1024) {
          checkFileSize = false;
        } else {
          fileList.push({
            id: null,
            documentUrl: null,
            documentType: "Certifications",
            documentFile: e.target.files[index],
          });

          fileListHook.push({
            file: URL.createObjectURL(e.target.files[index]),
            name: e.target.files[index].name,
          });
        }
      }

      if (!checkFileSize) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      }
      setUploadCertificationForQualification(fileListHook);

      setFormState({
        ...formState,
        horseDocumentsList: [
          ...formState.horseDocumentsList.filter(
            (oldValue) => oldValue.documentType !== "Certifications"
          ),
          ...fileList,
        ],
      });
    }
  };

  const handleHorseQualificationsCardChange = (event, input) => {
    if (!!input && input === "country") {
      setHorseQualificationsCard({
        ...horseQualificationsCard,
        countryDrop: event,
      });
    }
    // else if (!!input && input === "classRank") {
    //   setHorseQualificationsCard({
    //     ...horseQualificationsCard,
    //     classRankDrop: event,
    //   });
    // }
    else if (event.target.name === "websiteURL") {
      if (validUrl(event.target.value) === false && event.target.value !== "") {
        setWebsiteURLNotValidMsg("classdisplay");
      } else {
        setWebsiteURLNotValidMsg("classnotdisplay");
      }
      setHorseQualificationsCard({
        ...horseQualificationsCard,
        [event.target.name]: event.target.value,
      });
    } else {
      setHorseQualificationsCard({
        ...horseQualificationsCard,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleFormChange = (event, input) => {
    if (!!input && input === "origin") {
      setFormState({
        ...formState,
        origin: event.value,
        originDrop: event,
      });
    } else if (!!input && input === "gender") {
      setFormState({
        ...formState,
        gender: event.value,
        genderDrop: event,
      });
    } else if (!!input && input === "color") {
      setFormState({
        ...formState,
        color: event.value,
        colorDrop: event,
      });
    } else if (!!input && input === "country") {
      setFormState({
        ...formState,
        country: event.value,
        countryDrop: event,
      });
    } else if (!!input && input === "countryQual") {
      setFormState({
        ...formState,
        countryQual: event.value,
        countryQualDrop: event,
      });
    } else if (!!input && input === "birthDate") {
      setBirthdate(event);

      if (!isLessThanOneYearOld(new Date(event))) {
        setNoRequiredQualifications(false);
      }

      setFormState({
        ...formState,
        birthDate: event.$d.toISOString(),
      });
    } else if (!!input && input === "year") {
      setYeardate(event);
      setFormState({
        ...formState,
        year: event.$d.toISOString(),
      });
    } else if (event.target.name === "ownedByMe") {
      if (event.target.checked) {
        setFormState({
          ...formState,
          ownedByMe: event.target.checked,
          ownerNameEn: "",
          ownerNameAr: "",
        });
      } else {
        setFormState({
          ...formState,
          ownedByMe: event.target.checked,
        });
      }
    } else {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handelHorseSearch = () => {
    if (
      (formState.horseNameEn !== "" && formState.origin != null) ||
      formState.ueln !== ""
    ) {
      let params = {
        name: formState.horseNameEn,
        countryId: formState.origin,
        uelnNo: formState.ueln,
        langauge: currentLanguage,
      };
      dispatch(FetchSearchInternationalHorsesthunk(params));
      setCheckHorseButton(false);
      setCheckHorse(false);
      setOpenPopup((o) => !o);
    }
  };

  const handelHorseSelect = (id, isOpenPopup) => {
    let params = {
      id,
      langauge: currentLanguage,
    };
    dispatch(FetchInternationalHorsesByIdthunk(params));
    setCheckHorse(true);
    if (isOpenPopup) {
      setOpenPopup((o) => !o);
    }
  };

  useEffect(() => {
    if (Object.keys(internationalHorsesByIdData).length > 0) {
      // console.log("internationalHorsesByIdData", internationalHorsesByIdData);

      var lastHorseQualification =
        internationalHorsesByIdData.horseQualificationsList.length > 0
          ? internationalHorsesByIdData.horseQualificationsList[
              internationalHorsesByIdData.horseQualificationsList.length - 1
            ]
          : null;

      setFormState({
        ...formState,
        id: internationalHorsesByIdData.id,
        addressDetails: internationalHorsesByIdData.address,
        country: internationalHorsesByIdData.currentCountryId,
        countryDrop:
          internationalHorsesByIdData.currentCountryId == null
            ? {
                label: translate("web.horseCountry", "Horse Country"),
                value: null,
              }
            : {
                label: countriesData.find(
                  (item) =>
                    item.value === internationalHorsesByIdData.currentCountryId
                )?.text,
                value: internationalHorsesByIdData.currentCountryId,
              },
        countryQual:
          lastHorseQualification != null
            ? lastHorseQualification.countryId
            : null,
        countryQualDrop:
          lastHorseQualification == null ||
          lastHorseQualification.countryId == null
            ? {
                label: translate("web.countryName", "Country Name"),
                value: null,
              }
            : {
                label: countriesData.find(
                  (item) => item.value === lastHorseQualification.countryId
                )?.text,
                value: lastHorseQualification.countryId,
              },
        origin: internationalHorsesByIdData.originId,
        originDrop:
          internationalHorsesByIdData.originId == null
            ? {
                label: translate("web.chooseOrigin", "Choose Origin"),
                value: null,
              }
            : {
                label: countriesData.find(
                  (item) => item.value === internationalHorsesByIdData.originId
                )?.text,
                value: internationalHorsesByIdData.originId,
              },
        color: internationalHorsesByIdData.horseColorId,
        colorDrop:
          internationalHorsesByIdData.horseColorId == null
            ? {
                label: translate("web.color", "Color"),
                value: null,
              }
            : {
                label:
                  horseColorsData.find(
                    (item) =>
                      item.id === internationalHorsesByIdData.horseColorId
                  )?.nameEn ?? "",
                value: internationalHorsesByIdData.horseColorId,
              },
        horseNameEn: internationalHorsesByIdData?.nameEn,
        horseNameAr: internationalHorsesByIdData?.nameAr,
        ueln: internationalHorsesByIdData?.horseUELN,
        regNo: internationalHorsesByIdData?.registrationNumber,
        sireNameAr: internationalHorsesByIdData?.sireNameAr,
        sireNameEn: internationalHorsesByIdData?.sireNameEn,
        damNameEn: internationalHorsesByIdData?.damNameEn,
        damNameAr: internationalHorsesByIdData?.damNameAr,
        ownerNameAr:
          userProfile.accountType != "Agent"
            ? internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("EAHSUserID")
              ? ""
              : internationalHorsesByIdData?.ownerNameAr
            : internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("currentSubAccountId")
            ? ""
            : internationalHorsesByIdData?.ownerNameAr,
        ownerNameEn:
          userProfile.accountType != "Agent"
            ? internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("EAHSUserID")
              ? ""
              : internationalHorsesByIdData?.ownerNameEn
            : internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("currentSubAccountId")
            ? ""
            : internationalHorsesByIdData?.ownerNameEn,
        //breederInfo: internationalHorsesByIdData.breederNameEn,
        breederNameAr: internationalHorsesByIdData?.breederNameAr,
        breederNameEn: internationalHorsesByIdData?.breederNameEn,
        birthDate: new Date(
          internationalHorsesByIdData?.birthDate
        ).toISOString(),
        //year: "2024",
        year:
          lastHorseQualification != null && lastHorseQualification.year != null
            ? new Date(lastHorseQualification.year).toISOString()
            : null,
        ownedByMe:
          userProfile.accountType != "Agent"
            ? internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("EAHSUserID")
            : internationalHorsesByIdData?.ownerId ===
              localStorage.getItem("currentSubAccountId"),
        gender: internationalHorsesByIdData?.gender,
        genderDrop: {
          label:
            internationalHorsesByIdData?.gender === 1
              ? translate("web.mare", "Mare")
              : internationalHorsesByIdData?.gender === 2
              ? translate("web.stallion", "Stallion")
              : translate("web.gelding", "Gelding"),
          value: internationalHorsesByIdData?.gender,
        },
        idQual: lastHorseQualification != null ? lastHorseQualification.id : -1,
        classRank:
          lastHorseQualification != null
            ? lastHorseQualification.classRank
            : "",
        showName:
          lastHorseQualification != null ? lastHorseQualification.showName : "",
        websiteURL:
          lastHorseQualification != null ? lastHorseQualification.website : "",
        className:
          lastHorseQualification != null
            ? lastHorseQualification.className
            : "",
        // horseQualificationsList:
        //   internationalHorsesByIdData.horseQualificationsList.map((item) => {
        //     return {
        //       id: item.id,
        //       classRank: item.classRank,
        //       countryId: item.countryId,
        //       showName: item.showName,
        //       website: item.website,
        //       year: item.year,
        //       className: item.className,
        //     };
        //   }),
        horseDocumentsList: internationalHorsesByIdData?.horseDocumentsList
          //.filter((item) => item.documentType !== "Certifications")
          .map((item) => {
            return {
              id: item.id,
              documentUrl: item.documentUrl,
              documentType: item.documentType,
              documentFile: null,
            };
          }),
      });

      if (
        isLessThanOneYearOld(new Date(internationalHorsesByIdData?.birthDate))
      ) {
        setNoRequiredQualifications(
          lastHorseQualification != null ? false : true
        );
      }

      //0889
      setBirthdate(dayjs(internationalHorsesByIdData?.birthDate));

      setYeardate(
        lastHorseQualification != null && lastHorseQualification.year != null
          ? dayjs(lastHorseQualification.year)
          : null
      );

      setUploadPassport(
        internationalHorsesByIdData.horseDocumentsList
          .filter((item) => item.documentType === "PassPort")
          .map((item) => {
            return {
              file: item.documentUrl,
              name: item.documentUrl.substr(
                item.documentUrl.lastIndexOf("/") + 1
              ),
            };
          })
      );

      setUploadOwnershipProof(
        internationalHorsesByIdData.horseDocumentsList
          .filter((item) => item.documentType === "OwnerProof")
          .map((item) => {
            return {
              file: item.documentUrl,
              name: item.documentUrl.substr(
                item.documentUrl.lastIndexOf("/") + 1
              ),
            };
          })
      );

      setUploadCertificationForQualification(
        internationalHorsesByIdData.horseDocumentsList
          .filter((item) => item.documentType === "Certifications")
          .map((item) => {
            return {
              file: item.documentUrl,
              name: item.documentUrl.substr(
                item.documentUrl.lastIndexOf("/") + 1
              ),
            };
          })
      );

      // setHorseQualificationsCards(
      //   internationalHorsesByIdData.horseQualificationsList.map((item) => {
      //     return {
      //       id: item.id,
      //       countryDrop: {
      //         label: countriesData.find(
      //           (countryItem) => countryItem.value === item.countryId
      //         ).text,
      //         value: item.countryId,
      //       },
      //       classRank: item.classRank,
      //       classRankDrop: {
      //         label: item.classRank,
      //         value: item.classRank,
      //       },
      //       showName: item.showName,
      //       websiteURL: item.website,
      //       year: item.year,
      //       className: item.className,
      //     };
      //   })
      // );
    } else {
      setFormState({
        id: null,
        horseNameEn: "",
        origin: null,
        originDrop: {
          label: translate("web.chooseOrigin", "Choose Origin"),
          value: null,
        },
        ueln: "",
        horseNameAr: "",
        birthDate: null,
        gender: null,
        genderDrop: {
          label: translate("web.gender", "Gender"),
          value: null,
        },
        ownerNameEn: "",
        ownerNameAr: "",
        regNo: "",
        breederInfo: "",
        breederNameAr: "",
        breederNameEn: "",
        color: null,
        colorDrop: {
          label: translate("web.color", "Color"),
          value: null,
        },
        sireNameEn: "",
        sireNameAr: "",
        damNameEn: "",
        damNameAr: "",
        ownedByMe: true,
        country: null,
        countryDrop: {
          label: translate("web.horseCountry", "Horse Country"),
          value: null,
        },
        addressDetails: "",
        //horseQualificationsList: [],
        horseDocumentsList: [],

        idQual: -1,
        countryQualDrop: {
          label: translate("web.countryName", "Country Name"),
          value: null,
        },
        showName: "",
        year: null,
        className: "",
        classRank: "",
        websiteURL: "",
      });
    }
  }, [internationalHorsesByIdData]);

  const closeModal = () => setOpenPopup(false);
  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));

  const handelFormSubmit = () => {
    if (
      formState.horseNameAr !== "" &&
      formState.origin != null &&
      formState.horseNameEn !== "" &&
      formState.birthDate != null &&
      formState.gender != null &&
      ((formState.ownedByMe &&
        formState.ownerNameAr === "" &&
        formState.ownerNameEn === "") ||
        (!formState.ownedByMe &&
          formState.ownerNameAr !== "" &&
          formState.ownerNameEn !== "")) &&
      formState.regNo !== "" &&
      //formState.breederInfo !== "" &&
      formState.breederNameAr !== "" &&
      formState.breederNameEn !== "" &&
      formState.color != null &&
      formState.sireNameAr !== "" &&
      formState.sireNameEn !== "" &&
      formState.damNameAr !== "" &&
      formState.damNameEn !== "" &&
      formState.country != null &&
      ((formState.countryQual != null &&
        formState.showName !== "" &&
        formState.year != null &&
        formState.className !== "" &&
        formState.classRank !== "" &&
        !noRequiredQualifications) ||
        noRequiredQualifications) &&
      formState.addressDetails !== "" &&
      //formState.websiteURL !== "" &&
      //formState.horseQualificationsList.length > 0 &&
      formState.horseDocumentsList.length > 0 &&
      formState.horseDocumentsList.some(
        (obj) => obj.documentType === "PassPort"
      ) &&
      formState.horseDocumentsList.some(
        (obj) => obj.documentType === "OwnerProof"
      )
    ) {
      let horseQualification = [
        {
          id: formState.idQual,
          countryId: formState.countryQual,
          showName: formState.showName,
          year: formState.year,
          classRank: formState.classRank,
          className: formState.className,
          website: formState.websiteURL,
        },
      ];

      let params = {
        id: formState.id,
        createdBy:
          userProfile.accountType != "Agent"
            ? localStorage.getItem("currentSubAccountId")
            : localStorage.getItem("EAHSUserID"),
        nameEn: formState.horseNameEn,
        nameAr: formState.horseNameAr,
        gender: formState.gender,
        birthDate: formState.birthDate,
        horseColorId: formState.color,
        address: formState.addressDetails,
        horseUELN: formState.ueln,
        originId: formState.origin,
        registrationNumber: formState.regNo,
        //breederInfo: formState.breederInfo,
        breederNameAr: formState.breederNameAr,
        breederNameEn: formState.breederNameEn,
        isOwner: formState.ownedByMe,
        damNameEn: formState.damNameEn,
        damNameAr: formState.damNameAr,
        sireNameAr: formState.sireNameAr,
        sireNameEn: formState.sireNameEn,
        currentCountryId: formState.country,
        ownerNameAr: formState.ownerNameAr,
        ownerNameEn: formState.ownerNameEn,
        horseQualificationsList: noRequiredQualifications
          ? []
          : horseQualification,
        horseDocumentsList: formState.horseDocumentsList,
      };
      dispatch(FetchAddEditInternationalHorsethunk(params));
    } else {
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };

  useEffect(() => {
    if (toasterMessageShowEntry != null && toasterMessageShowEntry === "save") {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );

      dispatch(resetToasterMessageShowEntry());
    } else if (toasterMessageShowEntry == null && navigateStatus) {
      dispatch(resetNavigateStatus());

      setTimeout(() => {
        navigate(
          `/showentrycard?entryFees=${entryFees}&maxHorses=${maxHorses}&showNameAr=${showNameAr}&showNameEn=${showNameEn}&showId=${showId}&addRegisterHorseId=${addEditInternationalHorseData.id}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&registerStartDateInter=${registerStartDateInter}&registerEndDateInter=${registerEndDateInter}`
        );
      }, 1000);
    }
  }, [toasterMessageShowEntry]);

  function validUrl(urlVideo) {
    const validate = urlVideo.includes("http") || urlVideo.includes("https");
    return validate;
  }

  const dismissAll = () => toast.dismiss();

  const handelCheckHorseButton = (event) => {
    setCheckHorseButton(event.target.checked);
  };

  const handelCheckHorse = () => {
    setCheckHorse(true);
    closeModal();
  };

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          showEntryLoading ? "classdisplay" : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      <div className="row rtl">
        {
          // title
        }
        <div className="col-md-12 col-sm-12 row m0 rtl justify-content-center">
          <h1 className="fontWeight">
            {currentLanguage === "en" ? showNameEn : showNameAr}
          </h1>
          {
            // <h3 className="graycolor">
            //   {translate("web.horsesRemaining", "You have 2 horses remaining")}
            // </h3>
          }
          <h1 className="fontWeight">
            {translate("web.addInternationalHorse", "Add International Horse")}
          </h1>
          {
            //check existing horse
          }
          <h2 className="fontWeight colorred">
            {translate("web.checkExistingHorse", "Check Existing Horse")}
          </h2>
          <div className="col-md-12 col-sm-12 row rtl align-items-center padding-27 bordercategorycard align-self-center  mb-25">
            <div className="customFlexForDeskTop row rtl align-items-center ">
              <div className="col-md-3 col-sm-12 ">
                <NetaqInput
                  label={translate("web.horseNameEn", "Horse Name (En)")}
                  name={"horseNameEn"}
                  placeholder={translate("web.horseNameEn", "Horse Name (En)")}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formState.horseNameEn}
                  required={true}
                />
              </div>
              <div className="col-md-3 col-sm-12 ">
                <label>
                  {translate("web.origin", "Origin")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  //className="zindex999"
                  options={[
                    {
                      label: translate("web.chooseOrigin", "Choose Origin"),
                      value: null,
                    },
                    ...(!!countriesData &&
                      countriesData.map((a) => ({
                        label: a.text,
                        value: a.value,
                      }))),
                  ]}
                  value={formState.originDrop}
                  onChange={(e) => handleFormChange(e, "origin")}
                  placeholder={translate("web.chooseOrigin", "Choose Origin")}
                  name={"origin"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                />
              </div>
              <div className="col-md-3 col-sm-12 ">
                <NetaqInput
                  label={translate("web.ueln", "UELN")}
                  name={"ueln"}
                  placeholder={translate("web.ueln", "UELN")}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formState.ueln}
                />
              </div>
              <div className="col-md-3 col-sm-12 mt-4 ">
                {(formState.horseNameEn !== "" && formState.origin != null) ||
                formState.ueln !== "" ? (
                  <ActionButton
                    type={"submit"}
                    label={translate(
                      "web.checkExistingHorse",
                      "Check Existing Horse"
                    )}
                    text=<React.Fragment>
                      <i class="fa-solid fa-magnifying-glass-plus fa-lg marginrightleft-10"></i>
                      {translate(
                        "web.checkExistingHorse",
                        "Check Existing Horse"
                      )}
                    </React.Fragment>
                    margin={"auto"}
                    width={"100%"}
                    onClick={handelHorseSearch}
                    disabled={
                      (formState.horseNameEn !== "" &&
                        formState.origin != null) ||
                      formState.ueln !== ""
                        ? false
                        : true
                    }
                  />
                ) : (
                  <button
                    className="btn btn-danger width_100 m-23 pading-10-20"
                    disabled
                  >
                    <i class="fa-solid fa-magnifying-glass-plus fa-lg marginrightleft-10"></i>
                    {translate(
                      "web.checkExistingHorse",
                      "Check Existing Horse"
                    )}
                  </button>
                )}
              </div>
              <div className="col-md-12 col-sm-12 ">
                <span className="Gray_color">
                  {translate(
                    "web.checkSearchInterHorse",
                    "*You can search by (horse name english and origin) or (ueln)*"
                  )}
                </span>
              </div>
            </div>
            {
              //pop up
            }
            <Popup
              modal
              lockScroll
              open={openPopup}
              closeOnDocumentClick={false}
              closeOnEscape
              style={{ background: "red" }}
              contentStyle={{
                width: "100%",
                maxWidth: 1500,
                height: 500,
              }}
            >
              {(close) => (
                <React.Fragment>
                  <h1>{translate("web.resultSearch", "Result Search")}</h1>
                  <div className="divModalChampionchip overflowYSCroll">
                    <table className="table table-striped table-bordered rtl horseResultTable">
                      <thead className="theadHorseSelect">
                        <tr>
                          <th className="text-center">
                            {translate("web.name", "Name")}
                          </th>
                          <th className="text-center">
                            {translate("web.birthDate", "Birth Date")}
                          </th>
                          <th className="text-center">
                            {translate("web.registerNumber", "Register Number")}
                          </th>
                          <th className="text-center">
                            {translate("web.origin", "Origin")}
                          </th>
                          <th className="text-center">
                            {translate("web.color", "Color")}
                          </th>
                          <th className="text-center">
                            {translate("web.owner", "owner")}
                          </th>
                          <th className="text-center">
                            {translate("web.breeder", "Breeder")}
                          </th>
                          <th className="text-center">
                            {translate("web.gender", "Gender")}
                          </th>
                          <th className="text-center">
                            {translate("web.action", "Action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!searchInternationalHorsesData &&
                        !showEntryLoading &&
                        !!searchInternationalHorsesData.length > 0 ? (
                          searchInternationalHorsesData.map((item, i) => (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td>{item.birthDate}</td>
                              <td>{item.registerNumber}</td>
                              <td>{item.origin}</td>
                              <td>{item.color}</td>
                              <td>{item.owner}</td>
                              <td>{item.breeder}</td>
                              <td>{item.gender}</td>
                              <td>
                                <ActionButton
                                  type={"submit"}
                                  label={translate("web.select", "Select")}
                                  text={translate("web.select", "Select")}
                                  width={"100%"}
                                  onClick={() =>
                                    handelHorseSelect(item.id, true)
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}>
                              <h2 className="row text-danger text-center col-md-12 minHeight-174">
                                {translate(
                                  "web.nodataFound",
                                  "No Data Found !"
                                )}
                              </h2>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="divHorseSelect justRotate">
                    <div
                      className="bidSubmit divHorseSelectClose justRotate height-fitcontent"
                      onClick={closeModal}
                    >
                      {translate("web.close", "Close")}
                    </div>
                    <div className="justRotate rtl">
                      <div style={{ transform: "scale(1.1)" }}>
                        <NetaqInput
                          name={"checkHorse"}
                          type={"checkbox"}
                          label={translate(
                            "web.horseIsNotExist",
                            "Horse Is Not Exist In The Above List"
                          )}
                          onChange={handelCheckHorseButton}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-danger btn-lg"
                        onClick={handelCheckHorse}
                        disabled={checkHorseButton ? false : true}
                      >
                        <i className="fa fa-plus"></i>
                        {translate("web.addNewHorse", "Add New Horse")}
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Popup>
          </div>
          {
            //Horse Details
          }
          <h1 className="fontWeight colorred">
            {translate("web.horseDetails", "Horse Details")}
          </h1>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard  mb-25 row m0 rtl customFlexForDeskTop">
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.horseNameAr", "Horse Name (Ar)")}
                name={"horseNameAr"}
                placeholder={translate("web.horseNameAr", "Horse Name (Ar)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.horseNameAr}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.currentRegNo", "Current Reg No")}
                name={"regNo"}
                placeholder={translate("web.currentRegNo", "Current Reg No")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.regNo}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            {/* <div className="col-md-6 col-sm-12 p0 "> */}
              <div className="col-md-3 col-sm-12">
                <label>
                  {translate("web.color", "Color")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  isDisabled={checkHorse ? false : true}
                  options={[
                    {
                      label: translate("web.color", "Color"),
                      value: null,
                    },
                    ...(!!horseColorsData &&
                      horseColorsData.map((a) => ({
                        label: a.nameEn,
                        value: a.id,
                      }))),
                  ]}
                  value={formState.colorDrop}
                  onChange={(e) => handleFormChange(e, "color")}
                  placeholder={translate("web.color", "Color")}
                  name={"color"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <label>
                  {translate("web.gender", "Gender")}
                  <i className="colorred">*</i>
                </label>
                <Select
                  isDisabled={checkHorse ? false : true}
                  options={[
                    {
                      label: translate("web.gender", "Gender"),
                      value: null,
                    },
                    {
                      label: translate("web.mare", "Mare"),
                      value: 1,
                    },
                    {
                      label: translate("web.stallion", "Stallion"),
                      value: 2,
                    },
                    {
                      label: translate("web.gelding", "Gelding"),
                      value: 3,
                    },
                  ]}
                  value={formState.genderDrop}
                  onChange={(e) => handleFormChange(e, "gender")}
                  placeholder={translate("web.gender", "Gender")}
                  name={"gender"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                />
              </div>
            {/* </div> */}
            {/* <div className="col-md-6 col-sm-12 p0 "> */}
              <div className="col-md-3 col-sm-12">
                <NetaqInput
                  name={"birthDate"}
                  type={"date"}
                  label={translate("web.birthDate", "Birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDate")}
                  value={birthDate}
                  required={true}
                  disabled={checkHorse ? false : true}
                />
              </div>

              <div
                className="col-md-3 col-sm-12 pt-4"
                // style={{ transform: "scale(1.2)", marginLeft: 10 }}
              >
                <NetaqInput
                  name={"ownedByMe"}
                  type={"checkbox"}
                  label={translate("web.ownedByMe", "Owned By Me")}
                  defaultChecked={formState.ownedByMe}
                  checked={formState.ownedByMe}
                  onChange={handleFormChange}
                  disabled={checkHorse ? false : true}
                />
              </div>
            {/* </div> */}

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.ownerNameEn", "Owner Name in (En)")}
                name={"ownerNameEn"}
                placeholder={translate("web.ownerNameEn", "Owner Name in (En)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.ownerNameEn}
                disabled={formState.ownedByMe}
                required={true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.ownerNameAr", "Owner Name in (Ar)")}
                name={"ownerNameAr"}
                placeholder={translate("web.ownerNameAr", "Owner Name in (Ar)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.ownerNameAr}
                disabled={formState.ownedByMe === true}
                required={true}
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.breederNameEn", "Breeder Name (En)")}
                name={"breederNameEn"}
                placeholder={translate(
                  "web.breederNameEn",
                  "Breeder Name (En)"
                )}
                type={"text"}
                onChange={handleFormChange}
                setFormState
                value={formState.breederNameEn}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.breederNameAr", "Breeder Name (Ar)")}
                name={"breederNameAr"}
                placeholder={translate(
                  "web.breederNameAr",
                  "Breeder Name (Ar)"
                )}
                type={"text"}
                onChange={handleFormChange}
                setFormState
                value={formState.breederNameAr}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.sireNameEn", "Sire Name (En)")}
                name={"sireNameEn"}
                placeholder={translate("web.sireNameEn", "Sire Name (En)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.sireNameEn}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.sireNameAr", "Sire Name (Ar)")}
                name={"sireNameAr"}
                placeholder={translate("web.sireNameAr", "Sire Name (Ar)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.sireNameAr}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.damNameEn", "Dam Name (En)")}
                name={"damNameEn"}
                placeholder={translate("web.damNameEn", "Dam Name (En)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.damNameEn}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.damNameAr", "Dam Name (Ar)")}
                name={"damNameAr"}
                placeholder={translate("web.damNameAr", "Dam Name (Ar)")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.damNameAr}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <label>
                {translate("web.horseCountry", "Horse Country")}
                <i className="colorred">*</i>
              </label>
              <Select
                isDisabled={checkHorse ? false : true}
                options={[
                  {
                    label: translate("web.horseCountry", "Horse Country"),
                    value: null,
                  },
                  ...(!!countriesData &&
                    countriesData.map((a) => ({
                      label: a.text,
                      value: a.value,
                    }))),
                ]}
                value={formState.countryDrop}
                onChange={(e) => handleFormChange(e, "country")}
                placeholder={translate("web.horseCountry", "Horse Country")}
                name={"country"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.horseAddress", "Horse Address")}
                name={"addressDetails"}
                placeholder={translate("web.horseAddress", "Horse Address")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.addressDetails}
                required={true}
                disabled={checkHorse ? false : true}
              />
            </div>
          </div>
          {
            //Horse qualifications
          }
          <h1 className="fontWeight colorred mt-3">
            {translate("web.horsequalifications", "Horse Qualifications")}
          </h1>
          <div className="col-md-12 col-sm-12 padding-27  bordercategorycard customFlexForDeskTop mt-2 row m0 rtl">
            {isLessThanOneYearOld(new Date(birthDate)) && (
              <div
                className="col-md-12 col-sm-12"
                //style={{ transform: "scale(1.2)" }}
              >
                <NetaqInput
                  name={"noRequiredQualifications"}
                  type={"checkbox"}
                  label={translate(
                    "web.noRequiredQualifications",
                    "There is no required qualifications"
                  )}
                  defaultChecked={noRequiredQualifications}
                  checked={noRequiredQualifications}
                  onChange={(e) =>
                    setNoRequiredQualifications(e.target.checked)
                  }
                  disabled={checkHorse ? false : true}
                />
              </div>
            )}
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.countryName", "Country Name")}
                <i className="colorred">*</i>
              </label>
              <Select
                isDisabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
                options={[
                  {
                    label: translate("web.countryName", "Country Name"),
                    value: null,
                  },
                  ...(!!countriesData &&
                    countriesData.map((a) => ({
                      label: a.text,
                      value: a.value,
                    }))),
                ]}
                value={formState.countryQualDrop}
                onChange={(e) => handleFormChange(e, "countryQual")}
                placeholder={translate("web.countryName", "Country Name")}
                name={"countryQual"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
              />
            </div>
            <div className="col-md-3 col-sm-12 ">
              <NetaqInput
                disabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
                label={translate("web.showName", "Show Name")}
                name={"showName"}
                placeholder={translate("web.showName", "Show Name")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.showName}
                required={true}
              />
            </div>
            <div className="col-md-3 col-sm-12 ">
              {
                // <label className="capatalize_text">
                //     {translate("web.year", "Year")}
                //     <i className="colorred">*</i>
                //   </label>
                //   <input
                //     className="form-control"
                //     name="year"
                //     type="number"
                //     min="1900"
                //     max="3000"
                //     step="1"
                //     onChange={handleHorseQualificationsCardChange}
                //     value={horseQualificationsCard.year}
                //     disabled={checkHorse ? false : true}
                //   />
              }

              <NetaqInput
                name={"year"}
                type={"date"}
                label={translate("web.year", "Year")}
                onChange={(e) => handleFormChange(e, "year")}
                value={yearDate}
                required={true}
                disabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.className", "Class Name")}
                name={"className"}
                placeholder={translate("web.className", "Class Name")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.className}
                required={true}
                disabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <label>
                {translate("web.classRank", "Class Rank")}
                <i className="colorred">*</i>
              </label>
              <input
                className="form-control"
                name="classRank"
                type="number"
                min="1"
                step="1"
                onChange={handleFormChange}
                value={formState.classRank}
                disabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <NetaqInput
                label={translate(
                  "web.referenceWebsiteURL",
                  "Reference Website URL"
                )}
                name={"websiteURL"}
                placeholder={translate("web.websiteURL", "Website URL")}
                type={"text"}
                onChange={handleFormChange}
                value={formState.websiteURL}
                disabled={
                  checkHorse && !noRequiredQualifications ? false : true
                }
              />
              <span className={`text-danger ${websiteURLNotValidMsg}`}>
                {translate("web.urlNotValid", "URL not valid")}
              </span>
            </div>
            {
              // <div className="col-md-12 col-sm-12 mb-25 ">
              //   <button
              //     type="button"
              //     className="btn btn-danger btn-lg"
              //     onClick={() => {
              //       setHorseQualificationsCards((prevState) => [
              //         ...prevState,
              //         horseQualificationsCard,
              //       ]);
              //       setFormState({
              //         ...formState,
              //         horseQualificationsList: [
              //           ...formState.horseQualificationsList,
              //           {
              //             id: currentVal.current,
              //             countryId: horseQualificationsCard.countryDrop.value,
              //             showName: horseQualificationsCard.showName,
              //             year: horseQualificationsCard.year,
              //             classRank: horseQualificationsCard.classRank,
              //             //classRank: horseQualificationsCard.classRankDrop.value,
              //             website: horseQualificationsCard.websiteURL,
              //             className: horseQualificationsCard.className,
              //           },
              //         ],
              //       });
              //       currentVal.current = currentVal.current - 1;
              //       setHorseQualificationsCard(horseQualificationsCardReset());
              //     }}
              //     disabled={
              //       horseQualificationsCard.countryDrop.value != null &&
              //       horseQualificationsCard.showName !== "" &&
              //       horseQualificationsCard.year !== "" &&
              //       horseQualificationsCard.className !== "" &&
              //       horseQualificationsCard.classRank !== "" &&
              //       //horseQualificationsCard.classRankDrop.value != null &&
              //       ((horseQualificationsCard.websiteURL !== "" &&
              //         validUrl(horseQualificationsCard.websiteURL) === true) ||
              //         horseQualificationsCard.websiteURL === "") &&
              //       horseQualificationsCards.length === 0
              //         ? false
              //         : true
              //     }
              //   >
              //     <i className="fa fa-plus"></i>
              //     <span className="ml-10">{translate("web.add", "Add")}</span>
              //   </button>
              // </div>
              // horseQualificationsCards.length > 0 &&
              // horseQualificationsCards.map((item, i) => (
              //   <div
              //     //className="col-lg-6 col-md-6 col-sm-6 floatRight show-entry-first-div horseCardContainer width50"
              //     className="col-lg-6 col-md-6 col-sm-6 floatRight show-entry-first-div horseCardContainer"
              //     key={i}
              //   >
              //     {
              //       //<div className="horseCardContainer">
              //     }
              //     <div className="col-md-12 titletrashshowentrycard">
              //       <span className="horseCardLabeltitle rotateArabic"></span>
              //       <i
              //         className="fa fa-trash plusIcon cursorPointer mt-15"
              //         onClick={() => {
              //           setHorseQualificationsCards((oldValues) => {
              //             return oldValues.filter(
              //               (oldItem) => oldItem.id !== item.id
              //             );
              //           });
              //           setFormState({
              //             ...formState,
              //             horseQualificationsList:
              //               formState.horseQualificationsList.filter(
              //                 (oldValue) => oldValue.id !== item.id
              //               ),
              //           });
              //         }}
              //       ></i>
              //     </div>
              //     <div className="col-md-12  mb-5">
              //       {
              //         //first row
              //       }
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.countryName", "Country Name")}
              //         </span>
              //       </div>
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.showTitle", "Show Title")}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {item.countryDrop.label}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {item.showName}
              //         </span>
              //       </div>
              //       {
              //         //second row
              //       }
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.year", "Year")}
              //         </span>
              //       </div>
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.className", "Class Name")}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {item.year}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {item.className}
              //         </span>
              //       </div>
              //       {
              //         //third row
              //       }
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.classRank", "Class Rank")}
              //         </span>
              //       </div>
              //       <div className="col-md-6">
              //         <span className="horseCardlabelContent rotateArabic">
              //           {translate("web.website", "Website")}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {
              //             //item.classRankDrop.label
              //           }
              //           {item.classRank}
              //         </span>
              //       </div>
              //       <div className="col-md-6 mb-25">
              //         <span className="horseCardlabelContent rotateArabic fontWeight">
              //           {item.websiteURL}
              //         </span>
              //       </div>
              //     </div>
              //     {
              //       //</div>
              //     }
              //   </div>
              // ))
            }
          </div>
          {
            //Horse Documents
          }
          <h1 className="fontWeight colorred mt-3">
            {translate("web.uploadHorseDocuments", "Upload Horse Documents")}
          </h1>
          <div className="col-md-12 col-sm-12 padding-27 justRotate bordercategorycard mt-2 row m0 rtl">
            {
              // upload buttons
            }
            <div className="col-md-4 col-sm-12 justRotate mb-25">
              <h3>
                {translate("web.passport", "Passport")}
                <i className="colorred">*</i>
              </h3>
              <span className="font-15 Gray_color">
                {translate(
                  "web.uploadHorsePassportType",
                  "Upload horse passport pages which contain all the horse details in one or multi files (pdf, jpg, or jpeg)"
                )}
              </span>
              <UploadFile
                text={"Upload"}
                className="maxWidth150 mt-10"
                ismultiFile={true}
                disabled={checkHorse ? false : true}
                onChange={handleUploadPassportChange}
                iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                accept={"image/*,application/pdf"}
              />
              <br />
              <div className="col-md-12 col-sm-12 mb-25 ">
                {uploadPassport.length > 0 &&
                  uploadPassport.map((item, i) => (
                    <Link
                      key={i}
                      to={item.file}
                      className="C89C66 flex-center cursorPointer mb-25"
                      target="_blank"
                    >
                      <i className="fa fa-file fa-3x"></i>
                      <span className="black-color fontsize18 marginrightleft-10">
                        {item.name}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
            <div className="col-md-4 col-sm-12 justRotate mb-25">
              <h3>
                {translate("web.ownershipProof", "Ownership Proof")}
                <i className="colorred">*</i>
              </h3>
              <span className="font-15 Gray_color">
                {translate(
                  "web.uploadHorseOwnershipType",
                  "Upload the horse ownership or authority proof documents in one or multi files (pdf, jpg, or jpeg)"
                )}
              </span>
              <UploadFile
                text={"Upload"}
                className="maxWidth150 mt-10"
                ismultiFile={true}
                disabled={checkHorse ? false : true}
                onChange={handleUploadOwnershipProofChange}
                iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                accept={"image/*,application/pdf"}
              />
              <br />
              <div className="col-md-12 col-sm-12 mb-25 ">
                {uploadOwnershipProof.length > 0 &&
                  uploadOwnershipProof.map((item, i) => (
                    <Link
                      key={i}
                      to={item.file}
                      className="C89C66 flex-center cursorPointer mb-25"
                      target="_blank"
                    >
                      <i className="fa fa-file fa-3x"></i>
                      <span className="black-color fontsize18 marginrightleft-10">
                        {item.name}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
            <div className="col-md-4 col-sm-12 justRotate mb-25">
              <h3>
                {translate(
                  "web.qualificationCertification",
                  "Qualification Certificate"
                )}
              </h3>
              <span className="font-15 Gray_color">
                {translate(
                  "web.uploadHorseQualificationsCertificatesType",
                  "Upload the horse qualifications certificates if-any in one or multi files (pdf, jpg, or jpeg)"
                )}
              </span>
              <UploadFile
                text={"Upload"}
                className="maxWidth150 mt-10"
                ismultiFile={true}
                disabled={checkHorse ? false : true}
                onChange={handleUploadCertificationForQualificationChange}
                iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                accept={"image/*,application/pdf"}
              />
              <br />
              <div className="col-md-12 col-sm-12 mb-25 ">
                {uploadCertificationForQualification.length > 0 &&
                  uploadCertificationForQualification.map((item, i) => (
                    <Link
                      key={i}
                      to={item.file}
                      className="C89C66 flex-center cursorPointer mb-25"
                      target="_blank"
                    >
                      <i className="fa fa-file fa-3x"></i>
                      <span className="black-color fontsize18 marginrightleft-10">
                        {item.name}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
            {
              //upload imgs
            }
          </div>
          {
            //complete buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 textalignend">
            {checkHorse ? (
              <ActionButton
                type={"submit"}
                label={translate("web.submit", "Submit")}
                text={
                  <React.Fragment>
                    <i className="fa fa-save fa-lg marginrightleft-10"></i>
                    {translate("web.submit", "Submit")}
                  </React.Fragment>
                }
                margin={"23px"}
                width={"200px"}
                onClick={handelFormSubmit}
                disabled={checkHorse ? false : true}
              />
            ) : (
              <button
                className="btn btn-danger pading-10-20 m-23 width_200"
                disabled
              >
                <i className="fa fa-save fa-lg marginrightleft-10"></i>
                {translate("web.submit", "Submit")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
