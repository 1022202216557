import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import ActionButton from "../components/ActionButton";
import { useLocation } from "react-router";

const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
const ReactBaseURL = process.env.REACT_APP_BASE_URL;
function formatNumber(number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return number.toString();
  }
}

function ServiceFlow({ flow }) {
  return (
    <div className="serviceFlowContainer">
      <div className="serviceFlowChartContainer">
        {flow.map((node, index) => (
          <div className="serviceFlowNode">
            <div className="serviceNodeCircleContainer">
              <div className="serviceNodeCircle">{formatNumber(index + 1)}</div>
              {index + 1 < flow.length && (
                <div className="ServiceNodeSpacer"></div>
              )}
            </div>
            <div className="ServiceNodeName">{flow[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function SingleServicePage() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [service, setService] = useState([]);
  const { translate } = useTranslate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.has("id") ? searchParams.get("id") : "";
  const isLoggedIn = localStorage.getItem("accessTokenforEAHS") ?? false;
  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE"; // Defaulting to English for demonstration
  }

  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );
      // console.log("Umbraco umbraco is", umbraco.data);
      const formattedData = umbraco.data?.map((item) => ({
        serviceShortName: item?.name ?? "",
        serviceName: item?.properties?.serviceName ?? "",
        category: item?.properties?.category ?? [],
        estimateTime: item?.properties?.estimateTime ?? "",
        feesAmount: item?.properties?.feesAmount ?? 0,
        feesDescription: item?.properties?.feesDescription ?? "",
        requiredDocuments: item?.properties?.requiredDocuments?.markup ?? "",
        serviceForm: item?.properties?.serviceForm ?? "",
        serviceImage: item.properties?.serviceImage
          ? item.properties?.serviceImage[0]?.url
          : "",
        subTitle: item.properties?.subTitle ?? "",
        termsAndConditions: item?.properties?.termsAndConditions?.markup ?? "",
        serviceFlow: item?.properties?.serviceFlow ?? [],
        serviceLink: item?.properties?.serviceLink ?? null,
      }));
      setService(formattedData);
    }
    getdata();
  }, [currentLanguage]);

  return (
    <>
      {service.length === 0 && <Loader />}
      {service.length > 0 && (
        <div className="container currentFont">
          {service.map((service) => (
            <>
              <h2 className="bold rtl">{service.serviceShortName}</h2>
              { (
                <>
                  <div className="row rtl">
                    <div className="col-lg-3 col-md-3 col-sm-3 serviceLabelKey rtl">
                      {translate("web.serviceName", "Service Name")}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 serviceLabelVal rtl">
                      {service.serviceName}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 serviceLabelKey rtl">
                      {translate("web.requiredDocuments", "Required Documents")}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service.requiredDocuments,
                      }}
                      className="col-lg-8 col-md-8 col-sm-8 serviceLabelVal rtl"
                    />
                    {service.serviceFlow.length > 0 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-sm-3 serviceLabelKey rtl">
                          {translate("web.serviceFlow", "Service Flow")}
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 serviceLabelVal rtl">
                          <ServiceFlow flow={service.serviceFlow} />
                        </div>
                      </>
                    )}
                    <div className="col-lg-3 col-md-3 col-sm-3 serviceLabelKey rtl">
                      {translate(
                        "web.termsAndConditions",
                        "Terms and Conditions"
                      )}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service.termsAndConditions,
                      }}
                      className="col-lg-8 col-md-8 col-sm-8 serviceLabelVal rtl"
                    />
                    <div className="col-lg-3 col-md-3 col-sm-3 serviceLabelKey rtl">
                      {translate("web.fees", "Fees")}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 serviceLabelVal rtl">
                      {service.feesAmount == 0 ? (
                        translate("web.freeService", "Free Service")
                      ) : (
                        <>
                          {service.feesAmount +
                            " " +
                            translate("web.aed", "AED")}
                          <div
                            style={{
                              display: "inline-block",
                              margin: "auto 5px",
                              fontSize: 10,
                              fontWeight: "bold",
                            }}
                          >
                            {translate("web.includingVAT", "Including VAT")}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            
              {service.serviceLink != null ? (
                <div className="ApplyButnContainerServicePage rtl">
                  <ActionButton
                    type={"openLink"}
                    target="_self"
                    to={isLoggedIn ? `${ReactBaseURL}${service.serviceLink}` :'/login'}
                    text={translate("web.serviceApply", "Apply")}
                  /> 
                </div>
              ) : (
                <div className="ApplyButnContainerServicePage rtl">
                  <ActionButton
                    type={"openLink"}
                    to={`${MediaBaseURL}${service.serviceForm}`}
                    text={translate("web.downloadForm", "Download Form")}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
}
