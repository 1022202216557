
/**
 * Can be refactored as 
 * @deprecated return Array.from({ length: 7 }, (_, i) => new Date(year - (month < 8 ? 1 : 0), i + 8, 1));
 * By Walid
 * @param {number} year The season start year
 * @returns Array of season months as date object
 */
export function CalenderSeasonsCalculator(year = new Date().getFullYear()) {
    const now = new Date();
    const month = now.getMonth();

    let dates = [];

    if (month < 8) { // Between January (0) and August (7)
        dates = [
            new Date(Number(year) , 8, 1), // 1st September last year
            new Date(Number(year) , 9, 1), // 1st October last Number(year)
            new Date(Number(year) , 10, 1), // 1st November last Number(year)
            new Date(Number(year) , 11, 1), // 1st December last Number(year)
            new Date(Number(year) + 1, 0, 1), // 1st January next Number(year)
            new Date(Number(year) + 1, 1, 1), // 1st February next Number(year)
            new Date(Number(year) + 1, 2, 1),  // 1st March next year
            new Date(Number(year) + 1, 3, 1)  // 1st March next year
        ];
    } else { // Between September (8) and December (11)
        dates = [
            new Date(Number(year), 8, 1), // 1st September this year
            new Date(Number(year), 9, 1), // 1st October this year
            new Date(Number(year), 10, 1), // 1st November this year
            new Date(Number(year), 11, 1), // 1st December this year
            new Date(Number(year) + 1, 0, 1), // 1st January next year
            new Date(Number(year) + 1, 1, 1), // 1st February next year
            new Date(Number(year) + 1, 2, 1),  // 1st March next year
            new Date(Number(year) + 1, 3, 1),  // 1st March next year
        ];
    }

    return dates;
}

