import React, { useEffect, useState } from "react";
import SiteMap from "../components/SiteMap";
import { ColorScheme } from "../components/SiteMap";
import { useLocation } from "react-router-dom";

import { AuctionHeader } from "../components/auctions/header";
import AuctionDetails from "../components/auctions/listing/AuctionDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAuctionData,
  resetAuctionData,
} from "../Redux/AuctionData/AuctionsDataSlice";

import { getTimeStatus } from "../helper/timeUtils";
import { useTranslate } from "../components/TranslationText";

export default function AuctionDetailsPage({ data }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const auctionData = useSelector((state) => state.auction.auctionData);
  const auctionHorses = useSelector((state) => state.auction.auctionHorses);
  const auctionAttachment = useSelector(
    (state) => state.auction.auctionAttachment
  );
  const { translate } = useTranslate();

  // get auction data
  const currentAuctionId = searchParams.has("id")
    ? searchParams.get("id")
    : null;

  useEffect(() => {
    dispatch(resetAuctionData());
  }, []);
  useEffect(() => {
    dispatch(fetchAuctionData(currentAuctionId));
  }, [dispatch, currentAuctionId]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [bidderId, setBidderId] = useState("");
  const [bidderNumber, setBidderNumber] = useState(0);
  async function getPaymentStatus(
    paymentStatus = "",
    bidderId = "",
    bidderNumber = ""
  ) {
    setPaymentStatus(paymentStatus);
    setBidderId(bidderId);
    setBidderNumber(bidderNumber);
    if (!!auctionData) {
      localStorage.setItem(`${auctionData.auuctionId}`, paymentStatus);
    }
    return paymentStatus;
  }

  return (
    <>
      {!!auctionData && (
        <SiteMap
          siteTree={[
            `${translate(
              `web.${getTimeStatus(
                auctionData.auctionStartDate,
                auctionData.auctionEndDate
              ).toLowerCase()}Auction`,
              `${getTimeStatus(
                auctionData.auctionStartDate,
                auctionData.auctionEndDate
              ).toLowerCase()}Auction`
            )}`,
            auctionData.auctionName,
          ]}
          bgColor={ColorScheme.PrimaryRed}
          key={"sitemap"}
        />
      )}
      {!!auctionData && (
        <div className="container-fluid p0 AuctionHeaderContainer">
          <AuctionHeader
            slider={auctionAttachment}
            details={auctionData}
            getPaymentStatus={getPaymentStatus}
          />
          <br />
          <br />
        </div>
      )}
      {!!auctionData && (
        <AuctionDetails
          auctionData={auctionData}
          auctionHorses={auctionHorses}
          paymentStatus={paymentStatus}
          bidderId={bidderId}
          bidderNumber={bidderNumber}
        />
      )}
    </>
  );
}
