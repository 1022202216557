import axios from "axios";

const baseURL = process.env.REACT_APP_UMBRACO;
//const baseURL = process.env.REACT_APP_Local_UMBRACO;
const APIKEY = process.env.REACT_APP_UMBRACO_API_KEY ?? '';

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json", // Set default content type (optional)
    "cache-control": "no-cahce",
    "Api-Key":APIKEY,
    "Referer":window.location.href,
    "Accept":'application/json, text/plain, */*',
  },
});

// Function to inject access token and language into headers before each request
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken(); // Replace with your logic to retrieve access token
    const language = getCurrentLanguage(); // Replace with your logic to retrieve current language
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
   
    if (language) {
      config.params = {
        ...config.params,
        language: language,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

function getAccessToken() {
  const token = localStorage.getItem("accessTokenforEAHS");
  return token;
}

function getCurrentLanguage() {
  // Replace this with your logic to retrieve the current language
  // For example, you can retrieve it from localStorage or from a state variable
  const lang = localStorage.getItem("eahsLang") ?? "en";
  if (lang == "en") return 0;
  else return 1; // Defaulting to English for demonstration
}

export default axiosInstance;
