import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";
import { GuidGenerator } from "../helper/GuidGenerator";
import dayjs from "dayjs";

const ShowCategoryDynamicTable = ({rows,setRows,champoinsRows ,genderList}) => {
    const {translate} = useTranslate();

    console.debug('rows are' , champoinsRows)

    const champoinNameList = champoinsRows.filter((champ) => !!champ.col1).map((row)=>({
        name:row.col1,
        value:row.customGuid
    }))

    // Function to handle input change
    const handleInputChange = (index, e, isDropDown = false ,inputName , isDate=false) => {
        var name;
        var value;
        var label;
        if (isDropDown) {
            name = e.label;
            label = e.label;
            value = e.value;

        }
        else {
            if(isDate){
                name = inputName;
                label = inputName;
                value = e.toDate();    
                debugger;
            }else{
                name = e.target.name;
                label = e.target.name;
                value = e.target.value;
            }
        }
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    // Function to add a new row
    const addRow = (e) => {
        console.debug(e)
        setRows([...rows, { col1: "", col2: "", col3: "", col4: "",col5:"",col6:"",col8:"",customGuid:GuidGenerator() }]);
    };

    // Function to delete a row
    const deleteRow = (index) => {
        const updatedRows = rows.filter((row, i) => i !== index);
        setRows(updatedRows);
    };

    return (
        <div style={{borderRadius:5, border:'1px solid red' , padding:10}}>
            <table border="1" style={{ width: "100%", textAlign: "center", border: 0, borderCollapse: "separate", borderSpacing: "0 10px", }} >
                <thead>
                    <tr>
                        <th>{translate('web.categoryName','Category Name')}</th>
                        <th>{translate('web.categoryNameArabic','Category Name Arabic')}</th>
                        <th>{translate('web.maxHorsesPerClass','Max Horses Per Class')}</th>
                        <th>{translate('web.maxHorsesCount','Max Horses Count')}</th>
                        <th>{translate('web.birthDateFrom','Birthdate From ')}</th>
                        <th>{translate('web.birthDateTo','Birthdate To ')}</th>
                        <th>{translate('web.championship','Championship ')}</th>
                        <th>{translate('web.action','Action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td className="px-1">
                                <NetaqInput
                                    type="text"
                                    hideLable={true}
                                    name="col1"
                                    value={row.col1}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.categoryName','Category Name')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="text"
                                    name="col2"
                                    value={row.col2}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.categoryNameArabic','Category Name Arabic')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="number"
                                    name="col3"
                                    value={20}
                                    disabled={true}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.maxHorsesPerClass','Max Horses Per Class')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="number"
                                    name="col4"
                                    value={row.col4}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.maxHorsesCount','Max Horses Count')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="date"
                                    name="col5"
                                    value={!!row.col5 ? dayjs(row.col5):null }
                                    onChange={(e) => {
                                        handleInputChange(index, e , false,'col5' , true)
                                    }}
                                    placeholder={translate('web.birthdateFrom','Birthdate From ')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="date"
                                    name="col6"
                                    value={!!row.col6 ? dayjs(row.col6):null}
                                    
                                    // onChange={(e) => handleInputChange(index, e)}
                                    onChange={(e) => {
                                        handleInputChange(index, e , false,'col6' , true)
                                    }}
                                    placeholder={translate('web.birthdateTo','Birthdate To ')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput type={'selectislam'}
                                    Options={champoinNameList}
                                    label={translate('web.championship','Championship ')}
                                    hideLable={true} required={true} name="col8"
                                    value={row.col8}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.championship','Championship ')} />
                            </td>
                            <td>
                                <ActionButton onClick={() => deleteRow(index)} className="mx-auto" text={'<i class="fa-solid fa-trash"></i>'} type={'normalbtn'} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ActionButton onClick={(e) => addRow(e)} className="mt-3" text={translate('web.addItem','Add Item')} type={'normalbtn'} />
        </div>
    );
};

export default ShowCategoryDynamicTable;
