import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState("");
  
const accesstoken = localStorage.getItem('accessTokenforEAHS')??"";
  useEffect(() => {
    window.scrollTo(0, 0);
    if(pathname == '/add-organizer-request' ){
      const jsonString = localStorage.getItem("eahsUserProfile");
      if (jsonString) {
        const data = JSON.parse(jsonString);
        setUserProfile(data);
        if(data.accountType != 'Organizer'){
          navigate('/404-not-found');
        }
      }else{
        navigate('/404-not-found');
      }
    }
  }, [pathname]);

  return null;
}
