import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router";
import {
  FetchShowEntryClassesthunk,
  FetchShowEntryFilesthunk,
  FetchShowEntrybyIdthunk,
  FetchShowSchedulethunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { FetchShowGallerythunk } from "../Redux/Shows/ShowsAction";
import { CompareDate } from "../helper/CompareDate";
import { Link } from "react-router-dom";
import { handelActiveTabOwnerProfile } from "../Redux/ShowEntry/ShowEntrySlice";
import { differenceDaysBetweenTwoDates } from "../helper/differenceDaysBetweenTwoDates";
import { formatDate } from "../helper/formatDate";
import { generateIframeFromUrl } from "../helper/generateIframeFromUrl";
import Popup from "reactjs-popup";
import ActionButton from "../components/ActionButton";

export default function ShowEntryDetails() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();
  const [userProfile, setUserProfile] = useState("null");
  const loadDataFromLocal = () => {
    const jsonString = localStorage.getItem("eahsUserProfile");
    if (jsonString) {
      const data = JSON.parse(jsonString);
      setUserProfile(data);
    }
  };
  const {
    showEntryLoading,
    showEntrybyIdData,
    showEntryFilesData,
    showEntryClassesData,
    showScheduleData,
  } = useSelector((state) => state.showEntrySlice);
  // console.log("showEntrybyIdData", showEntrybyIdData);


  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentId = searchParams.has("showId")
    ? searchParams.get("showId")
    : null;



  useEffect(() => {
    async function getShowEntrybyId() {
      let paramsShowEntrybyId = {
        id: currentId,
        langauge: currentLanguage,
      };
      dispatch(FetchShowEntrybyIdthunk(paramsShowEntrybyId));
      dispatch(FetchShowGallerythunk(paramsShowEntrybyId));
      dispatch(FetchShowEntryFilesthunk(paramsShowEntrybyId));
      dispatch(FetchShowEntryClassesthunk(paramsShowEntrybyId));
      dispatch(FetchShowSchedulethunk(paramsShowEntrybyId));
      dispatch(handelActiveTabOwnerProfile("showEntry"));
    }

    getShowEntrybyId();
    loadDataFromLocal();
  }, [dispatch, currentId, currentLanguage]);


  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${showEntryLoading ? "classdisplay" : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      <div className="row rtl">
        {
          // card
        }
        <div className="col-lg-12 col-md-12 col-sm-12   show-entry-first-div wd-100 mb-90">
          <div className="horseCardContainer row rtl" style={{ display: "flex" }}>
            <div className="col-lg-2 col-md-2 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer ">
              <img src={showEntrybyIdData.logo} alt="logo" />
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 ">
              <span className="horseMarketCardLabeltitle">
                {showEntrybyIdData.showName}
              </span>

              <div className="row rtl mt-11wahoconference">
                <div className="col-md-12 ">
                  <span className="horseCardlabelContent ">
                    {showEntrybyIdData.cityName}
                  </span>
                  <span className="horseCardlabelContent ">
                    <i className="fa fa-trophy " style={{ marginRight: 8 }}></i>
                    {showEntrybyIdData.showRank}
                  </span>

                  <div className="col-md-12 titletrashshowentrycard flex_wrap ">
                    {
                      //Show Date
                    }
                    <div className="mb-5 p0 maxwidth350">
                      <span className="horseCardlabelContent  fontWeight">
                        {translate("web.showDate", "Show Date")}
                      </span>
                      <div className=" start-date-showlist mt-11wahoconference">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate("web.startDate", "Start Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.startDate &&
                              formatDate(showEntrybyIdData.startDate)}
                          </div>
                        </div>
                      </div>

                      <div className="end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.endDate &&
                              formatDate(showEntrybyIdData.endDate)}
                          </div>
                        </div>
                      </div>
                      <span className="horseCardlabelContent   colorred">
                        {showEntrybyIdData.startDate &&
                          differenceDaysBetweenTwoDates(
                            new Date(),
                            new Date(showEntrybyIdData.startDate)
                          )}{" "}
                        {translate(
                          "web.daysLeftShow",
                          "days Left for the show"
                        )}
                      </span>
                    </div>
                    {
                      //Registration Date
                    }
                    <div className="mb-5 p0 maxwidth350">
                      <span className="horseCardlabelContent  fontWeight">
                        {translate("web.registrationDate", "Registration Date")}
                      </span>
                      <div className=" start-date-showlist mt-11wahoconference">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate("web.startDate", "Start Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerStartDate &&
                              formatDate(showEntrybyIdData.registerStartDate)}
                          </div>
                        </div>
                      </div>

                      <div className=" end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerEndDate &&
                              formatDate(showEntrybyIdData.registerEndDate)}
                          </div>
                        </div>
                      </div>
                      {
                        // <span className="horseCardlabelContent   colorred">
                        //   {translate(
                        //     "web.daysLeftRegistration",
                        //     "2 days Left for the registration"
                        //   )}
                        // </span>
                      }
                    </div>

                    {
                      //Register International Date
                    }
                    <div className="mb-5 p0 maxwidth350">
                      <span className="horseCardlabelContent  fontWeight">
                        {translate(
                          "web.registerInternationalDate",
                          "Register International Horse Date"
                        )}
                      </span>
                      <div className=" start-date-showlist mt-11wahoconference">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate("web.startDate", "Start Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerStartDateInter &&
                              formatDate(
                                showEntrybyIdData.registerStartDateInter
                              )}
                          </div>
                        </div>
                      </div>

                      <div className=" end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerEndDateInter &&
                              formatDate(
                                showEntrybyIdData.registerEndDateInter
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="textalignend">
                {userProfile.accountType !== 'Organizer' && <Link
                  className={`btn button-saveasdraft ${
                    // CompareDate(
                    //   showEntrybyIdData.startDate,
                    //   showEntrybyIdData.endDate
                    // )
                    CompareDate(
                      showEntrybyIdData.registerStartDate,
                      showEntrybyIdData.endDate
                    )
                      ? "newbtn"
                      : "btn-danger"
                    } wd-20`}
                  disabled={
                    CompareDate(
                      showEntrybyIdData.registerStartDate,
                      showEntrybyIdData.endDate
                    )
                      ? false
                      : true
                  }
                  style={CompareDate(
                    showEntrybyIdData.registerStartDate,
                    showEntrybyIdData.endDate
                  )
                    ? { opacity: 1, cursor: 'pointer' }
                    : { opacity: 0.5, cursor: "not-allowed" }}
                  onClick={(event) => {
                    if (
                      !CompareDate(
                        showEntrybyIdData.registerStartDate,
                        showEntrybyIdData.endDate
                      )
                    ) {
                      event.preventDefault();
                    }
                  }}
                  to={
                    showEntrybyIdData.showParticipantRequestStatus === "New" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Draft" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Pending" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Returned"
                      ? `/showentrycard?entryFees=${showEntrybyIdData.entryFees
                      }&maxHorses=${parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                      parseInt(showEntrybyIdData.completedRequestsCount)
                      }&showNameAr=${showEntrybyIdData.showNameAr
                      }&showNameEn=${showEntrybyIdData.showNameEn
                      }&showId=${currentId}&registerStartDate=${showEntrybyIdData.registerStartDate
                      }&registerEndDate=${showEntrybyIdData.registerEndDate
                      }&registerStartDateInter=${showEntrybyIdData.registerStartDateInter
                      }&registerEndDateInter=${showEntrybyIdData.registerEndDateInter
                      }`
                      : `/showentryconflict?maxHorses=${parseInt(showEntrybyIdData.maxHorseNumberPerOwner)
                      // parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                      // parseInt(showEntrybyIdData.completedRequestsCount)
                      }&showNameAr=${showEntrybyIdData.showNameAr
                      }&showNameEn=${showEntrybyIdData.showNameEn
                      }&showId=${currentId}`
                  }
                >
                  {showEntrybyIdData.showParticipantRequestStatus === "New"
                    ? translate("web.participateNow", "Participate Now")
                    : showEntrybyIdData.showParticipantRequestStatus ===
                      "Draft" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Pending" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Returned"
                      ? translate("web.continue", "Continue")
                      : translate("web.addConflict", "Add Conflict")}
                </Link>}

                {
                  // <SubmitBtn
                  //   width={"20%"}
                  //   disabled={
                  //     CompareDate(
                  //       showEntrybyIdData.startDate,
                  //       showEntrybyIdData.endDate
                  //     )
                  //       ? false
                  //       : true
                  //   }
                  //   text={
                  //     showEntrybyIdData.showParticipantRequestStatus === "New"
                  //       ? translate("web.registerNow", "Register Now")
                  //       : showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Draft" ||
                  //         showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Pending" ||
                  //         showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Returned"
                  //       ? translate("web.continue", "Continue")
                  //       : translate("web.addConflict", "Add Conflict")
                  //   }
                  //   type={"link"}
                  //   to={
                  //     showEntrybyIdData.showParticipantRequestStatus === "New" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Draft" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Pending" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Returned"
                  //       ? `/showentrycard?entryFees=${
                  //           showEntrybyIdData.entryFees
                  //         }&maxHorses=${
                  //           parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                  //           parseInt(showEntrybyIdData.completedRequestsCount)
                  //         }&showName=${
                  //           showEntrybyIdData.showName
                  //         }&showId=${currentId}&registerStartDate=${
                  //           showEntrybyIdData.registerStartDate
                  //         }&registerEndDate=${
                  //           showEntrybyIdData.registerEndDate
                  //         }&registerStartDateInter=${
                  //           showEntrybyIdData.registerStartDateInter
                  //         }&registerEndDateInter=${
                  //           showEntrybyIdData.registerEndDateInter
                  //         }`
                  //       : `/showentryconflict?maxHorses=${
                  //           parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                  //           parseInt(showEntrybyIdData.completedRequestsCount)
                  //         }&showName=${
                  //           showEntrybyIdData.showName
                  //         }&showId=${currentId}`
                  //   }
                  // />
                }

                {showEntrybyIdData.showParticipantRequestStatus ===
                  "Completed" &&
                  showEntrybyIdData.maxHorseNumberPerOwner >
                  showEntrybyIdData.completedRequestsCount && (
                    <Link
                      className={`btn button-saveasdraft ${CompareDate(
                        showEntrybyIdData.registerStartDate,
                        showEntrybyIdData.endDate
                      )
                        ? "newbtn"
                        : "btn-danger"
                        } wd-20 marginrightleft-10`}
                      disabled={
                        CompareDate(
                          showEntrybyIdData.registerStartDate,
                          showEntrybyIdData.endDate
                        )
                          ? false
                          : true
                      }
                      onClick={(event) => {
                        if (
                          !CompareDate(
                            showEntrybyIdData.registerStartDate,
                            showEntrybyIdData.endDate
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      to={`/showentrycard?entryFees=${showEntrybyIdData.entryFees
                        }&maxHorses=${parseInt(
                          showEntrybyIdData.maxHorseNumberPerOwner
                        )}&showNameAr=${showEntrybyIdData.showNameAr
                        }&showNameEn=${showEntrybyIdData.showNameEn
                        }&showId=${currentId}&registerStartDate=${showEntrybyIdData.registerStartDate
                        }&registerEndDate=${showEntrybyIdData.registerEndDate
                        }&registerStartDateInter=${showEntrybyIdData.registerStartDateInter
                        }&registerEndDateInter=${showEntrybyIdData.registerEndDateInter
                        }`}
                    >
                      {translate(
                        "web.addAdditionalHorse",
                        "Add Additional Horse"
                      )}
                    </Link>
                  )}
              </div>
            </div>
          </div>
        </div>
        {
          // location
        }
        {((showEntrybyIdData.address != "" &&
          showEntrybyIdData.address != "undefiend" &&
          showEntrybyIdData.address) ||
          (showEntrybyIdData.mapLocation != "" &&
            showEntrybyIdData.mapLocation != "undefiend" &&
            showEntrybyIdData.mapLocation != null)) && (
            <div className="col-md-12 col-sm-12  mb-90">
              {showEntrybyIdData.address != "" &&
                showEntrybyIdData.address != "undefiend" &&
                showEntrybyIdData.address && (
                  <div className="col-md-3 col-sm-12 ">
                    <span className="horseMarketCardLabeltitle">
                      {translate("web.address", "Address")}
                    </span>
                    <p>
                      <span className="horseMarketCardLabeltitle ">
                        {showEntrybyIdData.address}
                        {
                          //"Building 100, Any Street, Abu Dhabi, ABZ 001"
                        }
                      </span>
                    </p>
                  </div>
                )}
              {showEntrybyIdData.mapLocation != "" &&
                showEntrybyIdData.mapLocation != "undefiend" &&
                showEntrybyIdData.mapLocation != null && (
                  <div
                    className="col-md-9 col-sm-12 "
                    dangerouslySetInnerHTML={{
                      __html: generateIframeFromUrl(
                        showEntrybyIdData.mapLocation
                      ),
                    }}
                  ></div>
                )}
            </div>
          )}
        {
          // Terms & Conditions
        }
        <div className="col-md-12 col-sm-12  rtl mb-90">
          <div className="col-md-12  col-sm-12">
            <span className="horseMarketCardLabeltitle d-block">
              {translate(
                "web.participationTermsAndConditions",
                "Participation Terms & Conditions"
              )}
            </span>
          </div>

          {!!showEntryFilesData &&
            showEntryFilesData.length > 0 &&
            showEntryFilesData.map((fileItem, iFileItem) => (
              <div className="col-md-12 col-sm-12 mb-10" key={iFileItem}>
                <div className="col-md-2  col-sm-12">
                  <span className="horseCardlabelContent ">
                    {
                      //translate("web.registrationTC", "Registration T&C")
                      fileItem.fileName
                    }
                  </span>
                </div>
                <Popup
                  modal
                  trigger={
                    <div key={iFileItem} className="btn btn-danger col-md-2  col-sm-12">

                      {translate("web.viewDetails", "View Details")}
                      {/* <SubmitBtn
                    type={"linkReset"}
                    text={translate("web.viewDetails", "View Details")}
                    to={fileItem.url}
                    target="_blank"
                  />  */}
                    </div>
                  }
                  closeOnDocumentClick={true}
                  closeOnEscape
                  className="popupUnsetmaxWidth"
                >
                  {(close) => (
                    <React.Fragment>
                      <div
                        className="text-start"
                        style={{ maxHeight: 300, overflowY: 'scroll' }}
                        dangerouslySetInnerHTML={{ __html: fileItem.termsAndConditions }}
                      />
                      <br />
                      <div
                      className="rtl"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        }}
                      >
                        <ActionButton type={'btn'} label={translate("web.close", "close")} onClick={close} text={translate("web.close", "close")} />
                        <SubmitBtn
                          type={"linkReset"}
                          text={translate("web.downloadFile", "DownLoad Terms And Conditions File")}
                          to={fileItem.url}
                          target="_blank"
                        />
                      </div>
                    </React.Fragment>
                  )}
                </Popup>
                {/* <SubmitBtn
                    type={"linkReset"}
                    text={translate("web.viewDetails", "View Details")}
                    to={fileItem.url}
                    target="_blank"
                  /> */}
              </div>
            ))}
          {
            // <div className="col-md-12 col-sm-12 mb-10">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate("web.registrationTC", "Registration T&C")}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
            // <div className="col-md-12 col-sm-12 mt-15 mb-5">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate("web.horseEntryTC", "Horse entry t&C")}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
            // <div className="col-md-12 col-sm-12 mt-15 mb-5">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate(
            //         "web.internationalRegistrationTC",
            //         "International Registration T&C"
            //       )}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
          }
        </div>
        {
          // Categories
        }
        <div className="col-md-12 col-sm-12  m0 row rtl">
          <div className="col-md-12 col-sm-12 ">
            <span className="horseMarketCardLabeltitle">
              {translate("web.categories", "Categories")}
            </span>
          </div>
          {!!showEntryClassesData && showEntryClassesData.length > 0 ? (
            showEntryClassesData.map((item, i) => (
              <div
                className="col-md-3 col-sm-12 mb-10 text-center bordercategorycard "
                key={i}
              >
                <h3 className="fontWeight">
                  {item.className !== null && item.className !== "" ? (
                    item.className
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </h3>
                <h4 className="fontWeight">
                  {translate("web.birthDate", "Birth Date")}
                </h4>
                <span className="horseCardlabelContent fontWeight">
                  {translate("web.from", "From")} :{" "}
                  {item.birthDateFrom && formatDate(item.birthDateFrom)}
                </span>
                <span className="horseCardlabelContent fontWeight">
                  {translate("web.to", "To")} :{" "}
                  {item.birthDateTo && formatDate(item.birthDateTo)}
                </span>
                <h4 className="fontWeight">
                  {translate("web.maxNumber", "Max Number")}
                </h4>
                <span className="horseCardlabelContent fontWeight">
                  {item.maxNumPerClass} {translate("web.horses", "Horses")}
                </span>
                <span className="horseCardlabelContent colorred fontWeight">
                  {translate("web.remaining", "Remaining")}
                </span>
                <span className="horseCardlabelContent fontWeight">
                  {item.remainingHorses}
                </span>
              </div>
            ))
          ) : (
            <h2 className="text-danger text-center dblock">
              {translate("web.nodataFound", "No Data Found !")}
            </h2>
          )}
        </div>
        {
          // media Gallery
        }

        {
          // <div className="col-md-12 rtl mb-25">
          //   <h2>{translate("web.mediaGallery", "Media Gallery")}</h2>
          //   <div className="col-md-12 showsGallery">
          //     {!!showGalleryData && showGalleryData.length > 0 ? (
          //       <OwlCarousel
          //         className="owl-theme"
          //         {...carouselOptions}
          //         marginWidth={1}
          //       >
          //         {showGalleryData.map((itemGallery, iItemGallery) => (
          //           <div className="carousel-item" key={iItemGallery}>
          //             <img
          //               className="historyImage"
          //               alt="asd"
          //               src={itemGallery.url}
          //               style={{
          //                 width: "100%",
          //                 height: "240px",
          //               }}
          //             />
          //           </div>
          //         ))}
          //       </OwlCarousel>
          //     ) : (
          //       <h2 className="text-danger text-center dblock">
          //         {translate("web.comingSoon", "Comming Soon...")}
          //       </h2>
          //     )}
          //   </div>
          // </div>
        }
        {
          //Show Schedule
        }
        <div className="col-md-12 rtl">
          <h2>{translate("web.showSchedule", "Show Schedule")}</h2>
          {!!showScheduleData && showScheduleData.length > 0 ? (
            showScheduleData.map((item, i) => (
              <div className="col-md-6 mb-25" key={i}>
                <div className="col-md-12 bordercategorycard titleShowSchedule">
                  <p className="showScheduleParag">{item.dayDisplayName}</p>
                  <p className="showScheduleParag">{item.dayName}</p>
                  <p className="showScheduleParag">{item.time}</p>
                </div>

                {item.showClassesList.map(
                  (showClassesListItem, iShowClassesList) => (
                    <div
                      className="col-md-12 bordercategorycard"
                      key={iShowClassesList}
                    >
                      <div className="col-md-4">
                        <h4>
                          {!!showClassesListItem.className &&
                            showClassesListItem.className.substring(0, 4)}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h4>{showClassesListItem.className.substring(4)}</h4>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))
          ) : (
            <h2 className="text-danger text-center dblock">
              {translate("web.comingSoon", "Comming Soon...")}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}
