import React from "react";

export default function SectionTitle({ section_id, section_name ,style={} , h2style={}}) {
  const id = section_id;
  return (
    <div className="section_title" id={id}  style={style}>
      <div className="line"></div>
      <h2 style={h2style} >{section_name}</h2>
      <div className="line"></div>
    </div>
  );
}
