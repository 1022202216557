import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchAddEditHorseQualificationAPI,
  FetchAddEditInternationalHorseAPI,
  FetchAddJudgeConflictAPI,
  FetchCreateShowEntryAPI,
  FetchDeleteJudgeConflictAPI,
  FetchGetHorseQualificationByHorseIdAPI,
  FetchInternationalHorsesByIdAPI,
  FetchMyShowParticipantsRequestAPI,
  FetchOwnerRequestsCountAPI,
  FetchSearchInternationalHorsesAPI,
  FetchShowConflictsAPI,
  FetchShowEntryAPI,
  FetchShowEntryClassesAPI,
  FetchShowEntryFilesAPI,
  FetchShowEntryOwnerHorsesAPI,
  FetchShowEntrybyIdAPI,
  FetchShowRequestAPI,
  FetchShowRequestHorsesAPI,
  FetchShowScheduleAPI,
  FetchShowTermsAPI,
  FetchSubOwnerProfilesByIdAPI,
} from "./ShowEntryApi";

export const FetchShowEntrythunk = createAsyncThunk(
  "ShowEntry/FetchShowEntry",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowEntryAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowEntrybyIdthunk = createAsyncThunk(
  "ShowEntry/FetchShowEntrybyId",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowEntrybyIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowEntryFilesthunk = createAsyncThunk(
  "ShowEntry/FetchShowEntryFiles",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowEntryFilesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowEntryOwnerHorsesthunk = createAsyncThunk(
  "ShowEntry/FetchShowEntryOwnerHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowEntryOwnerHorsesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowEntryClassesthunk = createAsyncThunk(
  "ShowEntry/FetchShowEntryClasses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowEntryClassesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowSchedulethunk = createAsyncThunk(
  "ShowEntry/FetchShowSchedule",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowScheduleAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCreateShowEntrythunk = createAsyncThunk(
  "ShowEntry/FetchCreateShowEntry",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCreateShowEntryAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCreateShowEntryRegisteredthunk = createAsyncThunk(
  "ShowEntry/FetchCreateShowEntryRegistered",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCreateShowEntryAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchSearchInternationalHorsesthunk = createAsyncThunk(
  "ShowEntry/FetchSearchInternationalHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchSearchInternationalHorsesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchInternationalHorsesByIdthunk = createAsyncThunk(
  "ShowEntry/FetchInternationalHorsesById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchInternationalHorsesByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchAddEditInternationalHorsethunk = createAsyncThunk(
  "ShowEntry/FetchAddEditInternationalHorse",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAddEditInternationalHorseAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchMyShowParticipantsRequestthunk = createAsyncThunk(
  "ShowEntry/FetchMyShowParticipantsRequest",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchMyShowParticipantsRequestAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowRequestthunk = createAsyncThunk(
  "ShowEntry/FetchShowRequest",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowRequestAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowRequestHorsesthunk = createAsyncThunk(
  "ShowEntry/FetchShowRequestHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowRequestHorsesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchAddJudgeConflictthunk = createAsyncThunk(
  "ShowEntry/FetchAddJudgeConflict",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAddJudgeConflictAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowConflictsthunk = createAsyncThunk(
  "ShowEntry/FetchShowConflicts",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowConflictsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchDeleteJudgeConflictthunk = createAsyncThunk(
  "ShowEntry/FetchDeleteJudgeConflict",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchDeleteJudgeConflictAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchSubOwnerProfilesByIdthunk = createAsyncThunk(
  "ShowEntry/FetchSubOwnerProfilesById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchSubOwnerProfilesByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowTermsthunk = createAsyncThunk(
  "ShowEntry/FetchShowTerms",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowTermsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchOwnerRequestsCountthunk = createAsyncThunk(
  "ShowEntry/FetchOwnerRequestsCount",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerRequestsCountAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchAddEditHorseQualificationthunk = createAsyncThunk(
  "ShowEntry/FetchAddEditHorseQualification",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAddEditHorseQualificationAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetHorseQualificationByHorseIdthunk = createAsyncThunk(
  "ShowEntry/FetchGetHorseQualificationByHorseId",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetHorseQualificationByHorseIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
