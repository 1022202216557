import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchGetOwnersListthunk,
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchFilterOwnersOrUsersthunk,
  FetchStudBookOwnerDetailsthunk,
} from "../Redux/StudBook/StudBookAction";
import {
  FetchApproveRejectRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import { resetToasterMessageServicesRequests } from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import UploadFile from "../components/UploadFile";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";

export default function RequestHorseOwnershipTransferSeller() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );
  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const { showEntryLoading, subOwnerProfilesByIdData } = useSelector(
    (state) => state.showEntrySlice
  );

  //dispatch
  const dispatch = useDispatch();

  //translate
  const { translate } = useTranslate();

  //navigate
  const navigate = useNavigate();

  //Location
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: "",
    },
    regNo: "",
    horseName: "",
    gender: "",
    color: "",
    birthDate: "",
    sire: "",
    dam: "",
    origin: "",
    breeder: "",
    owner: "",
    ownerDrop: {
      label: translate("web.owner", "owner"),
      value: "",
    },
    ownerName: "",
    ownerRegNo: "",
    country: "",
    city: "",
    area: "",
    phone: "",
    email: "",
    idNumber: "",
    employeeId: "",
    buyerComment: "",
    eventName: "",
    attachmentFile: null,
    comment: "",
    referenceNumber: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [ownerListLoading, setOwnerListLoading] = useState(false);
  const _status = useRef("");
  const statusRequest = useRef("");
  const checkAnotherOwner = useRef(true);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [uploadAttachmentFile, setUploadAttachmentFile] = useState(null);
  const [profile, setProfile] = useState({
    label: localStorage.getItem("EAHSUserFullName"),
    value: localStorage.getItem("EAHSUserID"),
  });
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function
  const handleFormChange = (e, input) => {
    if (input === "horse") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        horseDrop: e,
        horse: e.value,
        regNo: horse[0].registerNumber,
        horseName: horse[0].name,
        gender: horse[0].gender,
        color: horse[0].color,
        birthDate: horse[0].birthDate,
        sire: horse[0].sire,
        dam: horse[0].dam,
        origin: horse[0].origin,
        breeder: horse[0].breeder,
      });
    } else if (input === "owner") {
      if (e?.value != null && e?.value != undefined) {
        // let params = {
        //   id: e.value,
        //   langauge: currentLanguage,
        //   skip: 0,
        //   take: 10000,
        //   tableType: "user",
        // };

        //dispatch(FetchStudBookOwnerDetailsthunk(params));
        //dispatch(FetchFilterOwnersOrUsersthunk(params));

        setFormModel({
          ...formModel,
          ownerDrop: e,
          owner: e.value,
          email: e.details.email,
          phone: e.details.phone,
          idNumber: e.details.idNumber,
        });
      }
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handelFormSubmit = (status) => {
    debugger;
    if (formModel.horse != "" && formModel.owner != "") {
      let params = {
        id: id,
        serviceDefinitionId: serviceId,
        // employeeId:
        //   formModel.eventName == "SentBack"
        //     ? formModel.employeeId
        //     : localStorage.getItem("EAHSUserID"),
        employeeId: localStorage.getItem("EAHSUserID"),
        date: new Date().toLocaleDateString(),
        status: formModel.eventName == "SentBack" ? "PendingReview" : status,
        //status: status == "BuyerApproved" ? "WatingBuyerApproval" : status,
        requestDetails: {
          horseId: formModel.horse,
          // ownerId:
          //   formModel.eventName == "SentBack"
          //     ? formModel.employeeId
          //     : localStorage.getItem("EAHSUserID"),
          ownerId:
            formModel.eventName == "SentBack"
              ? formModel.employeeId
              : profile.value,
          newOwnerId: formModel.owner,
          attachmentFile: formModel.attachmentFile,
          createdBy: localStorage.getItem("EAHSUserID"),
        },
      };

      if (action == null) {
        //statusRequest.current = "new";
        dispatch(FetchCreateOwnerShipTransferServicesRequeststhunk(params));
      } else if (action === "edit" || formModel.eventName == "SentBack") {
        //statusRequest.current = "edit";
        dispatch(FetchUpdateOwnerShipTransferServicesRequeststhunk(params));
      }
    } else {
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };

  const handelApproveRejectRequest = (status) => {
    _status.current = status;

    let params = {
      id: id,
      // employeeId: formModel.employeeId,
      employeeId: localStorage.getItem("EAHSUserID"),
      status: status,
      comment: formModel.buyerComment,
    };

    dispatch(FetchApproveRejectRequestthunk(params));
  };

  const handleUploadServiceRequestDocumentChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          attachmentFile: e.target.files[0],
        });

        setUploadAttachmentFile({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
        });
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: "",
      },
      gender: "",
      color: "",
      birthDate: "",
      sire: "",
      dam: "",
      origin: "",
      breeder: "",
    });
  };

  const closeModal = () => setOpenPopup(false);

  //Effect
  useEffect(() => {
    let params = {
      ownerId: profile.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: profile.value,
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));

    //dispatch(FetchGetOwnersListthunk(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 &&
      checkAnotherOwner.current
    ) {
      if (ownerHorsesDropDownData.length > 0) {
        // let horse = ownerHorsesDropDownData.filter(
        //   (item) =>
        //     item.id == getServicesRequestByIdData.ownerShipTransfer.horseId
        // );

        //if (horse.length > 0) {
        setFormModel({
          ...formModel,
          // horse: horse[0].id,
          // horseDrop: {
          //   label: horse[0].registerNumber + " - " + horse[0].name,
          //   value: horse[0].id,
          // },
          // regNo: horse[0].registerNumber,
          // horseName: horse[0].name,
          // gender: horse[0].gender,
          // color: horse[0].color,
          // birthDate: horse[0].birthDate,
          // sire: horse[0].sire,
          // dam: horse[0].dam,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage == "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId: getServicesRequestByIdData.ownerShipTransfer.createdBy,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          //ownerName: getServicesRequestByIdData.ownerName,
          //country: getServicesRequestByIdData.ownerNationality,
          //city: getServicesRequestByIdData.ownerEmirateName,
          //area: getServicesRequestByIdData.ownerAreaName,
          //ownerRegNo: getServicesRequestByIdData.ownerId,
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          comment: getServicesRequestByIdData.comment,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment:
            getServicesRequestByIdData.ownerShipTransfer.buyerComment,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin:
            getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
        });

        //   let params = {
        //     ownerId: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
        //     langauge: currentLanguage,
        //   };

        //   dispatch(FetchStudBookOwnerDetailsthunk(params));
        //}
      } else {
        setFormModel({
          ...formModel,
          horse: getServicesRequestByIdData.ownerShipTransfer.horseId,
          horseDrop: {
            label:
              getServicesRequestByIdData.ownerShipTransfer.horse
                .registrationNumber +
              " - " +
              getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
            value: getServicesRequestByIdData.ownerShipTransfer.horseId,
          },
          regNo:
            getServicesRequestByIdData.ownerShipTransfer.horse
              .registrationNumber,
          horseName: getServicesRequestByIdData.ownerShipTransfer.horse.nameEn,
          gender: getServicesRequestByIdData.ownerShipTransfer.horse.gender,
          color:
            currentLanguage == "en"
              ? getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameEn
              : getServicesRequestByIdData.ownerShipTransfer.horse.horseColor
                .nameAr,
          birthDate:
            getServicesRequestByIdData.ownerShipTransfer.horse.birthDate,
          sire: getServicesRequestByIdData.ownerShipTransfer.horse.sireNameEn,
          dam: getServicesRequestByIdData.ownerShipTransfer.horse.damNameEn,

          employeeId: getServicesRequestByIdData.ownerShipTransfer.createdBy,
          owner: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          ownerDrop: {
            label:
              getServicesRequestByIdData.ownerId +
              " - " +
              getServicesRequestByIdData.ownerName,
            value: getServicesRequestByIdData.ownerShipTransfer.newOwnerId,
          },
          phone: getServicesRequestByIdData.ownerPhone,
          email: getServicesRequestByIdData.ownerEmail,
          idNumber: getServicesRequestByIdData.idNumber,
          eventName: getServicesRequestByIdData.eventName,
          comment: getServicesRequestByIdData.comment,
          referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
          buyerComment:
            getServicesRequestByIdData.ownerShipTransfer.buyerComment,
          breeder: getServicesRequestByIdData.ownerShipTransfer.horse.breeder,
          origin:
            getServicesRequestByIdData.ownerShipTransfer.horse.exportCountry,
        });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.attachmentUrl != null &&
        getServicesRequestByIdData.ownerShipTransfer.attachmentUrl !== ""
      ) {
        setUploadAttachmentFile({
          file: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl,
          name: getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.substr(
            getServicesRequestByIdData.ownerShipTransfer.attachmentUrl.lastIndexOf(
              "/"
            ) + 1
          ),
        });
      }

      if (
        getServicesRequestByIdData.ownerShipTransfer.ownerId !==
        getServicesRequestByIdData.ownerShipTransfer.createdBy &&
        subOwnerProfilesByIdData.length > 0
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.ownerShipTransfer.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update checkAnotherOwner
        checkAnotherOwner.current = false;
        //update profile
        setProfile({
          label: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.ownerShipTransfer.ownerId
          ).text,
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.ownerShipTransfer.ownerId
          ).value,
        });
      }
    }
  }, [
    ownerHorsesDropDownData,
    getServicesRequestByIdData,
    subOwnerProfilesByIdData,
  ]);

  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }
  }, []);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_status.current === "BuyerApproved") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_status.current === "BuyerApproved") {
          //go to payment
          //const returnUrl = `${BaseURL}/servicesrequestspaymentsuccessful?serviceRequestId=${id}`;
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=7&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }
  }, [toasterMessageServicesRequests]);

  // useEffect(() => {
  //   //if (Object.keys(ownerDetailsData).length > 0) {
  //   if (filterOwnersOrUsersData.length > 0) {
  //     setFormModel({
  //       ...formModel,
  //       phone: filterOwnersOrUsersData[0].phone,
  //       email: filterOwnersOrUsersData[0].email,
  //       idNumber: filterOwnersOrUsersData[0].idNumber,
  //     });
  //   }
  // }, [filterOwnersOrUsersData]);

  // useEffect(() => {
  //   if (
  //     Object.keys(getServicesRequestByIdData).length > 0 &&
  //     //getOwnersListData.length > 0 &&
  //     ownerHorsesDropDownData.length > 0
  //   ) {
  //     let horse = ownerHorsesDropDownData.filter(
  //       (item) =>
  //         item.id == getServicesRequestByIdData.ownerShipTransfer.horseId
  //     );

  //     let owner = getOwnersListData.filter(
  //       (item) =>
  //         item.id == getServicesRequestByIdData.ownerShipTransfer.newOwnerId
  //     );

  //     if (action !== "approve") {
  //       setFormModel({
  //         ...formModel,
  //         horse: horse[0].id,
  //         horseDrop: {
  //           label: horse[0].name,
  //           value: horse[0].id,
  //         },
  //         regNo: horse[0].registerNumber,
  //         horseName: horse[0].name,
  //         gender: horse[0].gender,
  //         color: horse[0].color,
  //         birthDate: horse[0].birthDate,
  //         sire: horse[0].sire,
  //         dam: horse[0].dam,
  //         owner: owner[0].id,
  //         ownerDrop: {
  //           label: owner[0].fullName,
  //           value: owner[0].id,
  //         },
  //         employeeId: getServicesRequestByIdData.employeeId,
  //       });
  //     } else {
  //       let params = {
  //         ownerId: getServicesRequestByIdData.employeeId,
  //         skip: 0,
  //         take: 1000,
  //         langauge: currentLanguage,
  //         horseTypeFilter: "1",
  //         searchValue: "",
  //         sortBy: "-1",
  //       };
  //       dispatch(FetchOwnerHorsesDropDownthunk(params));

  //       setFormModel({
  //         ...formModel,
  //         owner: owner[0].id,
  //         ownerDrop: {
  //           label: owner[0].fullName,
  //           value: owner[0].id,
  //         },
  //         employeeId: getServicesRequestByIdData.employeeId,
  //       });
  //     }

  //     let params = {
  //       ownerId: owner[0].id,
  //       langauge: currentLanguage,
  //     };

  //     dispatch(FetchStudBookOwnerDetailsthunk(params));
  //   }
  // }, [getServicesRequestByIdData]);

  const dismissAll = () => toast.dismiss();

  //console
  //console.log("checkAnotherOwner", checkAnotherOwner);
  // console.log("getOwnersListData", getOwnersListData);
  // console.log("ownerHorsesDropDownData", ownerHorsesDropDownData);
  // console.log("form", formModel);
  // console.log("action", action);
  console.log("subOwnerProfilesByIdData", subOwnerProfilesByIdData);
  console.log("getServicesRequestByIdData", getServicesRequestByIdData);
  console.log("uploadAttachmentFile", uploadAttachmentFile);

  // asyunc search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/StudBook/FilterOwnersOrUsers",
          {
            langauge: localStorage.getItem("eahsLang") ?? "en",
            ownerId: searchKeyword,
            skip: 0,
            take: 10000,
            tableType: "user",
          }
        );

        const data = await response.data.ownersList;

        const options = data.map((item) => ({
          label: item.ownerId + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading || ownerListLoading
            ? "classdisplay"
            : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl ">
          {
            // title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage == "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            // Selected Account
          }
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action == "edit") && (
              <div className="col-md-6 col-sm-12 mb-5">
                <React.Fragment>
                  <label className="capatalize_text">
                    {translate("web.selectedAccount", "Selected Account")}
                  </label>
                  <Select
                    className="mt-10 zindex200"
                    options={[
                      {
                        label: localStorage.getItem("EAHSUserFullName"),
                        value: localStorage.getItem("EAHSUserID"),
                      },
                      ...(!!subOwnerProfilesByIdData &&
                        !!subOwnerProfilesByIdData.length > 0 &&
                        subOwnerProfilesByIdData.map((a) => ({
                          label: a.text,
                          value: a.value,
                        }))),
                    ]}
                    value={profile}
                    onChange={(e) => {
                      handelSelectedAccountChange(e);
                    }}
                    placeholder={localStorage.getItem("EAHSUserFullName")}
                    isSearchable
                    noOptionsMessage={() =>
                      translate("web.noOptions", "No Options")
                    }
                    styles={customStyles}
                  />
                </React.Fragment>
              </div>
            )}
          {
            // horse
          }
          <h2 className="fontWeight colorred">
            {translate("web.horse", "Horse")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard row rtl  mb-25">
            <div className="col-md-6 col-sm-12 ">
              <label>
                {translate("web.horse", "Horse")}
                <i className="colorred">*</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.horse", "Horse"),
                    value: "",
                  },
                  ...(!!ownerHorsesDropDownData &&
                    ownerHorsesDropDownData.map((a) => ({
                      label: a.registerNumber + " - " + a.name,
                      value: a.id,
                    }))),
                ]}
                value={formModel.horseDrop}
                onChange={(e) => handleFormChange(e, "horse")}
                placeholder={translate("web.horse", "Horse")}
                name={"horse"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                isDisabled={
                  action == "view" ||
                  // (action == "approve" && formModel.eventName != "SentBack")
                  action == "approve"
                }
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.origin", "Origin")}
                name={"origin"}
                type={"text"}
                disabled={true}
                value={formModel.origin}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.breeder", "Breeder")}
                name={"breeder"}
                type={"text"}
                disabled={true}
                value={formModel.breeder}
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.gender", "Gender")}
                name={"gender"}
                type={"text"}
                disabled={true}
                value={translate(
                  `web.${formModel.gender.toLowerCase()}`,
                  formModel.gender
                )}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.color", "Color")}
                name={"color"}
                type={"text"}
                disabled={true}
                value={formModel.color}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.birthDate", "Birth Date")}
                name={"birthDate"}
                type={"text"}
                disabled={true}
                value={
                  formModel.birthDate != null && formModel.birthDate !== ""
                    ? formModel.birthDate && formatDate(formModel.birthDate)
                    : ""
                }
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.sire", "Sire")}
                name={"sire"}
                type={"text"}
                disabled={true}
                value={formModel.sire}
              />
            </div>
            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.dam", "Dam")}
                name={"dam"}
                type={"text"}
                disabled={true}
                value={formModel.dam}
              />
            </div>
          </div>
          {
            // buyer owner
          }
          <h2 className="fontWeight colorred">
            {translate("web.buyerOwner", "Buyer Owner")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard  row rtl mb-25">
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.owner", "Owner")}
                <i className="colorred">*</i>
              </label>
              {/* <Select
                  options={[
                    {
                      label: translate("web.owner", "Owner"),
                      value: null,
                    },
                    ...(!!getOwnersListData &&
                      getOwnersListData.map((a) => ({
                        label: a.fullName,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.ownerDrop}
                  onChange={(e) => handleFormChange(e, "owner")}
                  placeholder={translate("web.owner", "Owner")}
                  name={"owner"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  isDisabled={action === "view" || action === "approve"}
                /> */}
              <AsyncSelect
                isClearable
                loadOptions={promiseOptions}
                defaultOptions={false} // Set to true to load initial options
                onInputChange={(newValue) => setInputValue(newValue)}
                inputValue={inputValue}
                value={formModel.ownerDrop}
                loadingMessage={() => translate("web.loading", "Loading")}
                onChange={(e) => handleFormChange(e, "owner")}
                placeholder={
                  translate("web.typeToSearch", "Type To Search") + "..."
                }
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                isDisabled={action == "view" || action == "approve"}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.smsPhoneNo", "Mobile No")}
                name={"mobileNo"}
                type={"text"}
                disabled={true}
                value={formModel.phone}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate(
                  "web.passportOrEmiratesId",
                  "Passport/EmiratesId"
                )}
                name={"name"}
                type={"text"}
                disabled={true}
                value={formModel.idNumber}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.email", "Email")}
                name={"email"}
                type={"text"}
                disabled={true}
                value={formModel.email}
              />
            </div>
          </div>
          {
            // upload attachment
          }
          <h2 className="fontWeight colorred">
            {translate("web.uploadDocument", "Upload Document")}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard row rtl  mb-25">
            <div className="col-md-4 col-sm-12  mb-25">
              <h3>
                {translate(
                  "web.serviceRequestDocument",
                  "Service Request Document"
                )}
              </h3>
              <span className="font-15 Gray_color">
                {translate(
                  "web.serviceRequestDocumentType",
                  "Service Request Document (pdf, jpg, or jpeg)"
                )}
              </span>
              <UploadFile
                text={"Upload"}
                className="maxWidth150 mt-10"
                ismultiFile={false}
                disabled={
                  action == "view" ||
                    (action === "approve" && formModel.eventName != "SentBack")
                    ? true
                    : false
                }
                onChange={handleUploadServiceRequestDocumentChange}
                iconClassName={"fa-solid fa-upload fa-lg marginrightleft-5"}
                accept={"image/*,application/pdf"}
              />
              <br />
              <div className="col-md-12 col-sm-12 mb-25 ">
                {uploadAttachmentFile != null && (
                  <Link
                    to={uploadAttachmentFile.file}
                    className="C89C66 flex-center cursorPointer mb-25"
                    target="_blank"
                  >
                    <i className="fa fa-file fa-3x"></i>
                    <span className="black-color fontsize18 marginrightleft-10">
                      {uploadAttachmentFile.name}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {
            //Comments
          }
          {((formModel.comment !== "" && formModel.comment != null) ||
            (formModel.buyerComment !== "" && formModel.buyerComment != null) ||
            action === "approve") && (
              <React.Fragment>
                <h2 className="fontWeight colorred">
                  {translate("web.comment", "Comment")}
                </h2>
                <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                  {
                    // eahs Comment
                  }
                  {formModel.comment !== "" && (
                    <div className="col-md-6 col-sm-12">
                      <NetaqInput
                        label={translate("web.eahsComment", "EAHS Comment")}
                        name={"adminComment"}
                        type={"textarea"}
                        maxchars={1150}
                        value={formModel.comment}
                        disabled={true}
                      />
                    </div>
                  )}
                  {
                    // buyer Comment
                  }
                  {(action == "approve" ||
                    (action == "edit" &&
                      formModel.eventName == "BuyerRejected")) && (
                      <div className="col-md-6 col-sm-12 ">
                        <NetaqInput
                          label={translate("web.buyerComment", "Buyer Comment")}
                          name={"buyerComment"}
                          type={"textarea"}
                          placeholder={translate(
                            "web.buyerComment",
                            "Buyer Comment"
                          )}
                          maxchars={1150}
                          onChange={handleFormChange}
                          value={formModel.buyerComment}
                          disabled={action == "approve" ? false : true}
                        />
                      </div>
                    )}
                </div>
              </React.Fragment>
            )}
          {
            // buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {
              //draft/submit btn
            }
            {action == null ||
              action == "edit" ||
              (action == "approve" && formModel.eventName == "SentBack") ? (
              <React.Fragment>
                <ActionButton
                  type={"requests"}
                  className="btn buttonformshowentrycard height46 linkbtn"
                  label={translate("web.draft", "Draft")}
                  text={
                    <React.Fragment>
                      <i className="fa fa-save fa-lg marginrightleft-10"></i>
                      {translate("web.draft", "Draft")}
                    </React.Fragment>
                  }
                  margin={"auto 10px"}
                  width={"250px"}
                  onClick={() => handelFormSubmit("Draft")}
                  disabled={
                    // formModel.horse != "" &&
                    // formModel.owner != "" &&
                    formModel.eventName === "" ||
                      formModel.eventName === "Draft"
                      ? false
                      : true
                  }
                />
                <ActionButton
                  type={"requests"}
                  className="btn-success text-white requestBtn"
                  label={translate("web.submit", "Submit")}
                  text={
                    <React.Fragment>
                      <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                      {translate("web.submit", "Submit")}
                    </React.Fragment>
                  }
                  margin={"auto 25px"}
                  width={"200"}
                  onClick={() =>
                    handelFormSubmit(
                      action == "edit" && formModel.eventName == "BuyerRejected"
                        ? "WatingBuyerApproval"
                        : "WatingBuyerApproval"
                    )
                  }
                // disabled={
                //   formModel.horse != "" && formModel.owner != ""
                //     ? false
                //     : true
                // }
                />
              </React.Fragment>
            ) : action === "approve" && formModel.eventName != "SentBack" ? (
              //approve/reject btn
              <React.Fragment>
                <div className="row">
                  <div className="col-md-9 col-sm-12">
                    <ActionButton
                      className={"btn-success requestBtn"}
                      type={"requests"}
                      label={translate(
                        "web.approvalPayment",
                        "Approval Payment"
                      )}
                      text={
                        <React.Fragment>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.approvalPayment", "Approval Payment")}
                        </React.Fragment>
                      }
                      margin={"auto 10px"}
                      width={"250px"}
                      onClick={() =>
                        handelApproveRejectRequest("BuyerApproved")
                      }
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 d-flex flex-column justify-content-center ">
                    <ActionButton
                      type={"submit"}
                      label={translate("web.reject", "Reject")}
                      text={
                        <React.Fragment>
                          <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                          {translate("web.reject", "Reject")}
                        </React.Fragment>
                      }
                      additionalText={
                      `
                        ${translate(
                          "web.rejectBuyerComment",
                          "*To Reject The Request Please Enter The Buyer Comment*"
                        )}
                      `}
                      margin={"auto 10px"}
                      width={"250px"}
                      onClick={() => {
                        // let confirmText = window.confirm(
                        //   translate(
                        //     "web.confirmMsgRejectrequest",
                        //     "Are you sure to reject request?"
                        //   )
                        // );
                        // if (confirmText) {
                        //   handelApproveRejectRequest("BuyerRejected");
                        // } else {
                        //   return false;
                        // }
                        setOpenPopup((o) => !o);
                      }}
                      disabled={
                        formModel.buyerComment !== "" &&
                          formModel.buyerComment !== null
                          ? false
                          : true
                      }
                    />

                  </div>
                </div>
              </React.Fragment>
            ) : (
              // <button
              //   className="btn btn-danger pading-10-20 m-23 width_200"
              //   disabled
              // >
              //   <i className="fa fa-save fa-lg marginrightleft-10"></i>
              //   {translate("web.submit", "Submit")}
              // </button>
              ""
            )}
          </div>
          {
            //pop up
          }
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <React.Fragment>
                <div className="">
                  {translate(
                    "web.confirmMsgRejectrequest",
                    "Are you sure to reject request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl ">
                  <div
                    className="bidSubmit col-lg-4 col-sm-6 text-center"
                    onClick={closeModal}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopup((o) => !o);
                        handelApproveRejectRequest("BuyerRejected");
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.reject", "Reject")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}
