import React from 'react';
const BaseURL = process.env.REACT_APP_BASE_URL;

/**
 * Arena Table component
 * @param {number} tableNumber
 * @param {number} tablePrice
 * @param {string} tableState ['booked', 'hold', 'selected', 'available', 'NA', 'system']
 * @returns JSX Arena Table
 */
export default function ArenaTable({ tableNumber, tablePrice, onClick, state = 'available' }) {

    const mapStateToHtmlClass = {
        "booked": "isBookedTable",
        "hold": 'isHoldedTable',
        "selected": 'isSelectedTable',
        "available": '',
        "NA": 'notavailableTable',
        "system": "isSystemTable"
    }

    if (!!onClick && onClick.constructor !== Function) {
        throw new Error("onClick is required and to be a function.");
    }

    return (
        <div
            className={`bookingTable ${mapStateToHtmlClass[state]}`}
            onClick={['available', 'selected'].indexOf(state) > -1 ? onClick : null}
        >
            <img src={`${BaseURL}/assets/img/bookingtable.png`} alt={`Table ${tableNumber}`} />
            <div>
                {tablePrice.toLocaleString('en-US')}
                <span>AED</span>
            </div>
            <div className='TableNumber'>
                {tableNumber}
            </div>
        </div>
    );
}
