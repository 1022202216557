import { Navigate } from "react-router";
import axios from "../axios/axios";

export async function getUserProfile() {
    const userProfile = await axios.get("/account/profile");
    if (userProfile.data.status.detailedResponseCode !== "Success") {
      return <Navigate to="/login" />;
    } else {
      localStorage.setItem("EAHSUserID", userProfile.data.result.id);
      localStorage.setItem(
        "EAHSUserFullName",
        userProfile.data.result.fullName
      );
      const jsonString = JSON.stringify(userProfile.data.result);
      localStorage.setItem("eahsUserProfile", jsonString);
      return true;
    }
  }