import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FetchStudBookLanguagethunk } from "../Redux/StudBook/StudBookAction";
import { useDispatch, useSelector } from "react-redux";
const baseURL = process.env.REACT_APP_BASE_URL;

const Layout = ({ children }) => {
  const [userProfile, setUserProfile] = useState("");

  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchStudBookLanguagethunk(currentLanguage === "en" ? "1" : "2"));
  }, [currentLanguage]);

  return (
    <div>
      {currentLanguage === 'ar' && <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css" integrity="sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N" crossorigin="anonymous" />}
      {currentLanguage === 'en' && <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous" />}
      <link rel="stylesheet" href={`${baseURL}/assets/css/style-prod.css`} />
      {currentLanguage === 'ar' && <link rel="stylesheet" href={`${baseURL}/assets/css/rtl-prod.css`} />}

      {/* Pass setUserProfile as a prop to Header */}
      <Header setUserProfile={setUserProfile} />
      <div className="mainLayoutContainer">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
