import React, { useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslate } from "./TranslationText";
// date Picker
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { PickersTextField } from "@mui/x-date-pickers";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";

String.prototype.isWhitespacesOrEmpty = function () {
  return this.trim().length < 1;
};

export default function NetaqInput({
  name,
  placeholder,
  type,
  value,
  checked,
  defaultChecked,
  label,
  urlLabel,
  onChange,
  maxchars = 0,
  Options,
  initialValue,
  disabled = false,
  listSelect,
  htmlTags,
  info = false,
  defaultValue,
  isEditRequest = false,
  isWithURL = false,
  url = "",
  required,
  minDate,
  minValue,
  hideLable,
  isClearable = false,
  isMulti = false,
  onClick,
  style = {},
  isDash = false,
  ...others
}) {
  const [showPassword, setShowPassword] = useState(false);
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (name?.isWhitespacesOrEmpty() ?? true)
    return (
      <span style={{ color: "red" }}>
        Cannot Create Input without "name" attribute!
      </span>
    );

  if (!!onChange && !(onChange instanceof Function))
    throw new Error("OnChange attribute must be a callback function!");

  const transformToLabel = (text) => text.split(/(?=[A-Z])(?<!^)/)?.join(" ");
  const labelText = label ?? transformToLabel(name);
  return (
    <div className="form-group">
      {type === "text" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          )}
          {htmlTags}
          <input
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            style={style}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            required={required}
            disabled={disabled}
          />
        </React.Fragment>
      ) : null}
      {type === "orginisertext" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          )}
          {htmlTags}
          <input
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            style={style}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            required={required}
            disabled={disabled}
          />
        </React.Fragment>
      ) : null}
      {type === "textarea" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          )}
          {htmlTags}
          <textarea
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            maxLength={maxchars > 0 ? maxchars : "unset"}
            disabled={disabled}
            rows={6}
            style={{ height: 150, marginBottom: 15 }}
          />
        </React.Fragment>
      ) : null}
      {/* {type === "select" ? (
        <React.Fragment>
          <label htmlFor={label} className="capatalize_text">
            {label.replace(/-/g, " ")}{required? <i style={{color:'red'}}>*</i>:''}
          </label>
          {htmlTags}
          <select
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">{label}</option>
            {Options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </React.Fragment>
      ) : null} */}
      {type === "selectislam" ? (
        <React.Fragment>
          <select
            type="text"
            className="form-select text-right-rtl"
            id={label}
            style={{ marginBottom: 0 }}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">{label}</option>
            {Options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </React.Fragment>
      ) : null}
      {type === "email" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
          </label>
          {htmlTags}
          <input
            type="email"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            disabled={disabled}
            required={required}
            others
          />
        </React.Fragment>
      ) : null}
      {type === "password" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />

          <div className="position-relative">
            <button
              type="button"
              className="passwordButton"
              style={{
                right: currentLanguage == "en" ? 10 : "unset",
                left: currentLanguage == "ar" ? 10 : "unset",
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </React.Fragment>
      ) : null}
      {type === "radio" ? (
        <React.Fragment>
          <div className="d-flex-row-itemscenter-justify-flexStart">
            <input
              className="form-check-input radiobuttonstyle"
              type="radio"
              name={name}
              value={value}
              defaultChecked={checked}
              id={label}
              onChange={onChange}
              disabled={disabled}
            />
            <label className="form-check-label radioLable" htmlFor={label}>
              {label.replace(/-/g, " ")}{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
              {info ? <i class="info_circle  fa-solid fa-circle-info"></i> : ""}
            </label>
          </div>
        </React.Fragment>
      ) : null}
      {type === "number" ? (
        <React.Fragment>
          {!hideLable &&
            <label htmlFor={name} className="capatalize_text">
              {!!label && label.replace(/-/g, " ")}{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          }
          {htmlTags}
          <input
            type="number"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            min={minValue ?? 0}
            disabled={disabled}
          />
        </React.Fragment>
      ) : null}
      {type === "tel" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
          </label>
          <input
            type="tel"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />
        </React.Fragment>
      ) : null}
      {type === "date" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!hideLable &&
            <label htmlFor={name} className="capatalize_text">
              {
                label
                //label.replace(/-/g, " ")
              }{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          }
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={disabled}
              format="DD/MM/YYYY"
              onChange={(event) => onChange(event, name)}
              showDaysOutsideCurrentMonth
              clearable
              value={value}
              autoFocus={false}
              minDate={minDate ?? null}
              sx={{
                // "& .MuiOutlinedInput-input": { fontSize:16 },
                "& .MuiOutlinedInput-input": {
                  fontSize: "12px",
                  paddingLeft: "5px",
                  maxHeight: "34px",
                  maxWidth: "130px",
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "12px",
                  paddingLeft: "5px",
                  background: "#fff",
                  maxHeight: "34px",
                  maxWidth: "130px",
                },
              }}
            />
          </LocalizationProvider>
        </div>
      ) : null}
      {/* {type === "date" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {
              label
              //label.replace(/-/g, " ")
            } {required ? <i style={{ color: 'red' }}>*</i> : ''}
          </label>
          <input
            type="date"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
            max="2999-12-31"
          />
        </React.Fragment>
      ) : null} */}
      {type === "checkbox" ? (
        <React.Fragment>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={value}
              id={label}
              name={name}
              placeholder={placeholder}
              checked={checked} // Set checked based on state
              onChange={onChange} // Handle change event
              onClick={onClick}
              disabled={disabled}
              defaultChecked={defaultChecked}
            />
            <label
              className="form-check-label ml-5 mt--5 netaqCheckboxLabel"
              htmlFor={label}
            >
              {htmlTags}
              {!isDash && !isWithURL && !!label && label.replace(/-/g, " ")}{" "}
              {isDash && !isWithURL && !!label && label}
              {!isDash && isWithURL &&
                <>
                  {label}
                  <Link to={url} target="_blank" className="mx-2">
                    {urlLabel}
                  </Link>
                </>
              }
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          </div>
        </React.Fragment>
      ) : null}

      {type === "select" ? (
        <React.Fragment>
          {hideLable != true ? (
            <label htmlFor={label} className="capatalize_text">
              {label.replace(/-/g, " ")}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          ) : (
            <></>
          )}
          <Select
            options={[
              ...(!!listSelect &&
                listSelect.map((a) => ({
                  label: a.text,
                  value: a.value,
                  ...a,
                }))),
            ]}
            placeholder={placeholder}
            name={name}
            isSearchable
            hideSelectedOptions
            isDisabled={disabled}
            defaultValue={defaultValue}
            isMulti={isMulti}
            onChange={onChange}
            isClearable={isClearable}
            maxMenuHeight={175}
            required={required}
            value={value}
            noOptionsMessage={() => translate("web.noOptions", "No Options")}
          />
        </React.Fragment>
      ) : null}
      {type == 'htmlEditor' &&
        <>
          {hideLable != true ? (
            <label htmlFor={label} className="capatalize_text">
              {label.replace(/-/g, " ")}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          ) : (
            <></>
          )}

          <Editor
            apiKey='r3rxq69ks9bgn5m2q3ranq0nm9jc9j41x3ucqupecnmrdlqb'
            onInit={(_evt, editor) => editorRef.current = editor}
            initialValue={initialValue}
            onEditorChange={(e) => {
              onChange(e)
            }}
            name={name}
            init={{
              height: 500,
              menubar: true,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
          <style>
            {`.tox-statusbar__branding{display:none !important}`}
          </style>
        </>}
    </div>
  );
}
