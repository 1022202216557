export function extractChars(str) {
    // Ensure the string is long enough
    if (str.length < 9) {
        return str;
    }

    // Extract the first 5 characters
    let firstPart = str.substring(0, 5);

    // Extract the last 4 characters
    let lastPart = str.substring(str.length - 4);

    // Combine and return
    return firstPart + lastPart;
}