import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NetaqInput from "../components/NetaqInput";
import ActionButton from "../components/ActionButton";
import { ToastContainer, toast } from "react-toastify";
import axios from "../axios/axios";
import axiosUmbraco from "../axios/axiosUmbraco";
import { useTranslate } from "../components/TranslationText";
import { useSelector } from "react-redux";
import { RemovePlusFromString } from "../helper/RemovePlusFromString";
import Loader from "../components/Loader";
import ArenaTable from "../components/ArenaTable";
import ComingSoon from "../components/ComingSoon";
import CronometerCounter from '../components/CronometerCounter';
import {
  getFirestore,
  collection,
  where,
  query,
  onSnapshot,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import { bookingTablesRequest } from "../firebase/collections";
import { useLocation, useNavigate } from "react-router";

class TableObject {

  static userProfile = JSON.parse(localStorage.getItem('eahsUserProfile') ?? '{}') ?? '';

  id
  ownerId
  holdEndDate
  price
  status
  tableNo

  constructor() {
  }

  static fromRaw(raw) {
    const self = new TableObject();
    Object.assign(self, raw);
    return self;
  }

  state() {
    const statusCodeToState = {
      1: 'available',
      2: 'booked',
      3: 'NA',
      4: this.ownerId === TableObject.userProfile.id ? 'selected' : 'hold'
    }

    const state = statusCodeToState[this.status] ?? 'NA';
    return state;
  }
}

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  const { translate } = useTranslate();

  return (
    <div className="zoomTools">
      <button className="tooltip" onClick={() => zoomIn()}><i class="fa-solid fa-magnifying-glass-plus"></i>   <span class="tooltiptext"> {translate('web.zoomIn', 'Zoom In')}</span></button>
      <button className="redbtn tooltip" onClick={() => zoomOut()}><i class="fa-solid fa-magnifying-glass-minus"></i> <span class="tooltiptext">{translate('web.zoomOut', 'Zoom Out')}</span></button>
      <button className="blueBtn tooltip" onClick={() => resetTransform()}><i class="fa-solid fa-magnifying-glass"></i> <span class="tooltiptext">{translate('web.zoomReset', 'Zoom Reset')}</span></button>
    </div>
  );
};
const UMBRACOURL = process.env.REACT_APP_UMBRACO;

export default function BookTable() {
  // umbraco
  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const ContactUsID = process.env.REACT_APP_CONTANCT_US_ID;
  const { translate } = useTranslate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentEventId = searchParams.has("eventid")
    ? searchParams.get("eventid")
    : null;

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [contactus, setContactus] = useState([]);
  const [loaded, setloaded] = useState(false);

  const [totalTables, setTotalTables] = useState([]);
  const navigate = useNavigate();


  const id = ContactUsID;

  function getCurrentLanguageUmbraco() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    return lang === "en" ? "en-US" : "ar-AE";
  }

  const [openingEvents, setOpeningEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false)
  // get Open TO book Template
  async function getOpeningEvents() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    const response = await axiosInstance.get(`bookingtable/getbookingtablesevents?Langauge=${lang}`);
    // setOpeningEvents(response.data.result);
    setEventsLoaded(true);
    setEventsLoaded(true);

    const filteredEvent = response.data.result.filter((item) => item.id == currentEventId)
    setOpeningEvents(filteredEvent)
    return filteredEvent;

  }

  async function getTemplateTables(event, id) {
    let userProfileCahched = localStorage.getItem('eahsUserProfile');
    let userProfile = '';
    if (userProfileCahched !== "") {
      userProfile = JSON.parse(localStorage.getItem('eahsUserProfile')) ?? '';
    }
    const eventID = id;
    const eventType = 'Show';

    const response = await axiosInstance.get(`bookingtable/geteventtemplatetables?EventId=${eventID}&EentType=${eventType}`);
    setTotalTables(response.data.result.tablesList.sort((a, b) => b.tableNo - a.tableNo));
    const pastHoldingTables = response.data.result.tablesList;
    const filterdTables = await pastHoldingTables.filter((x) => x.ownerId == userProfile.id && x.ownerId != "" && x.ownerId != null && x.ownerId != undefined && x.status === 4)
    setSelectedTables(filterdTables.map((x) => x.id));
    if (filterdTables.length > 0) {
      setNowDate(new Date(filterdTables[0].holdEndDate))
    }

    setIsLoading(false);
    const lastTotal = filterdTables.map((x) => x.price).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setTotalAmount(lastTotal);
    // subscribeToRealTimeHoldedTables(filterdTables);
  }
  const getRealTimeHoldedTables = async () => {
    try {
      const q = query(
        bookingTablesRequest,
        where('EventId', '==', currentEventId),
        orderBy('DateTime', 'desc')
        , limit(1)
      );
      return q;
    } catch (error) {
      console.log('error')
    }
  };

  const [TimerIsFinished, setTimerIsFinished] = useState(false);
  let userProfileCahched = localStorage.getItem('eahsUserProfile');
  let userProfile = '';
  if (userProfileCahched !== "") {
    userProfile = JSON.parse(localStorage.getItem('eahsUserProfile')) ?? '';
  }
  const updateBackendWithFirestore = (backendArray, firestoreArray) => {
    const nowDate = new Date();

    // Calculate the date and time 20 minutes from now
    const twentyMinutesFromNow = new Date(nowDate.getTime() + 20 * 60 * 1000);

    // Filter the array to get only items where holdEndDate is within the next 20 minutes
    const firestoreTables = firestoreArray.filter(item => {
      const holdEndDate = new Date(item.holdEndDate);
      return holdEndDate >= nowDate && holdEndDate <= twentyMinutesFromNow;
    });
    const excludeIds = firestoreTables.map(item => item.id);

    const filterdBackendarray = backendArray.filter(item => !excludeIds.includes(item.id));

    if (firestoreArray.length > 0) {
      return [...firestoreArray];
    } else {
      return [...backendArray];
    }
  };

  const [fireBaseConnected, setFireBaseConnected] = useState(false);
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);  // Flag to track the initial load

  async function subscribeToRealTimeHoldedTables() {
    try {
      const q = await getRealTimeHoldedTables(); // Await the Promise to get the Query object

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = [];
        querySnapshot.forEach((doc) => {
          newData.push(doc.data());
        });

        // console.log("newDataTables Are", newData);
        if (newData.length === 0) return;

        const latestTables = newData[0];
        // console.log("latest Tables Are", latestTables.TablesList);

        const formattedData = latestTables.TablesList.map((table, index) => ({
          holdEndDate: table.HoldEndDate,
          id: table.Id,
          listOfTables: table.ListOfTables,
          ownerId: table.OwnerId,
          price: table.Price,
          status: table.Status,
          tableNo: table.TableNo,
        }));

        setFireBaseConnected(true);
        if (!initialLoadCompleted) {
          setInitialLoadCompleted(true);
          return;
        }

        // console.log('allTables From Back end are', totalTables);
        if (totalTables.length > 0) {
          const updatedTables = updateBackendWithFirestore(totalTables, formattedData);
          setTotalTables(updatedTables.sort((a, b) => b.tableNo - a.tableNo));
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log('Error in Firestore', error);
    }
  }



  useEffect(() => {
    async function getdata() {
      const umbraco = await axiosUmbraco.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );
      const formattedData = umbraco.data?.map((item) => ({
        contactWhatsApp: item?.properties?.contactWhatsApp ?? "",
        contactTitle: item?.properties?.contactTitle ?? "",
        contactPhone: item?.properties?.contactPhone ?? "",
        contactMapUrl: item?.properties?.contactMapUrl ?? "",
        contactEmail: item?.properties?.contactEmail ?? "",
        contactDescription: item?.properties?.contactDescription ?? "",
        contactAddress: item?.properties?.contactAddress ?? "",
      }));
      setContactus(formattedData);
      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    // getdata();
    getOpeningEvents()
    if (openingEvents.length > 0) {

      getTemplateTables(openingEvents[0], currentEventId);
      subscribeToRealTimeHoldedTables();

    }



  }, [currentLanguage, eventsLoaded, fireBaseConnected]);


  // end of umbraco
  const [paymentDetails, setPaymentDetails] = useState({
    tableEglishName: userProfile.fullName,
    tableArabicName: userProfile.fullNameAr,
    email: userProfile.email,
    contactNumber: userProfile.phone,
    collectorName: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);


  const handleChange = (event) => {
    setPaymentDetails({
      ...paymentDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handelUrlToPayment = () => {
    const returnUrl = `${BaseURL}/bookingsuccessful?EventName=${openingEvents[0].eventName}`;

    const encodeURI = encodeURIComponent(returnUrl);

    window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${openingEvents[0].id}&paymentType=5&userId=${userProfile.id}&returnUrl=${encodeURI}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fields = ["tableEglishName", "tableArabicName", "contactNumber", "email", "collectorName"]; // Add more fields as needed
    const emptyFields = fields.filter((field) => !paymentDetails[field]);
    setIsLoading(true);
    if (emptyFields.length > 0) {
      toast.error(
        `${translate(
          "web.pleaseFillInTheAllfields",
          "Please fill in the All fields"
        )}: ${emptyFields.join(", ").toUpperCase()}`
      );
      setIsLoading(false);
      return;
    }
    try {
      const send = await axios.post("/bookingtable/createbookingtablesrequest", {
        ...paymentDetails, listOfTables: selectedTables
      });
      if (send.data.responseCode === "200") {
        toast.success(
          translate("web.redirectToPaymentPage", "Redirect To Payment Page")
        );
        setIsLoading(false);
        setPaymentDetails({
          tableEglishName: "",
          tableArabicName: "",
          email: '',
          contactNumber: '',
          collectorName: ''
        });
        handelUrlToPayment()
      } else {
        toast.error("Payment Request does not Sent Succecfully Check API");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Error in try Catch");
      setIsLoading(false);
    }
  };

  const dismissAll = () => toast.dismiss();
  const [totalAmount, setTotalAmount] = useState(0);
  const [nowDate, setNowDate] = useState(new Date());
  async function removeHold(tableID, istimeout = false) {

    let currentDate = new Date();
    // Add 10 minutes to the current time
    currentDate.setMinutes(currentDate.getMinutes() + 10);
    setNowDate(currentDate);
    if (!istimeout) {
      setTimerIsFinished(false);
    }
    const response = await axiosInstance.post('bookingtable/removetablehold', { id: tableID })
  }
  async function addToHold(selectedTables) {
    let currentDate = new Date();

    // Add 10 minutes to the current time
    currentDate.setMinutes(currentDate.getMinutes() + 10);
    setNowDate(currentDate);
    setTimerIsFinished(false);
    const response = await axiosInstance.post('bookingtable/settableshold', { listOfTables: selectedTables.toString() })
  }


  const toggleTableSelection = (tableID, tablePrice) => {
    if (!userProfile) {
      navigate("/login");
      return;
    }


    setTotalTables(prev => {
      const currentTableIndex = prev.findIndex((table) => table.id === tableID);
      const leftSlice = prev.slice(0, currentTableIndex);
      const currentTable = prev[currentTableIndex];
      currentTable.status = currentTable.status === 1 ? 4 : 1;
      currentTable.ownerId = userProfile?.id;
      const rightSlice = prev.slice(currentTableIndex + 1);
      return [...leftSlice, currentTable, ...rightSlice];
    });
    setSelectedTables((prevState) => {
      const isSelected = prevState.includes(tableID);
      const updatedTables = isSelected
        ? prevState.filter((num) => num !== tableID)
        : [...prevState, tableID];
      if (isSelected) {
        removeHold(tableID);
      } else {
        addToHold([...prevState, tableID])
      }

      setTotalAmount((prevAmount) =>
        isSelected ? prevAmount - tablePrice : prevAmount + tablePrice
      );

      return updatedTables;
    });
  };

  const isTableSelected = (tableID) => selectedTables.includes(tableID);

  useEffect(() => {
    if (TimerIsFinished) {
      selectedTables.forEach(table => {
        // removeHold(table, true);
        const tablePrice = totalTables.filter((alltable) => alltable.id == table);

        toggleTableSelection(table, tablePrice[0].price)
      })
    }
  }, [TimerIsFinished])

  const tablePrice = 500000;


  function checkTableStatus(statusCode, tableOwnerID) {
    let status = { Available: false, Booked: false, PrivateBooked: false, Hold: false };
    let userProfileCahched = localStorage.getItem('eahsUserProfile');
    let userProfile = '';
    if (userProfileCahched !== "") {
      userProfile = JSON.parse(localStorage.getItem('eahsUserProfile')) ?? '';
    }
    const isSameUserHolding = (tableOwnerID == userProfile.id);
    switch (statusCode) {
      case 1:
        status.Available = true;
        status.Booked = false;
        status.PrivateBooked = false;
        status.Hold = false;
        break;
      case 2:
        status.Available = false;
        status.Booked = true;
        status.PrivateBooked = false;
        status.Hold = false;
        break;
      case 3:
        status.Available = false;
        status.Booked = true;
        status.PrivateBooked = true;
        status.Hold = false;
        break;
      case 4:
        status.Available = false;
        // status.Booked = isSameUserHolding ? false : true;
        status.Booked = false;
        status.PrivateBooked = false;
        status.Hold = true;
        break;

      default:
        status.Available = false;
        status.Booked = false;
        status.PrivateBooked = false;
        status.Hold = false;
        break;
    }

    return status;

  }

  return (
    <>
      {
        eventsLoaded && (
          <>
            <ToastContainer
              position="top-right"
              onClick={dismissAll}
              theme="colored"
              autoClose={10000}
            />
            <div className="container-fluid px75">
              <div className="row noMarginRow justRotate">
                <h3 className=" NewsPostTitle bold justRotate rtl">
                  {openingEvents[0].eventName}
                </h3>
                <br />
                <br />
                <div className="col-md-3  showDetaisLabel justRotate rtl ">
                  <i
                    className="fa fa-calendar C89C66"
                    style={{ margin: "0 5px" }}
                  ></i>
                  <span>
                    {translate("web.startDate", "Start Date")} : {formatDate(openingEvents[0].startDate)}
                  </span>
                </div>
                <div className="col-md-3  showDetaisLabel justRotate rtl">
                  <i
                    className="fa fa-calendar C89C66"
                    style={{ margin: "0 5px" }}
                  ></i>
                  <span>
                    {translate("web.endDate", "End Date")} : {formatDate(openingEvents[0].endate)}
                  </span>
                </div>
                {!!(openingEvents[0].address) &&
                  (!!(openingEvents[0].mapLocation)
                    ?
                    <Link to={openingEvents[0].mapLocation} target="_blank" className="col-md-3  showDetaisLabel justRotate rtl C89C66">
                      <i
                        className="fa-solid fa-location-dot C89C66"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span style={{ textDecoration: 'underline' }}>
                        {openingEvents[0].address}
                      </span>
                    </Link>
                    :
                    <div className="col-md-3  showDetaisLabel justRotate rtl C89C66">
                      <i
                        className="fa-solid fa-location-dot C89C66"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span>
                        {openingEvents[0].address}
                      </span>
                    </div>
                  )
                }
              </div>
              <br />
              <div className="row noMarginRow justRotate">
                <div className="col-lg-9 col-md-9 col-sm-12 justRotate" style={{position:'relative'}}>

                  <div
                    className="row noMarginRow bookingArenaBorder"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TransformWrapper
                      initialScale={1}
                      initialPositionX={0}
                      initialPositionY={0}
                      wheel={{ wheelDisabled: true }}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                          <Controls />
                          <TransformComponent  >
                            <div className="bookingTableContainer">
                              <div className="bookingTablesRow">
                                {totalTables
                                  .filter((item, index) => item.tableNo > 53 && item.tableNo < 65)
                                  .map((item, index) => (
                                    index < 11 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      // isHold={checkTableStatus(item.status, item.ownerId).Hold}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                                {/* Refactor to be part of map */}
                                <ArenaTable
                                  tableNumber={'system'}
                                  tablePrice={'0'}
                                  // isSystemStaff={true}
                                  state={"system"}
                                />
                                <ArenaTable
                                  tableNumber={'system'}
                                  tablePrice={'0'}
                                  // isSystemStaff={true}
                                  state={"system"}
                                />
                                <ArenaTable
                                  tableNumber={'system'}
                                  tablePrice={'0'}
                                  // isSystemStaff={true}
                                  state={"system"}
                                />
                                <ArenaTable
                                  tableNumber={'system'}
                                  tablePrice={'0'}
                                  state={"system"}
                                // isSystemStaff={true}
                                />
                                <ArenaTable
                                  tableNumber={'system'}
                                  tablePrice={'0'}
                                  // isSystemStaff={true}
                                  state={"system"}
                                />
                              </div>
                              <div className="bookingTableCenter">
                                <div className="bookingTablesColumn">
                                  {totalTables
                                    .filter((item, index) => item.tableNo > 43 && item.tableNo < 54)
                                    .map((item, index) => (
                                      index < 10 &&
                                      <ArenaTable
                                        key={item.tableNo}
                                        tableNumber={item.tableNo}
                                        tablePrice={item.price}
                                        state={TableObject.fromRaw(item).state()}
                                        // isSelected={isTableSelected(item.id)}
                                        // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                        // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                        onClick={() =>
                                          toggleTableSelection(item.id, item.price)
                                        }
                                      />
                                    ))}
                                </div>
                                <div className="bookingTablesColumn">
                                  {totalTables

                                    .filter((item, index) => item.tableNo > 32 && item.tableNo < 44)
                                    .map((item, index) => (
                                      index < 11 &&
                                      <ArenaTable
                                        key={item.tableNo}
                                        tableNumber={item.tableNo}
                                        tablePrice={item.price}
                                        state={TableObject.fromRaw(item).state()}
                                        // isSelected={isTableSelected(item.id)}
                                        // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                        // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                        onClick={() =>
                                          toggleTableSelection(item.id, item.price)
                                        }
                                      />
                                    ))}
                                </div>
                                <div className="BookingTableArena">
                                  <img
                                    src={`${BaseURL}/assets/img/HorseArena.png`}
                                    alt="Horse Arena"
                                  />
                                </div>
                              </div>
                              <div className="bookingTablesRow withVIPSection">
                                {totalTables

                                  .filter((item, index) => item.tableNo > 13 && item.tableNo < 21)
                                  .map((item, index) => (
                                    index < 7 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                                <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                                {totalTables

                                  .filter((item, index) => item.tableNo < 6)
                                  .map((item, index) => (
                                    index < 5 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                              </div>
                              <div className="bookingTablesRow withVIPSection secondVIPRow">
                                {totalTables

                                  .filter((item, index) => item.tableNo > 20 && item.tableNo < 27)
                                  .map((item, index) => (
                                    index < 6 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                                <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                                {totalTables

                                  .filter((item, index) => item.tableNo > 5 && item.tableNo < 10)
                                  .map((item, index) => (
                                    index < 4 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                              </div>
                              <div className="bookingTablesRow withVIPSection secondVIPRow">
                                {totalTables

                                  .filter((item, index) => item.tableNo > 26 && item.tableNo < 33)
                                  .map((item, index) => (
                                    index < 6 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                                <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                                {totalTables
                                  .filter((item, index) => item.tableNo > 9 && item.tableNo < 14)
                                  .map((item, index) => (
                                    index < 4 &&
                                    <ArenaTable
                                      key={item.tableNo}
                                      tableNumber={item.tableNo}
                                      tablePrice={item.price}
                                      state={TableObject.fromRaw(item).state()}
                                      // isSelected={isTableSelected(item.id)}
                                      // notAvailable={checkTableStatus(item.status, item.ownerId).PrivateBooked}
                                      // isBooked={checkTableStatus(item.status, item.ownerId).Booked}
                                      onClick={() =>
                                        toggleTableSelection(item.id, item.price)
                                      }
                                    />
                                  ))}
                              </div>
                            </div>
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>

                  </div>
                  <div className="BookingTableRow alignselfflexCenter TabelLegendContainer">
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'available'}
                        tablePrice={0}
                        state={'available'}
                      />
                      <h6> {translate('web.availableTable', 'Available Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'booked'}
                        tablePrice={0}
                        state={'booked'}
                      />
                      <h6> {translate('web.bookedTable', 'Booked Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'selected'}
                        tablePrice={0}
                        state={'selected'}
                      />
                      <h6> {translate('web.selectedTable', 'Selected Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'NA'}
                        tablePrice={0}
                        state={'NA'}
                      />
                      <h6> {translate('web.unavailableTable', 'Unavailable Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'holded'}
                        tablePrice={'0'}
                        state={'hold'}
                      />
                      <h6> {translate('web.holdedTable', 'Holded Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'system'}
                        tablePrice={'0'}
                        state={'system'}
                      />
                      <h6> {translate('web.systemTable', 'System Table')}</h6>
                    </div>
                  </div>
                  <div className="BookingTableRow alignselfflexCenter TabelLegendContainer rtl">
                    <div className="TableLegend text-warning">
                      <h3 className="bold"><span className="text-danger">{translate('web.note', 'Note')} : </span> {translate('web.eachTableContains6Chairs', 'Each table contains 6 chairs')}</h3>
                    </div>
                  </div>

                </div>
                {!!userProfile &&

                  <div className="col-lg-3 col-md-3 col-sm-12 justRotate rtl">
                    <form
                      onSubmit={handleSubmit}
                      className="col-lg-12 col-md-12 col-sm-12 contactFormContainer"
                      style={{ position: "relative" }}
                    >
                      <div className="contactUsLable rtl" style={{ fontSize: 14 }}>
                        <i className="fa-solid fa-street-view C89C66"></i>
                        <span>{translate('web.totalCount', 'Total Count')}</span>
                        <p className="Contact-US-Desc" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {Math.abs(selectedTables.length).toLocaleString('en-US')} {translate('web.tables', 'Tables')}
                          {selectedTables.length > 0 && TimerIsFinished == false &&
                            <>
                              {" " + translate('web.holdingFor', 'holding for') + " : "}<CronometerCounter isMinutes={true} className="redbtn" endDate={nowDate} setTimerIsFinished={setTimerIsFinished} />
                            </>
                          }
                        </p>
                      </div>
                      <div className="contactUsLable" style={{ fontSize: 14 }}>
                        <i className="fa-solid fa-sack-dollar C89C66"></i>
                        <span>{translate('web.totalAmount', 'Total Amount')}</span>
                        <p className="Contact-US-Desc">
                          {Math.abs(totalAmount).toLocaleString('en-US')} {translate('web.aed')}
                        </p>
                      </div>

                      <NetaqInput
                        required={true}
                        value={paymentDetails.tableEglishName}
                        onChange={handleChange}
                        name={"tableEglishName"}
                        label={translate(
                          "web.EnglishName(NameToBePrintedOnTable)",
                          "English Name (Name to be printed on table)"
                        )}
                        placeholder={translate("web.fullName", "Full Name")}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.tableArabicName}
                        onChange={handleChange}
                        name={"tableArabicName"}
                        label={translate(
                          "web.ArabicName(NameToBePrintedOnTable)",
                          "Arabic Name (Name to be printed on table)"
                        )}
                        placeholder={translate(
                          "web.ArabicName(NameToBePrintedOnTable)",
                          "Arabic Name (Name to be printed on table)"
                        )}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.contactNumber}
                        onChange={handleChange}
                        name={"contactNumber"}
                        label={translate("web.contactNumber", "Contact Number")}
                        placeholder={translate("web.phone", "Phone")}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.email}
                        onChange={handleChange}
                        name={"email"}
                        label={translate("web.email", "Email")}
                        placeholder={translate("web.email", "Email")}
                        type={"email"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.collectorName}
                        onChange={handleChange}
                        name={"collectorName"}
                        label={translate("web.collectorName", "Collector Name")}
                        placeholder={translate("web.collectorName", "Full Name")}
                        type={"text"}
                      />


                      {isLoading ? (
                        <Loader />
                      ) : (
                        <ActionButton
                          type={"submit"}
                          text={translate("web.submit", "Submit")}
                          className="mt-3"
                        />
                      )}
                    </form>
                  </div>
                }
                {!userProfile && <div className="col-lg-3 col-md-3 col-sm-12 justRotate text-center">
                  <h4 className="color-eahsred"> {translate('web.pleaseLoginToBookYourTable', 'Please Login To Book Your Table')} </h4>
                </div>}
              </div>
            </div>
          </>
        )
      }
      {/* <ComingSoon /> */}
    </>
  );
}
