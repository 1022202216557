import React, { useEffect, useState } from "react";
import ImagesSlider from "../components/ImagesSlider";
import CollapseLabel from "../components/CollapseLabel";
import PedigreeChart from "../components/PedigreeChart";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHorseAuctionData,
  resetHorseAuctionData,
} from "../Redux/HorseAuctionData/HorseAuctionDataSlice";
import { useTranslate } from "../components/TranslationText";
import { convertToCamelCase } from "../helper/convertToCamelCase";

export default function HorseProfile() {
  const { translate } = useTranslate();

  // get hourse data from backend
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [horse, setHorse] = useState(horseData);
  const horse = useSelector((state) => state.horseauctiondata.horseData);
  const hierarchyData = useSelector(
    (state) => state.horseauctiondata.hierarchyData
  );
  const aboutArray = Object.entries(horse.about);
  const horseAttachment = horse.horseAttachment;

  // console.log("horseAttachment", horseAttachment);
  // console.log("horse", horse);
  // console.log("aboutArray", aboutArray);
  const currentHorseId = searchParams.has("horseid")
    ? searchParams.get("horseid")
    : null;
  const currentAuctionId = searchParams.has("auctionid")
    ? searchParams.get("auctionid")
    : null;

  const relatedUrls = searchParams.has("relatedUrls")
    ? searchParams.get("relatedUrls")
    : null;
  const dispatch = useDispatch();

  useEffect(() => {
    async function getHorseData() {
      dispatch(resetHorseAuctionData());
      dispatch(fetchHorseAuctionData(currentHorseId));
    }
    getHorseData();
  }, [dispatch, currentHorseId]);

  return (
    <React.Fragment>
      {!!horse && !!hierarchyData && (
        <div className="container-fluid" style={{ padding: "0px 50px" }}>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              {relatedUrls !== "null" && relatedUrls != null ? (
                <ImagesSlider
                  data={horseAttachment}
                  sliderName={"horseprofile"}
                  type={"horseprofile"}
                  auctionid={currentAuctionId}
                  horseName={
                    horse.about.Name +
                    "/" +
                    translate("web.lotNo", "Lot No") +
                    horse.about["lot No"]
                  }
                  VideoURL={relatedUrls}
                />
              ) : (
                <ImagesSlider
                  data={horseAttachment}
                  sliderName={"horseprofile"}
                  type={"horseprofile"}
                  auctionid={currentAuctionId}
                  horseName={
                    horse.about.Name +
                    "/" +
                    translate("web.lotNo", "Lot No") +
                    horse.about["lot No"]
                  }
                />
              )}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <CollapseLabel name={translate("web.about", "About")}>
                {
                  <div className="row right-to-left" style={{ margin: 0 }}>
                    {aboutArray.map(([key, value]) => (
                      <React.Fragment>
                        <div
                          className="col-lg-2 col-md-3 col-sm-4"
                          style={{ paddingLeft: 0, marginBottom: 15 }}
                        >
                          <span style={{ display: "block", color: "#777777" }}>
                            {translate(`web.${convertToCamelCase(key)}`, key)}
                          </span>
                          <span
                            style={{ display: "block", fontWeight: "bold" }}
                          >
                            {value}
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                }
              </CollapseLabel>
              <CollapseLabel name={translate("web.pedigree", "Pedigree")}>
                <PedigreeChart hierarchy={hierarchyData} />
              </CollapseLabel>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
