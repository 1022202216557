import React from 'react'

export default function CoursesPage() {
    const currentLanguage = localStorage.getItem('eahsLang') ?? 'en';
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
         {currentLanguage == 'en' &&
            <div style={{ width: '75%', maxHeight: 500, minWidth: 250 }} dangerouslySetInnerHTML={{ __html: '<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:80%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?backgroundColorFullscreen=%23fcf9f4&amp;d=ecaho_trainingmanual2023_issuu_digital&amp;hideIssuuLogo=true&amp;hideShareButton=true&amp;u=ecaho"></iframe></div>' }}></div>
         }  
         {currentLanguage == 'ar' &&
            <div style={{ width: '75%', maxHeight: 500, minWidth: 250 }} dangerouslySetInnerHTML={{ __html: '<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:80%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=ecaho_trainingmanual2023_final_arabic&amp;u=ecaho"></iframe></div>' }}></div>
         }  
        </div>
    )
}
