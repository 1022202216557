import React, { useEffect, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import { FetchShowJudgesthunk } from "../Redux/Shows/ShowsAction";
import {
  FetchAddJudgeConflictthunk,
  FetchDeleteJudgeConflictthunk,
  FetchShowConflictsthunk,
  FetchShowRequestHorsesthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { ToastContainer, toast } from "react-toastify";
import {
  resetNavigateStatus,
  resetToasterMessageShowEntry,
} from "../Redux/ShowEntry/ShowEntrySlice";
import ActionButton from "../components/ActionButton";

export default function ShowEntryConflict() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const {
    showEntryLoading,
    showRequestHorsesData,
    toasterMessageShowEntry,
    navigateStatus,
    showConflictsData,
  } = useSelector((state) => state.showEntrySlice);

  const { showJudgesData } = useSelector((state) => state.showsSlice);

  const { translate } = useTranslate();
  const [checker, setChecker] = useState(true);
  const [checkertest, setCheckertest] = useState([false]);
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const maxHorses = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  const showNameAr = searchParams.has("showNameAr")
    ? searchParams.get("showNameAr")
    : null;

  const showNameEn = searchParams.has("showNameEn")
    ? searchParams.get("showNameEn")
    : null;

  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  const judgeConflictReset = () => {
    return {
      judgeDrop: {
        label: translate("web.chooseJudge", "Choose Judge"),
        value: null,
      },
      radioButtons: "CertainHorses",
      //radioButtonsGeneralState: false,
      //radioButtonsCertainHorsesState: true,
      horse: [],
      horseChecked: [],
      reason: "",
      otherReason: "",
    };
  };

  const [judgeConflict, setjudgeConflict] = useState(judgeConflictReset());
  const [judgeConflictList, setJudgeConflictList] = useState([]);

  useEffect(() => {
    let params = {
      id: showId,
      langauge: currentLanguage,
    };
    dispatch(FetchShowJudgesthunk(params));

    let paramsHorses = {
      showId: showId,
      ownerId: localStorage.getItem("EAHSUserID"),
      langauge: currentLanguage,
    };
    dispatch(FetchShowRequestHorsesthunk(paramsHorses));

    dispatch(FetchShowConflictsthunk(paramsHorses));
  }, [currentLanguage]);

  const handleJudgeConflictChange = (event, input) => {
    if (!!input && input === "judge") {
      setjudgeConflict({
        ...judgeConflict,
        judgeDrop: event,
      });
    } else if (event.target.name === "horse") {
      if (!event.target.checked) {
        setjudgeConflict({
          ...judgeConflict,
          horse: judgeConflict.horse.filter(
            (item) => item !== event.target.value
          ),
          horseChecked: judgeConflict.horse.filter(
            (item) => item !== event.target.id
          ),
        });
      } else {
        setjudgeConflict({
          ...judgeConflict,
          horse: judgeConflict.horse.concat(event.target.value),
          horseChecked: judgeConflict.horseChecked.concat(event.target.id),
        });
      }
    } else {
      let newArray = new Array(checkertest.length).fill(false);
      if (event.target.value === "General") {
        setCheckertest(newArray);
      }

      setjudgeConflict({
        ...judgeConflict,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handelAddJudgeConflict = () => {
    if (
      judgeConflict.radioButtons === "CertainHorses" &&
      showConflictsData.length > 0 &&
      showConflictsData.some(
        (obj) =>
          obj.showJudgeId === judgeConflict.judgeDrop.value &&
          judgeConflict.horseChecked.includes(obj.horseName) &&
          obj.general == false
      )
    ) {
      const horseList = showConflictsData
        .filter((obj) => judgeConflict.horseChecked.includes(obj.horseName))
        .map((item) => item.horseName);

      toast.info(
        `${translate(
          "web.horsesalreadysaved",
          "this horses already saved with this judge"
        )}
      ${translate("web.Judge", "Judge")} : ${
          judgeConflict.judgeDrop.label.props.children[1].props.children
        }
      ${translate("web.horses", "Horses")} : ${horseList.toString()}`
      );

      setjudgeConflict(judgeConflictReset());
      return;
    } else if (
      judgeConflict.radioButtons === "General" &&
      showConflictsData.length > 0 &&
      showConflictsData.some(
        (obj) =>
          obj.showJudgeId === judgeConflict.judgeDrop.value &&
          obj.general === true
      )
    ) {
      toast.info(
        translate("web.judgealreadyexist", "this judge already exist")
      );
      setjudgeConflict(judgeConflictReset());
      return;
    }
    const params = {
      showId: showId,
      ownerId: localStorage.getItem("EAHSUserID"),
      judgeId: judgeConflict.judgeDrop.value,
      isGeneral: judgeConflict.radioButtons === "CertainHorses" ? false : true,
      selectedHorses: judgeConflict.horse,
      reason: judgeConflict.reason,
      description: judgeConflict.otherReason,
    };
    dispatch(FetchAddJudgeConflictthunk(params));
  };

  const handelDeleteJudgeConflict = (id) => {
    let param = {
      id,
    };
    dispatch(FetchDeleteJudgeConflictthunk(param));
  };

  useEffect(() => {
    if (toasterMessageShowEntry != null && toasterMessageShowEntry === "save") {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );
      dispatch(resetToasterMessageShowEntry());
    } else if (toasterMessageShowEntry == null && navigateStatus) {
      dispatch(resetNavigateStatus());

      let paramsHorses = {
        showId: showId,
        ownerId: localStorage.getItem("EAHSUserID"),
        langauge: currentLanguage,
      };
      dispatch(FetchShowConflictsthunk(paramsHorses));

      // setJudgeConflictList([
      //   ...judgeConflictList,
      //   {
      //     judge: judgeConflict.judgeDrop.label.props.children[1].props.children,
      //     horse:
      //       judgeConflict.radioButtons === "CertainHorses"
      //         ? judgeConflict.horseChecked
      //         : [],
      //     reason: judgeConflict.reason,
      //   },
      // ]);

      setjudgeConflict(judgeConflictReset());
    }
  }, [toasterMessageShowEntry]);

  const dismissAll = () => toast.dismiss();

  const ischeck = (i) => {
    let newArray = new Array(checkertest.length).fill(false);
    let newArrayStatus = checkertest;

    if (judgeConflict.radioButtons === "General") {
      setCheckertest(newArray);
    } else {
      newArrayStatus[i] = !newArrayStatus[i];
      setCheckertest(newArrayStatus);
    }
  };

  useEffect(() => {
    setCheckertest(new Array(showRequestHorsesData.length).fill(false));
  }, showRequestHorsesData);

  // console.log("showRequestHorsesData", showRequestHorsesData);
  // console.log("showConflictsData", showConflictsData);
  return (
    <div className="container-fluid container-fluid-padding graybackgroundcolor row rtl">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          showEntryLoading ? "classdisplay" : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {
        // title
      }
      <div className="col-md-12 col-sm-12  whitebackgroundcolor rtl mt-15">
        <h1 className="fontWeight">
          {currentLanguage === "en" ? showNameEn : showNameAr}
        </h1>
        {
          // <h4 className="graycolor">
          //   {translate(
          //     "web.pleaseRegisterHorsesMax",
          //     `Please note you register for up to`
          //   )}{" "}
          //   {maxHorses} {translate("web.horsesmax", `horses max`)}
          // </h4>
        }
      </div>
      <div className="col-md-12 col-sm-12 rtl ">
        <h2 className="fontWeight mb-25">
          {translate(
            "web.Pleasechoosejudges",
            "Please choose judges incase you have any conflicts with them and state the reason"
          )}
        </h2>
        {
          //judges list
        }
        <form onReset={handelAddJudgeConflict}>
          <div className="col-md-3 col-sm-12 mb-25  p0">
            <Select
              options={[
                {
                  label: translate("web.chooseJudge", "Choose Judge"),
                  value: null,
                },
                ...(!!showJudgesData &&
                  showJudgesData.map((a) => ({
                    label: (
                      <React.Fragment>
                        <img
                          className="countryFlageImg rotateArabic iconjudge"
                          src={a.countryFlag}
                          alt={a.countryName}
                        />
                        <span className="marginrightleft-10">{a.name}</span>
                      </React.Fragment>
                    ),
                    value: a.id,
                  }))),
              ]}
              placeholder={translate("web.chooseJudge", "Choose Judge")}
              value={judgeConflict.judgeDrop}
              onChange={(e) => handleJudgeConflictChange(e, "judge")}
              name={"judge"}
              isSearchable
              noOptionsMessage={() => translate("web.noOptions", "No Options")}
            />
          </div>
          {
            //horses
          }
          <div className="col-md-12 col-sm-12 mb-25 p0">
            <h2 className="fontWeight">{translate("web.horses", "Horses")}</h2>
          </div>
          {
            //radio buttons
          }
          <div className="col-md-12 col-sm-12 mb-25 rtl justRotate p0">
            <div className="col-md-2 justRotate p0">
              <NetaqInput
                name={"radioButtons"}
                key={"General"}
                type={"radio"}
                value={"General"}
                label={translate("web.generalConflict", "General")}
                onChange={handleJudgeConflictChange}
                //checked={judgeConflict.radioButtonsGeneralState}
              />
            </div>
            <div className="col-md-2 justRotate p0">
              <NetaqInput
                name={"radioButtons"}
                key={"CertainHorses"}
                type={"radio"}
                value={"CertainHorses"}
                label={translate("web.certainHorses", "Certain horses")}
                onChange={handleJudgeConflictChange}
                checked={checker}
                //checked={judgeConflict.radioButtonsCertainHorsesState}
              />
            </div>
          </div>
          {
            //checkbox horses buttons
          }
          <div className="col-md-12 col-sm-12 mb-25 p0">
            {showRequestHorsesData.length > 0 &&
              showRequestHorsesData.map((item, i) => (
                <NetaqInput
                  key={i}
                  name={"horse"}
                  type={"checkbox"}
                  value={item.id}
                  label={`${
                    item.isRegisterd
                      ? item.registerNumber + " - "
                      : (!!item.registerNumber
                          ? item.registerNumber + " - "
                          : "") +
                        item.countryName +
                        " - "
                  } ${item.name}`}
                  isDash={true}
                  onChange={(e) => handleJudgeConflictChange(e, "horse")}
                  disabled={judgeConflict.radioButtons === "General"}
                  checked={checkertest[i]}
                  onClick={() => ischeck(i)}
                />
              ))}
          </div>
          {
            //title reason conflict
          }
          <div className="col-md-12 col-sm-12 mb-10 p0">
            <h2 className="fontWeight">
              {translate("web.Reasonconflcit", "Reason of the conflcit")}
            </h2>
          </div>
          {
            //text reason conflict
          }
          <div className="col-md-12 col-sm-12 mb-25 justRotate p0 rtl">
            <div className="col-md-4 col-sm-12 justRotate  p0">
              <NetaqInput
                label={translate("web.reason", "Reason")}
                name={"reason"}
                placeholder={translate("web.reason", "Reason")}
                type={"text"}
                onChange={handleJudgeConflictChange}
                value={judgeConflict.reason}
              />
            </div>
          </div>
          {
            //reach reason conflict
          }
          <div className="col-md-12 col-sm-12 mb-25 justRotate p0">
            <div className="col-md-4 col-sm-12 justRotate  rtl p0">
              <NetaqInput
                label={translate(
                  "web.otherReason",
                  "Additional Reason (optional):"
                )}
                name={"otherReason"}
                type={"textarea"}
                onChange={handleJudgeConflictChange}
                value={judgeConflict.otherReason}
              />
            </div>
          </div>
          {
            //Add another judge button
          }
          <div className="col-md-12 col-sm-12 mb-25 p0">
            <button
              type="reset"
              className={`btn btn-danger btn-lg height46 ${
                judgeConflict.judgeDrop.value != null &&
                (judgeConflict.radioButtons === "General" ||
                  (judgeConflict.radioButtons === "CertainHorses" &&
                    judgeConflict.horse.length > 0)) &&
                judgeConflict.reason !== ""
                  ? "linkbtn"
                  : "newlinkbtn"
              }`}
              //className="btn buttonformshowentrycard height46 linkbtn"
              disabled={
                judgeConflict.judgeDrop.value != null &&
                (judgeConflict.radioButtons === "General" ||
                  (judgeConflict.radioButtons === "CertainHorses" &&
                    judgeConflict.horse.length > 0)) &&
                judgeConflict.reason !== ""
                  ? false
                  : true
              }
            >
              <i className="fa fa-plus"></i>
              <span className="ml-10">
                {translate("web.addAnotherJudge", "Add another judge")}
              </span>
            </button>
          </div>
        </form>
        {
          // {
          //   //checkbox proceed payment
          // }
          // <div className="col-md-12 col-sm-12 mb-25 p0">
          //   <NetaqInput
          //     name={"checkboxproceedpayment"}
          //     type={"checkbox"}
          //     value={"checkboxproceedpayment"}
          //     label={translate(
          //       "web.checkboxProceedPayment",
          //       "By checking this box, I agree that I have disclosed any potential conflicts of interest with the judges. I understand that providing false or misleading information may have legal consequences."
          //     )}
          //     //onChange={(e) => handleChange(e, "showStatus")}
          //   />
          // </div>
          // {
          //   //button proceed payment
          // }
        }
        <div className="col-md-12 col-sm-12 mb-25 p0">
          {
            // <ActionButton
            //     type={"submit"}
            //     text={translate("web.proceedPayment", "Proceed To Payment")}
            //     // onClick={() => {
            //     //   dispatch(
            //     //     FetchOwnerHorseMarketthunk(
            //     //       localStorage.getItem("EAHSUserID")
            //     //     )
            //     //   );
            //     // }}
            //     margin={"0"}
            //   />
          }
        </div>
        {
          //table
        }
        <div className="col-md-12 col-sm-12 rtl mb-25 p0">
          <table className="table table-bordered rtl  whitebackgroundcolor">
            <thead className="tablebackgroundcolor">
              <tr>
                <th className="text-center">
                  {translate("web.judges", "Judges")}
                </th>
                <th className="text-center">
                  {translate("web.theHorses", "Horses")}
                </th>
                <th className="text-center">
                  {translate("web.Reasonconflcit", "Reason of the conflcit")}
                </th>

                <th className="text-center">
                  {translate("web.delete", "Delete")}
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {showConflictsData.length > 0 &&
                showConflictsData.map((item, i) => (
                  <tr key={i}>
                    <td>{item.judgeName}</td>
                    <td>
                      {
                        // item.horse.length > 0 &&
                        // item.horse.map((itemHorse, iHorse) => (
                        //   <p key={iHorse}>{itemHorse}</p>
                        // ))
                        //item.horseName
                      }
                      {!!item.horseName &&
                        `${
                          item.isRegisterd
                            ? item.registerNumber + " - "
                            : (!!item.registerNumber
                                ? item.registerNumber + " - "
                                : "") +
                              item.countryName +
                              " - "
                        } ${item.horseName}`}
                    </td>
                    <td>{item.reason}</td>

                    <td>
                      <i
                        className="fa fa-trash plusIcon cursorPointer mt-15"
                        onClick={() => handelDeleteJudgeConflict(item.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-12 col-sm-12 mb-25 p0">
          <Link
            type="button"
            className="btn buttonformshowentrycard height46 linkbtn"
            to={`/profile`}
          >
            <span className="ml-10">
              <i class="fa-solid fa-arrow-left fa-lg marginrightleft-10"></i>
              {translate("web.back", "Back")}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
