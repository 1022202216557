import React, { useEffect, useState } from "react";
import StudBookFilter from "../components/StudBookFilter";
import { Link, useNavigate } from "react-router-dom";
import SubmitBtn from "../components/SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchStudBookDatathunk,
  FetchStudBookOwnerthunk,
} from "../Redux/StudBook/StudBookAction";
import {
  emptyPage,
  incrementPage,
  resetSearch,
} from "../Redux/StudBook/StudBookSlice";

import { useTranslate } from "../components/TranslationText";

import Loader from "../components/Loader";
import HorseCard from "../components/HorseCard";
import FilterButton from "../components/FilterButton";

const BaseURL = process.env.REACT_APP_BASE_URL;

export default function StudBookPage() {
  const [regNo, setRegNo] = useState("");
  const [isHoveredSearch, setIsHoveredSearch] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFilterSection, setIsFilterSection] = useState(false);

  const [isFilterimg, setIsFilterimg] = useState(true);
  const [filterCriteria, setFilterCriteria] = useState({
    gender: [],
    horseUELN: "",
    origin: null,
    birthDateFrom: null,
    birthDateTo: null,
    ownerId: "",
    regNo: "",
  });
  const [selectedSearchType, setSelectedSearchType] = useState("1");
  const [notFoundMsg, setNotFoundMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    marginTop: 23,
    textAlign: "center",
    display: "none",
  });
  const navigate = useNavigate();

  const {
    studBookData,
    studBookLoading,
    stateShowMoreButtonHorse,
    page,
    take,
    totalCount,
    currentLanguage,
    stateNoDataFound,
  } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();

  // console.log(studBookData);
  const dispatch = useDispatch();

  const filterHorses = async () => {
    if (regNo === "") {
      setErrorMsg({
        ...errorMsg,
        display: "block",
      });
    } else {
      setErrorMsg({
        ...errorMsg,
        display: "none",
      });
      dispatch(resetSearch());
    }

    //dispatch(emptyPage());
  };

  useEffect(() => {
    if (
      page === 0 ||
      (regNo === "" &&
        filterCriteria.gender.length === 0 &&
        filterCriteria.horseUELN === "" &&
        filterCriteria.origin == null &&
        filterCriteria.birthDateFrom === null &&
        filterCriteria.birthDateTo === null &&
        filterCriteria.ownerId === "")
    ) {
      dispatch(emptyPage());
      return; // Exit
    }
    if (selectedSearchType === "1") {
      let params = {
        regNo,
        skip: (page - 1) * take,
        take,
        langauge: currentLanguage,
        gender:
          filterCriteria.gender.length === 0 ? null : filterCriteria.gender,
        horseUELN:
          filterCriteria.horseUELN === "" ? null : filterCriteria.horseUELN,
        origin: filterCriteria.origin === "" ? null : filterCriteria.origin,
        birthDateFrom:
          filterCriteria.birthDateFrom === ""
            ? null
            : filterCriteria.birthDateFrom,
        birthDateTo:
          filterCriteria.birthDateTo === "" ? null : filterCriteria.birthDateTo,
        ownerRegisterNumber:
          filterCriteria.ownerId === "" ? null : filterCriteria.ownerId,
      };
      dispatch(FetchStudBookDatathunk(params));
      setNotFoundMsg(true);
      setErrorMsg({
        ...errorMsg,
        display: "none",
      });
    }

    if (selectedSearchType === "2") {
      setNotFoundMsg(true);
      let params = {
        ownerId: regNo,
        skip: (page - 1) * take,
        take,
        langauge: currentLanguage,
        //ownerId: filterCriteria.ownerId === "" ? null : filterCriteria.ownerId,
      };
      dispatch(FetchStudBookOwnerthunk(params));
    }
  }, [page, filterCriteria, currentLanguage]);

  const updateStudBookList = (newFilters) => {
    dispatch(emptyPage());
    //setFilterCriteria(newFilters);
    setFilterCriteria({
      ...newFilters,
      regNo,
    });
  };

  const handleMouseOverSearch = () => {
    setIsHoveredSearch(true);
  };

  const handleMouseOutSearch = () => {
    setIsHoveredSearch(false);
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleChange = (e) => {
    //dispatch(resetSearch());
    //dispatch(emptypage());
    setRegNo(e.target.value);
  };

  const handleSelectedSearchType = (e) => {
    setSelectedSearchType(e.target.value);
    dispatch(resetSearch());

    if (e.target.value === "1") {
      setIsFilterimg(true);
    }

    if (e.target.value === "2") {
      setIsFilterSection(false);
      setIsFilterimg(false);
    }
  };

  const FilterSectionShow = () => {
    setIsFilterSection(!isFilterSection);
  };

  const handelShowMore = async () => {
    dispatch(incrementPage());
  };

  function formatDate(_date) {
    // Extracting day, month, and year
    let date = new Date(_date);

    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero based
    let year = date.getFullYear();

    // Padding day and month with leading zeros if necessary
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    // Returning the formatted date string
    return day + "/" + month + "/" + year;
  }

  useEffect(() => {
    return () => dispatch(resetSearch());
  }, []);
  const langauge = localStorage.getItem("eahsLang") ?? "en";

  return (
    <React.Fragment>
      <div
        className="container-fluid"
        style={{ paddingLeft: 15, paddingRight: 15 }}
      >
        <div className="row">
          <div className="col-md-12 col-sm-12 right-to-left row m0 rtl">
            <div className="StudBookSearchBarContainer">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  filterHorses();
                }}
                className="input-group"
                style={{
                  paddingRight: 14,
                  width: "42%",
                  height: 53,
                  display: "flex",
                  border: "1px solid rgb(228, 77, 82)",
                  padding: "6px 7px",
                  marginRight: 14,
                  borderRadius: 6,
                }}
              >
                <i
                  className="fas fa-search fa-xl"
                  style={{
                    marginLeft: 14,
                    color: "rgb(244 159 162)",
                    marginRight: 14,
                    marginTop: 19,
                  }}
                ></i>
                <input
                  type="text"
                  id="searchStudBook"
                  name="searchStudBook"
                  className="form-control"
                  value={regNo}
                  //autocomplete="on"
                  style={{ height: "38px", borderStyle: "hidden" }}
                  onChange={handleChange}
                  placeholder={translate(
                    "web.searchStudBook",
                    "Search for StudBook, By Name Or Reg Number"
                  )}
                />
                <select
                  style={{
                    backgroundColor: "#f0f0f0",
                    borderColor: "white",
                    height: "38px",
                    borderRadius: 5,
                    textAlign: "center",
                    backgroundImage: `url('${BaseURL}/assets/img/down-arrow.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "92%",
                    backgroundPositionY: "51%",
                    width: 114,
                    WebkitAppearance: "none",
                  }}
                  defaultValue={selectedSearchType}
                  // onChange={(evt) => setSelectedSearchType(evt.target.value)}
                  onChange={handleSelectedSearchType}
                >
                  <option value={1}>{translate("web.horse", "Horse")}</option>
                  <option value={2}>{translate("web.owner", "Owner")}</option>
                </select>
              </form>
              <div>
                <button
                  type="button"
                  className="btn"
                  style={{
                    background: isHoveredSearch ? "#E44D52" : "#ffffff",
                    padding: "10px 20px",
                    color: isHoveredSearch ? "#fff" : "#E44D52",
                    outline: 0,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "#E44D52",
                    transition: "all 0.3s",
                    height: 53,
                    marginRight: 19,
                    marginLeft: 3,
                  }}
                  onMouseOver={handleMouseOverSearch}
                  onMouseOut={handleMouseOutSearch}
                  onClick={filterHorses}
                >
                  {translate("web.search", "Search")}
                </button>

                {isFilterimg && (
                  <FilterButton
                    onClick={FilterSectionShow}
                    isStudBookPage={true}
                  />
                )}
              </div>
            </div>

            <div className={"col-md-12 col-sm-12"} style={errorMsg}>
              <p className="text-danger">
                {translate(
                  "web.pleaseEnterYourSearch",
                  "please enter your Search in textbox"
                )}
              </p>
            </div>

            {isFilterSection  && (
              <div className="col-md-3 col-sm-12" style={{ marginTop: 23 }}>
                <StudBookFilter
                  onSubmit={updateStudBookList}
                  setRN={setRegNo}
                  regNo={regNo}
                />
              </div>
            )}

            <div
              className={
                isFilterSection
                  ? "row col-md-9 col-sm-12  width100Arabic768 right-to-left"
                  : selectedSearchType === "2"
                  ? "row col-md-12 col-sm-12  right-to-left width100Arabic768"
                  : " width100Arabic768 row col-md-12 col-sm-12  right-to-left"
              }
              style={{
                margin: 0,
                marginTop: 25,
              }}
            >
              {
                //studBookLoading && <Loader />
              }

              {!!studBookData &&
                studBookData.length > 0 &&
                selectedSearchType === "1" &&
                studBookData.map((item, i) => (
                  <HorseCard isArabicClass={true} item={item} i={i} />
                ))}

              {!!studBookData &&
                studBookData.length > 0 &&
                selectedSearchType === "2" &&
                studBookData.map((item, i) => (
                  <div key={i} className="col-lg-4 col-md-4 col-sm-6  ">
                    <div
                      style={{
                        position: "relative",
                        minHeight: 200,
                        padding: "10px 20px",
                        background: "#FCFAF7",
                        borderTop: "2px solid #C89C66",

                        borderRadius: 5,
                        marginBottom: 15,
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          textTransform: "capitalize",
                          display: "block",
                          marginBottom: 5,
                        }}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          textTransform: "capitalize",
                          display: "block",
                          marginBottom: 23,
                        }}
                      >
                        {translate("web.regno", "Reg No")}: {item.ownerId}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            // fontWeight: "bold",
                            fontSize: 15,
                            textTransform: "capitalize",
                            display: "block",
                            marginBottom: 23,
                            color: "black",
                          }}
                        >
                          {translate("web.owns", "owns")}{" "}
                          {item.numberofHorsesOwned}{" "}
                          {translate("web.horses", "Horses")}
                        </span>
                        <span
                          style={{
                            // fontWeight: "bold",
                            fontSize: 15,
                            textTransform: "capitalize",
                            display: "block",
                            marginBottom: 23,
                            color: "black",
                          }}
                        >
                          {translate("web.breds", "Breds")}{" "}
                          {item.numberofHorsesBred}{" "}
                          {translate("web.horses", "Horses")}
                        </span>
                      </div>
                      <div className="row m0 justify-content-end">
                        <span
                          className="hoveronspan ViewProfileLink  rtl"
                          style={
                            langauge == "en"
                              ? {
                                  textAlign: "right",
                                  cursor: "pointer",
                                  marginRight: -10,
                                }
                              : {
                                  textAlign: "left",
                                  cursor: "pointer",
                                  marginLeft: -10,
                                }
                          }
                          onClick={() => {
                            navigate("/ownerdetails", { state: item });
                          }}
                        >
                          {translate("web.viewDetails", "View Details")}
                          <i className="fa-solid fa-chevron-right justRotate red-fz-8-op-25"></i>
                          <i className="fa-solid fa-chevron-right justRotate red-fz-8-op-50"></i>
                          <i className="fa-solid fa-chevron-right justRotate  red-fz-8-op-1"></i>
                        </span>
                      </div>
                      {/* <span
                        className="hoveronlink dblock"
                        style={{
                          fontSize: 20,
                          color: "#E44D52",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        //to={`/ownerdetails?ownerId=${item.id}`}
                        onClick={() => {
                          navigate("/ownerdetails", { state: item });
                        }}
                      >
                        {translate("web.viewDetails", "View Details")}
                        <i
                          className="fa-solid fa-chevron-right "
                          style={{
                            color: "#E44D52",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        ></i>
                      </span> */}
                    </div>
                  </div>
                ))}

              {stateNoDataFound && (
                <React.Fragment>
                  <div
                    className={
                      selectedSearchType === "2"
                        ? "row text-danger   text-center "
                        : "row text-danger   text-center"
                    }
                  >
                    <span className=" dblock">
                      {translate("web.nodataFound", "No data Found!")}
                    </span>
                  </div>
                </React.Fragment>
              )}
              <div
                className={
                  selectedSearchType === "2"
                    ? "col-lg-12 col-md-12 col-sm-12   text-center-rtl text-center"
                    : "col-lg-12 col-md-12 col-sm-12   text-center-rtl text-center"
                }
                style={{ marginTop: 20 }}
              >
                {!studBookLoading &&
                !!studBookData &&
                studBookData.length > 0 ? (
                  <SubmitBtn
                    disabled={stateShowMoreButtonHorse}
                    onClick={handelShowMore}
                    marginBottom={50}
                    type={"loadmore"}
                    label={`
                        ${translate("web.showMore", "Show More")} (${
                      studBookData.length
                    } ${translate("web.of", "of")} ${totalCount})`}
                  />
                ) : (
                  ""
                )}
                {studBookLoading && <Loader />}
              </div>
            </div>
            {/* {isFilterSection && currentLanguage != "en" && (
              <div className="col-md-3 col-sm-12" style={{ marginTop: 23 }}>
                <StudBookFilter
                  onSubmit={updateStudBookList}
                  setRN={setRegNo}
                  regNo={regNo}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
